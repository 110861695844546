@charset "UTF-8";
/* Custom Layout */
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Theme Color
2.0 Fonts
		2.1 Font Size
		2.2 Font Wieght
3.0 Heading Size
--------------------------------------------------------------*/
/**---Triangle---**/
/**---Box Shadow---**/
.v-align, .app--item--content, .section--break__legend::after, .form-section--head::after, .form-block--title:before {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%); }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.c-align, .profile-content--img {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.v-c-align, .table th.sortable .order .dropup,
.table th.sortable .order .dropdown {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  line-height: 1.3 !important; }

.h5, .nav-tabs .nav-link,
.h6,
h5,
h6 {
  line-height: 1.5 !important; }

small,
.small {
  font-size: 0.74987rem !important; }

.xs-small {
  font-size: 0.62475rem; }

.bg-green {
  background: #174e4d; }

.bg-light-green, .course-module .module-title, .discussion-group .group-item:hover, .discussion-group .group-item.active, .custom-calander.react-datepicker, .custom-calander.react-datepicker .react-datepicker__month-container, .custom-calander.react-datepicker .react-datepicker__header {
  background-color: #f3f7fa; }

.bg-grey-9 {
  background: #f7f3ec; }

.text-blue {
  color: #74b9ff; }

.text-link-blue {
  color: #0facf3; }

.pointer {
  cursor: pointer; }

.cursor-default {
  cursor: default !important; }

ul.arrow {
  margin-left: 1rem; }
  ul.arrow li {
    padding-left: 0.5rem; }

.img-container {
  position: relative; }
  .img-container:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99; }

.react-bs-table-sizePerPage-dropdown .dropdown-menu {
  top: -170px !important;
  bottom: 30px; }
  .react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a {
    display: block; }

.react-bs-table-sizePerPage-dropdown .btn-secondary,
.react-bs-table-sizePerPage-dropdown .dropdown-toggle {
  background-color: #e5f8f4;
  border-color: #e5f8f4; }
  .react-bs-table-sizePerPage-dropdown .btn-secondary:hover, .react-bs-table-sizePerPage-dropdown .btn-secondary:active, .react-bs-table-sizePerPage-dropdown .btn-secondary:focus, .react-bs-table-sizePerPage-dropdown .btn-secondary:not(:disabled):not(.disabled):active,
  .react-bs-table-sizePerPage-dropdown .dropdown-toggle:hover,
  .react-bs-table-sizePerPage-dropdown .dropdown-toggle:active,
  .react-bs-table-sizePerPage-dropdown .dropdown-toggle:focus,
  .react-bs-table-sizePerPage-dropdown .dropdown-toggle:not(:disabled):not(.disabled):active {
    background-color: #20c997;
    border-color: #20c997; }

.react-bs-table-sizePerPage-dropdown.show .btn-secondary,
.react-bs-table-sizePerPage-dropdown.show .dropdown-toggle {
  background-color: #e5f8f4;
  border-color: #e5f8f4; }
  .react-bs-table-sizePerPage-dropdown.show .btn-secondary:hover, .react-bs-table-sizePerPage-dropdown.show .btn-secondary:active, .react-bs-table-sizePerPage-dropdown.show .btn-secondary:focus, .react-bs-table-sizePerPage-dropdown.show .btn-secondary:not(:disabled):not(.disabled):active,
  .react-bs-table-sizePerPage-dropdown.show .dropdown-toggle:hover,
  .react-bs-table-sizePerPage-dropdown.show .dropdown-toggle:active,
  .react-bs-table-sizePerPage-dropdown.show .dropdown-toggle:focus,
  .react-bs-table-sizePerPage-dropdown.show .dropdown-toggle:not(:disabled):not(.disabled):active {
    background-color: #20c997;
    border-color: #20c997; }

.pagination .page-item.active a {
  pointer-events: none;
  cursor: default; }

.loader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; }

.text-color-blue {
  color: #007bff; }

.text-light-gray {
  color: #696969; }

.delete-icon:hover {
  color: #dc3545; }

.label-color {
  color: #595959; }

.no-label {
  display: none; }

.bg-light-green-d {
  background: #f8fdff; }

.bg-lightgreen {
  background: #e5f8f4; }

.bg-gray-300 {
  background: #e8e8e8; }

.bg-gray-100 {
  background: #f8f8f8; }

.font-weight-semibold {
  font-weight: 600 !important; }

.font-weight-light {
  font-weight: 100 !important; }

.font-xl {
  font-size: 1.37462rem; }

.font-lg {
  font-size: 1.00012rem; }

.font-base {
  font-size: 0.875rem; }

.font-sm {
  font-size: 0.74987rem; }

.font-xs {
  font-size: 0.62475rem; }

.box-shadow, .form-builder--tools-list, .user .dropdown-menu {
  -webkit-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1); }

.grid__2col {
  grid-template-columns: calc(67% - 18px) 33%; }

.grid__3col {
  grid-template-columns: 64px calc(74% - 20px) calc(25% - 64px); }

.w-90 {
  width: 90%; }

.w-100x {
  width: 100px; }

.text-danger, .invalid {
  color: #ff7775 !important; }

.text-warning {
  color: #feca6e !important; }

.nm-x {
  margin: 0 -15px; }

.nm-trl {
  margin: -24px -24px 0 -24px; }

.flex-sp-bt {
  display: flex;
  justify-content: space-between;
  align-items: center; }

select.required option:first-child {
  display: none; }

.flt-rt {
  float: right; }

.tab-button {
  position: absolute;
  right: 15px;
  top: 15px; }

.clr-gray {
  color: #6c757d; }

button:disabled {
  opacity: 0.65;
  pointer-events: none; }

.disabled {
  opacity: 0.65;
  pointer-events: none; }

.empty-label:before {
  content: '';
  height: 23px;
  width: auto;
  display: inline-block; }

.line-clamp {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative; }

.line-clamp-2 {
  min-height: 40px;
  -webkit-line-clamp: 2;
  max-height: 74px; }

.css-26l3qy-menu {
  max-height: 300px;
  overflow: hidden;
  z-index: 2 !important; }

.css-11unzgr {
  height: 100%; }

.auto-saving-status {
  display: inline-flex;
  justify-content: initial;
  align-items: center;
  color: #595959;
  font-weight: normal; }
  .auto-saving-status [class^='icon-'],
  .auto-saving-status [class*=' icon-'] {
    font-size: 1em; }
  .auto-saving-status .icon-cloud {
    font-size: 24px; }
  .auto-saving-status .icon-info {
    font-size: 20px; }

.filter-wrap {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); }
  .filter-wrap .grid-cl-2 {
    grid-column: 1/3; }

.myFileModal {
  width: 100%;
  height: 100vh;
  background-color: black; }

.myModalHeader {
  width: 100%; }

.fullScreenButtonModal {
  position: absolute;
  right: 40px; }

.fullScreenButtonModal:hover {
  cursor: pointer; }

.fullScreenTooltipContainer {
  position: relative;
  display: inline-block; }

.fullScreenTooltipContainer .fullScreenTooltipText {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; }

.fullScreenTooltipContainer:hover .fullScreenTooltipText {
  visibility: visible; }

.btn-span {
  cursor: unset !important;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5 !important; }

.title--overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.mw-100 {
  max-width: 100%; }

.z-feedpost {
  z-index: 1029 !important; }

.mw-405 {
  max-width: 405px; }

/* Core variables and mixins */
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #efc829;
  --green: #439c7c;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #439c7c;
  --secondary: #f7f3ec;
  --success: #439c7c;
  --info: #17a2b8;
  --warning: #efc829;
  --danger: #dc3545;
  --light: #e5f8f4;
  --dark: #1f0b0b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff; }

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 900; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: #439c7c;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #2c6651;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4, .h5, .nav-tabs .nav-link,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1,
.h1 {
  font-size: 2.49988rem; }

h2,
.h2 {
  font-size: 2.00025rem; }

h3,
.h3 {
  font-size: 1.75rem; }

h4,
.h4 {
  font-size: 1.37462rem; }

h5, .h5, .nav-tabs .nav-link {
  font-size: 1.12525rem; }

h6,
.h6 {
  font-size: 1.00012rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: '\2014\00A0'; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cae3da; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9dccbb; }

.table-hover .table-primary:hover {
  background-color: #b9dace; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b9dace; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fdfcfa; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #fbf9f5; }

.table-hover .table-secondary:hover {
  background-color: #f6f1e8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f6f1e8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cae3da; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9dccbb; }

.table-hover .table-success:hover {
  background-color: #b9dace; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b9dace; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbf0c3; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7e290; }

.table-hover .table-warning:hover {
  background-color: #f9eaab; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f9eaab; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f8fdfc; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f1fbf9; }

.table-hover .table-light:hover {
  background-color: #e4f7f4; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e4f7f4; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c0bbbb; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8b8080; }

.table-hover .table-dark:hover {
  background-color: #b4aeae; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4aeae; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #ffffff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #8fcfb8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.00012rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.74987rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.74987rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.00012rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #439c7c; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.74987rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(67, 156, 124, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #439c7c;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23439c7c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #439c7c;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #439c7c;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23439c7c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #439c7c;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #439c7c; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #439c7c; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #439c7c; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5ab896;
  background-color: #5ab896; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #439c7c; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #439c7c; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #439c7c;
  box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.74987rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #439c7c;
  border-color: #439c7c; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #388167;
    border-color: #347860; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background-color: #388167;
    border-color: #347860;
    box-shadow: 0 0 0 0.2rem rgba(95, 171, 144, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #439c7c;
    border-color: #439c7c; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #347860;
    border-color: #306f59; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 171, 144, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #f7f3ec;
  border-color: #f7f3ec; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #ece2d1;
    border-color: #e8dcc8; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #ece2d1;
    border-color: #e8dcc8;
    box-shadow: 0 0 0 0.2rem rgba(215, 212, 207, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #f7f3ec;
    border-color: #f7f3ec; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e8dcc8;
    border-color: #e4d7bf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 212, 207, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #439c7c;
  border-color: #439c7c; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #388167;
    border-color: #347860; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background-color: #388167;
    border-color: #347860;
    box-shadow: 0 0 0 0.2rem rgba(95, 171, 144, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #439c7c;
    border-color: #439c7c; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #347860;
    border-color: #306f59; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 171, 144, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #efc829;
  border-color: #efc829; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e1b811;
    border-color: #d5ae10; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e1b811;
    border-color: #d5ae10;
    box-shadow: 0 0 0 0.2rem rgba(208, 176, 41, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #efc829;
    border-color: #efc829; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d5ae10;
    border-color: #c9a50f; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 176, 41, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #e5f8f4;
  border-color: #e5f8f4; }
  .btn-light:hover {
    color: #212529;
    background-color: #c7f0e7;
    border-color: #bdede3; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #c7f0e7;
    border-color: #bdede3;
    box-shadow: 0 0 0 0.2rem rgba(200, 216, 214, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #e5f8f4;
    border-color: #e5f8f4; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #bdede3;
    border-color: #b3eadf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 216, 214, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #1f0b0b;
  border-color: #1f0b0b; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #030101;
    border-color: black; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: #030101;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(65, 48, 48, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #1f0b0b;
    border-color: #1f0b0b; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: black;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 48, 48, 0.5); }

.btn-outline-primary {
  color: #439c7c;
  border-color: #439c7c; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #439c7c;
    border-color: #439c7c; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #439c7c;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #439c7c;
    border-color: #439c7c; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.5); }

.btn-outline-secondary {
  color: #f7f3ec;
  border-color: #f7f3ec; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #f7f3ec;
    border-color: #f7f3ec; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 243, 236, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #f7f3ec;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #f7f3ec;
    border-color: #f7f3ec; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 243, 236, 0.5); }

.btn-outline-success {
  color: #439c7c;
  border-color: #439c7c; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #439c7c;
    border-color: #439c7c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #439c7c;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #439c7c;
    border-color: #439c7c; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #efc829;
  border-color: #efc829; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #efc829;
    border-color: #efc829; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 200, 41, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #efc829;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #efc829;
    border-color: #efc829; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 200, 41, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #e5f8f4;
  border-color: #e5f8f4; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #e5f8f4;
    border-color: #e5f8f4; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 248, 244, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e5f8f4;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e5f8f4;
    border-color: #e5f8f4; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(229, 248, 244, 0.5); }

.btn-outline-dark {
  color: #1f0b0b;
  border-color: #1f0b0b; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #1f0b0b;
    border-color: #1f0b0b; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(31, 11, 11, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #1f0b0b;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1f0b0b;
    border-color: #1f0b0b; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(31, 11, 11, 0.5); }

.btn-link {
  font-weight: 400;
  color: #439c7c; }
  .btn-link:hover {
    color: #2c6651; }
  .btn-link:focus, .btn-link.focus {
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.00012rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.74987rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ''; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^='top'], .dropdown-menu[x-placement^='right'], .dropdown-menu[x-placement^='bottom'], .dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #439c7c; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.74987rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type='radio'],
  .btn-group-toggle > .btn input[type='checkbox'],
  .btn-group-toggle > .btn-group > .btn input[type='radio'],
  .btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type='radio'],
  .input-group-text input[type='checkbox'] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.00012rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.74987rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #439c7c;
    background-color: #439c7c; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #8fcfb8; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #b3decf;
    border-color: #b3decf; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    background-color: #ffffff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #439c7c;
  background-color: #439c7c; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(67, 156, 124, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(67, 156, 124, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(67, 156, 124, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(67, 156, 124, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #8fcfb8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size='1']) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.74987rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.00012rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #8fcfb8;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: 'Browse';
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #439c7c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3decf; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #439c7c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3decf; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #439c7c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3decf; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #439c7c; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.40616rem;
  padding-bottom: 0.40616rem;
  margin-right: 1rem;
  font-size: 1.00012rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.00012rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' strokeMiterlimit='10' strokeWidth='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' strokeMiterlimit='10' strokeWidth='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #439c7c;
  background-color: #ffffff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #2c6651;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #439c7c;
  border-color: #439c7c; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.00012rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.74987rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #439c7c; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #347860; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #f7f3ec; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e8dcc8; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 243, 236, 0.5); }

.badge-success {
  color: #ffffff;
  background-color: #439c7c; }
  a.badge-success:hover, a.badge-success:focus {
    color: #ffffff;
    background-color: #347860; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.5); }

.badge-info {
  color: #ffffff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #ffffff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #efc829; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d5ae10; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 200, 41, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #e5f8f4; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #bdede3; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(229, 248, 244, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #1f0b0b; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: black; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(31, 11, 11, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #235140;
  background-color: #d9ebe5;
  border-color: #cae3da; }
  .alert-primary hr {
    border-top-color: #b9dace; }
  .alert-primary .alert-link {
    color: #142d24; }

.alert-secondary {
  color: #807e7b;
  background-color: #fdfdfb;
  border-color: #fdfcfa; }
  .alert-secondary hr {
    border-top-color: #f6f1e8; }
  .alert-secondary .alert-link {
    color: #666462; }

.alert-success {
  color: #235140;
  background-color: #d9ebe5;
  border-color: #cae3da; }
  .alert-success hr {
    border-top-color: #b9dace; }
  .alert-success .alert-link {
    color: #142d24; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #7c6815;
  background-color: #fcf4d4;
  border-color: #fbf0c3; }
  .alert-warning hr {
    border-top-color: #f9eaab; }
  .alert-warning .alert-link {
    color: #50430e; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #77817f;
  background-color: #fafefd;
  border-color: #f8fdfc; }
  .alert-light hr {
    border-top-color: #e4f7f4; }
  .alert-light .alert-link {
    color: #5f6665; }

.alert-dark {
  color: #100606;
  background-color: #d2cece;
  border-color: #c0bbbb; }
  .alert-dark hr {
    border-top-color: #b4aeae; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #439c7c;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #439c7c;
    border-color: #439c7c; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #235140;
  background-color: #cae3da; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #235140;
    background-color: #b9dace; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #235140;
    border-color: #235140; }

.list-group-item-secondary {
  color: #807e7b;
  background-color: #fdfcfa; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #807e7b;
    background-color: #f6f1e8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #807e7b;
    border-color: #807e7b; }

.list-group-item-success {
  color: #235140;
  background-color: #cae3da; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #235140;
    background-color: #b9dace; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #235140;
    border-color: #235140; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #7c6815;
  background-color: #fbf0c3; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7c6815;
    background-color: #f9eaab; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #7c6815;
    border-color: #7c6815; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #77817f;
  background-color: #f8fdfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #77817f;
    background-color: #e4f7f4; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #77817f;
    border-color: #77817f; }

.list-group-item-dark {
  color: #100606;
  background-color: #c0bbbb; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #100606;
    background-color: #b4aeae; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #100606;
    border-color: #100606; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5; }
  .close:hover {
    color: #000000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: 0.75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ''; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 100000000;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.74987rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: '';
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^='top'] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^='top'] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^='right'] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^='right'] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^='bottom'] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^='left'] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^='left'] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.74987rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: '';
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^='top'] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^='top'] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^='top'] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^='right'] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^='right'] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^='right'] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^='right'] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^='bottom'] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^='bottom'] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^='bottom'] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^='bottom'] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: '';
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^='left'] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^='left'] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^='left'] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
  width: auto; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ''; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #439c7c !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #347860 !important; }

.bg-secondary {
  background-color: #f7f3ec !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e8dcc8 !important; }

.bg-success {
  background-color: #439c7c !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #347860 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #efc829 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d5ae10 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #e5f8f4 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #bdede3 !important; }

.bg-dark {
  background-color: #1f0b0b !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top, table.coded-grades thead tr > th {
  border-top: 1px solid #dee2e6 !important; }

.border-right, table.coded-grades thead tr > th:nth-child(n + 5):not(:last-child), table.coded-grades thead tr > th:nth-last-child(1), table.coded-grades tbody tr > td:nth-child(n + 5):not(:last-child), table.coded-grades tbody tr > td:nth-last-child(1) {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom, table.coded-grades tbody tr > td {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #439c7c !important; }

.border-secondary {
  border-color: #f7f3ec !important; }

.border-success {
  border-color: #439c7c !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #efc829 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #e5f8f4 !important; }

.border-dark {
  border-color: #1f0b0b !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg, .blocks, .quiz-complete, .tab-content {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after, .form-block::after {
  display: block;
  clear: both;
  content: ''; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .app--link, .exam-proctoring .accordion .button, .submission-content, .accordion .link {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ''; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .app--link, .exam-proctoring .accordion .button, .accordion .link {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm, .app--link:hover, .back-btn, .quiz-complete--body .resource, .card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow, .course-module, .card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25, .profile-content--row .label {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75, .profile-content--row .details {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100, .quiz-complete {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, ul.arrow li, .inner__menu, .tab-content,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4, .form--wrapper .tab-content .btn-grp,
.my-4,
.question--option {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4,
.question--option {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2,
.side-notification .notification-list .formatted-date {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2,
aside .main-menu .menu {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2,
.side-notification .notification-list .formatted-date {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2,
aside .main-menu .menu {
  padding-left: 0.5rem !important; }

.p-3, .blocks {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4,
.form--wrapper .tab-content .btn-grp {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4,
.form--wrapper .tab-content .btn-grp {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.25rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.25rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.25rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.25rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.25rem !important; }

.m-n3 {
  margin: -0.25rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.25rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.25rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.25rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.25rem !important; }

.m-n4 {
  margin: -0.25rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -0.25rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -0.25rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -0.25rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -0.25rem !important; }

.m-n5 {
  margin: -0.25rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -0.25rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -0.25rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -0.25rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -0.25rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto, .card .small,
.mx-auto,
.chatbot--body {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto, .form--wrapper .tab-content .btn-grp .cancel,
.mx-auto,
.chatbot--body {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.25rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.25rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.25rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.25rem !important; }
  .m-sm-n3 {
    margin: -0.25rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.25rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.25rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.25rem !important; }
  .m-sm-n4 {
    margin: -0.25rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -0.25rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -0.25rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -0.25rem !important; }
  .m-sm-n5 {
    margin: -0.25rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -0.25rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -0.25rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -0.25rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.25rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.25rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.25rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.25rem !important; }
  .m-md-n3 {
    margin: -0.25rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.25rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.25rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.25rem !important; }
  .m-md-n4 {
    margin: -0.25rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -0.25rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -0.25rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -0.25rem !important; }
  .m-md-n5 {
    margin: -0.25rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -0.25rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -0.25rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -0.25rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.25rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.25rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.25rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.25rem !important; }
  .m-lg-n3 {
    margin: -0.25rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.25rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.25rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.25rem !important; }
  .m-lg-n4 {
    margin: -0.25rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -0.25rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -0.25rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -0.25rem !important; }
  .m-lg-n5 {
    margin: -0.25rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -0.25rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -0.25rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -0.25rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.25rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.25rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.25rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.25rem !important; }
  .m-xl-n3 {
    margin: -0.25rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.25rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.25rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.25rem !important; }
  .m-xl-n4 {
    margin: -0.25rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -0.25rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -0.25rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -0.25rem !important; }
  .m-xl-n5 {
    margin: -0.25rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -0.25rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -0.25rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -0.25rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap, .app--link .text {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase, .profile-content--table th {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: 900 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #439c7c !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #2c6651 !important; }

.text-secondary {
  color: #f7f3ec !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #e0d1b6 !important; }

.text-success {
  color: #439c7c !important; }

a.text-success:hover, a.text-success:focus {
  color: #2c6651 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #efc829 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #bd9b0e !important; }

.text-danger, .invalid {
  color: #dc3545 !important; }

a.text-danger:hover, a.invalid:hover, a.text-danger:focus, a.invalid:focus {
  color: #a71d2a !important; }

.text-light {
  color: #e5f8f4 !important; }

a.text-light:hover, a.text-light:focus {
  color: #a9e8db !important; }

.text-dark {
  color: #1f0b0b !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* base */
* {
  margin: 0;
  padding: 0; }

body,
html {
  color: #1f0b0b !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  background: #f3f7fa;
  min-width: auto !important; }

input {
  outline: none; }

button:focus,
select:focus,
a:focus {
  outline: none; }

button::-moz-focus-inner,
select::-moz-focus-inner,
a::-moz-focus-inner {
  border: 0;
  outline: 0; }

select {
  cursor: pointer; }

.custom-control-label {
  cursor: pointer; }

a {
  text-decoration: none;
  color: #439c7c; }
  a:hover, a:focus {
    text-decoration: none;
    color: #245543; }

.object-fit {
  height: 140px;
  width: 100%;
  object-fit: contain; }

.fade-in, section {
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  -webkit-animation: fadein 1s;
  -o-animation: fadein 1s;
  zoom: 1; }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ReactModal__Overlay--after-open {
  z-index: 1030 !important; }

/* fonts */
@font-face {
  font-family: 'fuseclassroom';
  src: url("fonts/fuseclassroom.eot?x6irf9");
  src: url("fonts/fuseclassroom.eot?x6irf9#iefix") format("embedded-opentype"), url("fonts/fuseclassroom.ttf?x6irf9") format("truetype"), url("fonts/fuseclassroom.woff?x6irf9") format("woff"), url("fonts/fuseclassroom.svg?x6irf9#fuseclassroom") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fuseclassroom', fantasy !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icn-po {
  position: relative;
  top: 2px; }

.icn-po-4 {
  position: relative;
  top: 4px; }

.icn-xm {
  font-size: 12px; }

.icn-sm {
  font-size: 0.625rem; }

.icn-lg {
  font-size: 1.375rem;
  line-height: 0.75em;
  vertical-align: -15%; }

.icn-md {
  font-size: 1rem;
  line-height: 0.75em;
  vertical-align: -15%; }

.icn-2x, .quiz-complete--body .resource--item [class^='icon-'],
.quiz-complete--body .resource--item [class*=' icon-'] {
  font-size: 2em; }

.icn-3x {
  font-size: 3em; }

.icn-4x {
  font-size: 4em; }

.icn-5x {
  font-size: 5em; }

.icn-fw {
  width: 1.28571429em;
  text-align: center; }

.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.icon-rotate-270, .proctoring-icon__rotate {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.icon-flip-horizontal, .submission-header .file-paginate--item:first-child {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1); }

.icon-like-fill:before {
  content: '\e903'; }

.icon-announcement:before {
  content: "\e905"; }

.icon-arrow:before {
  content: "\e906"; }

.icon-assignment:before {
  content: "\e907"; }

.icon-dashboard:before {
  content: "\e908"; }

.icon-discussion:before {
  content: "\e909"; }

.icon-download:before {
  content: "\e90a"; }

.icon-file:before {
  content: "\e90b"; }

.icon-folder:before {
  content: "\e90c"; }

.icon-grade:before {
  content: "\e90d"; }

.icon-live:before {
  content: "\e90e"; }

.icon-online-learning:before {
  content: "\e90f"; }

.icon-overview:before {
  content: "\e910"; }

.icon-photo-roster:before {
  content: "\e911"; }

.icon-clock:before {
  content: "\e912"; }

.icon-feedback:before {
  content: "\e913"; }

.icon-play:before {
  content: "\e914"; }

.icon-circle-close:before {
  content: "\e915"; }

.icon-check:before {
  content: "\e916"; }

.icon-circle-check:before {
  content: "\e917"; }

.icon-dot:before {
  content: "\e918"; }

.icon-recorded:before {
  content: "\e919"; }

.icon-a-plus:before {
  content: "\e91a"; }

.icon-attendence:before {
  content: "\e91b"; }

.icon-checkbox:before {
  content: "\e91c"; }

.icon-circle-tick:before {
  content: "\e91d"; }

.icon-comment:before {
  content: "\e91e"; }

.icon-settings:before {
  content: "\e91f"; }

.icon-lock:before {
  content: "\e920"; }

.icon-file-o:before {
  content: "\e921"; }

.icon-preview:before {
  content: "\e922"; }

.icon-pdf:before {
  content: "\e923"; }

.icon-photo:before {
  content: "\e924"; }

.icon-plus:before {
  content: "\e925"; }

.icon-quiz:before {
  content: "\e926"; }

.icon-radio:before {
  content: "\e927"; }

.icon-textarea:before {
  content: "\e928"; }

.icon-like:before {
  content: "\e929"; }

.icon-arrow-right:before {
  content: "\e92a"; }

.icon-duplicate:before {
  content: "\e92b"; }

.icon-delete:before {
  content: "\e92c"; }

.icon-cross:before {
  content: "\e92d"; }

.icon-student-status:before {
  content: "\e92e"; }

.icon-edit:before {
  content: "\e92f"; }

.icon-attendance:before {
  content: "\e930"; }

.icon-clip:before {
  content: "\e931"; }

.icon-phone:before {
  content: "\e932"; }

.icon-place:before {
  content: "\e933"; }

.icon-github:before {
  content: "\e934"; }

.icon-linkedin:before {
  content: "\e935"; }

.icon-edit-line:before {
  content: "\e936"; }

.icon-angle:before {
  content: "\e937"; }

.icon-three-dots:before {
  content: "\e938"; }

.icon-move:before {
  content: "\e939"; }

.icon-notification:before {
  content: "\e93a"; }

.icon-menu:before {
  content: "\e93b"; }

.icon-home:before {
  content: "\e93c"; }

.icon-message:before {
  content: "\e93d"; }

.icon-search:before {
  content: "\e93e"; }

.icon-picture:before {
  content: "\e93f"; }

.icon-section:before {
  content: "\e940"; }

.icon-chatbot:before {
  content: "\e941"; }

.icon-save:before {
  content: "\e942"; }

.icon-presentation:before {
  content: "\e943"; }

.icon-e-book:before {
  content: "\e944"; }

.icon-reload:before {
  content: "\e945"; }

.icon-alert:before {
  content: "\e946"; }

.icon-cloud:before {
  content: "\e947"; }

.icon-info:before {
  content: "\e948"; }

.icon-run:before {
  content: "\e949"; }

.icon-close-octagon:before {
  content: "\e94a"; }

.icon-chrome:before {
  content: "\e94b"; }

.icon-video:before {
  content: "\e94c"; }

.icon-mic:before {
  content: "\e94d"; }

.icon-minus:before {
  content: "\e94e"; }

.icon-volume:before {
  content: "\e94f"; }

.icon-zoom-out:before {
  content: "\e950"; }

.icon-zoom-in:before {
  content: "\e951"; }

.icon-six-dots:before {
  content: "\e952"; }

.icon-approval:before {
  content: "\e953"; }

.icon-pause:before {
  content: "\e954"; }

.icon-person:before {
  content: "\e955"; }

.icon-volume-mute:before {
  content: "\e956"; }

.icon-poll:before {
  content: "\e957"; }

.icon-link:before {
  content: "\e958"; }

.icon-attachment:before {
  content: "\e959"; }

.icon-check-dash:before {
  content: "\e95a"; }

.icon-view:before {
  content: "\e95b"; }

.icon-hide:before {
  content: "\e95c"; }

.icon-audio-record:before {
  content: "\e95d"; }

.icon-stop:before {
  content: "\e95e"; }

.icon-hamburger-menu:before {
  content: "\e95f"; }

.icon-referral:before {
  content: "\e960"; }

.icon-analytics:before {
  content: "\e961"; }

.icon-outline-home:before {
  content: "\e962"; }

.icon-pentool:before {
  content: "\e963"; }

.icon-rotate-anticlockwise:before {
  content: "\e964"; }

.icon-rotate-clockwise:before {
  content: "\e965"; }

.icon-skip-back:before {
  content: "\e900"; }

.icon-skip-forward:before {
  content: "\e901"; }

@font-face {
  font-family: 'Glyphicons Halflings', fantasy;
  src: url("./fonts/bootstrap/glyphicons-halflings-regular.eot");
  src: url("./fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/bootstrap/glyphicons-halflings-regular.woff2") format("woff2"), url("./fonts/bootstrap/glyphicons-halflings-regular.woff") format("woff"), url("./fonts/bootstrap/glyphicons-halflings-regular.ttf") format("truetype"), url("./fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg"); }

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings', fantasy;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.glyphicon-asterisk:before {
  content: '\002a'; }

.glyphicon-plus:before {
  content: '\002b'; }

.glyphicon-eur:before,
.glyphicon-euro:before {
  content: '\20ac'; }

.glyphicon-minus:before {
  content: '\2212'; }

.glyphicon-cloud:before {
  content: '\2601'; }

.glyphicon-envelope:before {
  content: '\2709'; }

.glyphicon-pencil:before {
  content: '\270f'; }

.glyphicon-glass:before {
  content: '\e001'; }

.glyphicon-music:before {
  content: '\e002'; }

.glyphicon-search:before {
  content: '\e003'; }

.glyphicon-heart:before {
  content: '\e005'; }

.glyphicon-star:before {
  content: '\e006'; }

.glyphicon-star-empty:before {
  content: '\e007'; }

.glyphicon-user:before {
  content: '\e008'; }

.glyphicon-film:before {
  content: '\e009'; }

.glyphicon-th-large:before {
  content: '\e010'; }

.glyphicon-th:before {
  content: '\e011'; }

.glyphicon-th-list:before {
  content: '\e012'; }

.glyphicon-ok:before {
  content: '\e013'; }

.glyphicon-remove:before {
  content: '\e014'; }

.glyphicon-zoom-in:before {
  content: '\e015'; }

.glyphicon-zoom-out:before {
  content: '\e016'; }

.glyphicon-off:before {
  content: '\e017'; }

.glyphicon-signal:before {
  content: '\e018'; }

.glyphicon-cog:before {
  content: '\e019'; }

.glyphicon-trash:before {
  content: '\e020'; }

.glyphicon-home:before {
  content: '\e021'; }

.glyphicon-file:before {
  content: '\e022'; }

.glyphicon-time:before {
  content: '\e023'; }

.glyphicon-road:before {
  content: '\e024'; }

.glyphicon-download-alt:before {
  content: '\e025'; }

.glyphicon-download:before {
  content: '\e026'; }

.glyphicon-upload:before {
  content: '\e027'; }

.glyphicon-inbox:before {
  content: '\e028'; }

.glyphicon-play-circle:before {
  content: '\e029'; }

.glyphicon-repeat:before {
  content: '\e030'; }

.glyphicon-refresh:before {
  content: '\e031'; }

.glyphicon-list-alt:before {
  content: '\e032'; }

.glyphicon-lock:before {
  content: '\e033'; }

.glyphicon-flag:before {
  content: '\e034'; }

.glyphicon-headphones:before {
  content: '\e035'; }

.glyphicon-volume-off:before {
  content: '\e036'; }

.glyphicon-volume-down:before {
  content: '\e037'; }

.glyphicon-volume-up:before {
  content: '\e038'; }

.glyphicon-qrcode:before {
  content: '\e039'; }

.glyphicon-barcode:before {
  content: '\e040'; }

.glyphicon-tag:before {
  content: '\e041'; }

.glyphicon-tags:before {
  content: '\e042'; }

.glyphicon-book:before {
  content: '\e043'; }

.glyphicon-bookmark:before {
  content: '\e044'; }

.glyphicon-print:before {
  content: '\e045'; }

.glyphicon-camera:before {
  content: '\e046'; }

.glyphicon-font:before {
  content: '\e047'; }

.glyphicon-bold:before {
  content: '\e048'; }

.glyphicon-italic:before {
  content: '\e049'; }

.glyphicon-text-height:before {
  content: '\e050'; }

.glyphicon-text-width:before {
  content: '\e051'; }

.glyphicon-align-left:before {
  content: '\e052'; }

.glyphicon-align-center:before {
  content: '\e053'; }

.glyphicon-align-right:before {
  content: '\e054'; }

.glyphicon-align-justify:before {
  content: '\e055'; }

.glyphicon-list:before {
  content: '\e056'; }

.glyphicon-indent-left:before {
  content: '\e057'; }

.glyphicon-indent-right:before {
  content: '\e058'; }

.glyphicon-facetime-video:before {
  content: '\e059'; }

.glyphicon-picture:before {
  content: '\e060'; }

.glyphicon-map-marker:before {
  content: '\e062'; }

.glyphicon-adjust:before {
  content: '\e063'; }

.glyphicon-tint:before {
  content: '\e064'; }

.glyphicon-edit:before {
  content: '\e065'; }

.glyphicon-share:before {
  content: '\e066'; }

.glyphicon-check:before {
  content: '\e067'; }

.glyphicon-move:before {
  content: '\e068'; }

.glyphicon-step-backward:before {
  content: '\e069'; }

.glyphicon-fast-backward:before {
  content: '\e070'; }

.glyphicon-backward:before {
  content: '\e071'; }

.glyphicon-play:before {
  content: '\e072'; }

.glyphicon-pause:before {
  content: '\e073'; }

.glyphicon-stop:before {
  content: '\e074'; }

.glyphicon-forward:before {
  content: '\e075'; }

.glyphicon-fast-forward:before {
  content: '\e076'; }

.glyphicon-step-forward:before {
  content: '\e077'; }

.glyphicon-eject:before {
  content: '\e078'; }

.glyphicon-chevron-left:before {
  content: '\e079'; }

.glyphicon-chevron-right:before {
  content: '\e080'; }

.glyphicon-plus-sign:before {
  content: '\e081'; }

.glyphicon-minus-sign:before {
  content: '\e082'; }

.glyphicon-remove-sign:before {
  content: '\e083'; }

.glyphicon-ok-sign:before {
  content: '\e084'; }

.glyphicon-question-sign:before {
  content: '\e085'; }

.glyphicon-info-sign:before {
  content: '\e086'; }

.glyphicon-screenshot:before {
  content: '\e087'; }

.glyphicon-remove-circle:before {
  content: '\e088'; }

.glyphicon-ok-circle:before {
  content: '\e089'; }

.glyphicon-ban-circle:before {
  content: '\e090'; }

.glyphicon-arrow-left:before {
  content: '\e091'; }

.glyphicon-arrow-right:before {
  content: '\e092'; }

.glyphicon-arrow-up:before {
  content: '\e093'; }

.glyphicon-arrow-down:before {
  content: '\e094'; }

.glyphicon-share-alt:before {
  content: '\e095'; }

.glyphicon-resize-full:before {
  content: '\e096'; }

.glyphicon-resize-small:before {
  content: '\e097'; }

.glyphicon-exclamation-sign:before {
  content: '\e101'; }

.glyphicon-gift:before {
  content: '\e102'; }

.glyphicon-leaf:before {
  content: '\e103'; }

.glyphicon-fire:before {
  content: '\e104'; }

.glyphicon-eye-open:before {
  content: '\e105'; }

.glyphicon-eye-close:before {
  content: '\e106'; }

.glyphicon-warning-sign:before {
  content: '\e107'; }

.glyphicon-plane:before {
  content: '\e108'; }

.glyphicon-calendar:before {
  content: '\e109'; }

.glyphicon-random:before {
  content: '\e110'; }

.glyphicon-comment:before {
  content: '\e111'; }

.glyphicon-magnet:before {
  content: '\e112'; }

.glyphicon-chevron-up:before {
  content: '\e113'; }

.glyphicon-chevron-down:before {
  content: '\e114'; }

.glyphicon-retweet:before {
  content: '\e115'; }

.glyphicon-shopping-cart:before {
  content: '\e116'; }

.glyphicon-folder-close:before {
  content: '\e117'; }

.glyphicon-folder-open:before {
  content: '\e118'; }

.glyphicon-resize-vertical:before {
  content: '\e119'; }

.glyphicon-resize-horizontal:before {
  content: '\e120'; }

.glyphicon-hdd:before {
  content: '\e121'; }

.glyphicon-bullhorn:before {
  content: '\e122'; }

.glyphicon-bell:before {
  content: '\e123'; }

.glyphicon-certificate:before {
  content: '\e124'; }

.glyphicon-thumbs-up:before {
  content: '\e125'; }

.glyphicon-thumbs-down:before {
  content: '\e126'; }

.glyphicon-hand-right:before {
  content: '\e127'; }

.glyphicon-hand-left:before {
  content: '\e128'; }

.glyphicon-hand-up:before {
  content: '\e129'; }

.glyphicon-hand-down:before {
  content: '\e130'; }

.glyphicon-circle-arrow-right:before {
  content: '\e131'; }

.glyphicon-circle-arrow-left:before {
  content: '\e132'; }

.glyphicon-circle-arrow-up:before {
  content: '\e133'; }

.glyphicon-circle-arrow-down:before {
  content: '\e134'; }

.glyphicon-globe:before {
  content: '\e135'; }

.glyphicon-wrench:before {
  content: '\e136'; }

.glyphicon-tasks:before {
  content: '\e137'; }

.glyphicon-filter:before {
  content: '\e138'; }

.glyphicon-briefcase:before {
  content: '\e139'; }

.glyphicon-fullscreen:before {
  content: '\e140'; }

.glyphicon-dashboard:before {
  content: '\e141'; }

.glyphicon-paperclip:before {
  content: '\e142'; }

.glyphicon-heart-empty:before {
  content: '\e143'; }

.glyphicon-link:before {
  content: '\e144'; }

.glyphicon-phone:before {
  content: '\e145'; }

.glyphicon-pushpin:before {
  content: '\e146'; }

.glyphicon-usd:before {
  content: '\e148'; }

.glyphicon-gbp:before {
  content: '\e149'; }

.glyphicon-sort:before {
  content: '\e150'; }

.glyphicon-sort-by-alphabet:before {
  content: '\e151'; }

.glyphicon-sort-by-alphabet-alt:before {
  content: '\e152'; }

.glyphicon-sort-by-order:before {
  content: '\e153'; }

.glyphicon-sort-by-order-alt:before {
  content: '\e154'; }

.glyphicon-sort-by-attributes:before {
  content: '\e155'; }

.glyphicon-sort-by-attributes-alt:before {
  content: '\e156'; }

.glyphicon-unchecked:before {
  content: '\e157'; }

.glyphicon-expand:before {
  content: '\e158'; }

.glyphicon-collapse-down:before {
  content: '\e159'; }

.glyphicon-collapse-up:before {
  content: '\e160'; }

.glyphicon-log-in:before {
  content: '\e161'; }

.glyphicon-flash:before {
  content: '\e162'; }

.glyphicon-log-out:before {
  content: '\e163'; }

.glyphicon-new-window:before {
  content: '\e164'; }

.glyphicon-record:before {
  content: '\e165'; }

.glyphicon-save:before {
  content: '\e166'; }

.glyphicon-open:before {
  content: '\e167'; }

.glyphicon-saved:before {
  content: '\e168'; }

.glyphicon-import:before {
  content: '\e169'; }

.glyphicon-export:before {
  content: '\e170'; }

.glyphicon-send:before {
  content: '\e171'; }

.glyphicon-floppy-disk:before {
  content: '\e172'; }

.glyphicon-floppy-saved:before {
  content: '\e173'; }

.glyphicon-floppy-remove:before {
  content: '\e174'; }

.glyphicon-floppy-save:before {
  content: '\e175'; }

.glyphicon-floppy-open:before {
  content: '\e176'; }

.glyphicon-credit-card:before {
  content: '\e177'; }

.glyphicon-transfer:before {
  content: '\e178'; }

.glyphicon-cutlery:before {
  content: '\e179'; }

.glyphicon-header:before {
  content: '\e180'; }

.glyphicon-compressed:before {
  content: '\e181'; }

.glyphicon-earphone:before {
  content: '\e182'; }

.glyphicon-phone-alt:before {
  content: '\e183'; }

.glyphicon-tower:before {
  content: '\e184'; }

.glyphicon-stats:before {
  content: '\e185'; }

.glyphicon-sd-video:before {
  content: '\e186'; }

.glyphicon-hd-video:before {
  content: '\e187'; }

.glyphicon-subtitles:before {
  content: '\e188'; }

.glyphicon-sound-stereo:before {
  content: '\e189'; }

.glyphicon-sound-dolby:before {
  content: '\e190'; }

.glyphicon-sound-5-1:before {
  content: '\e191'; }

.glyphicon-sound-6-1:before {
  content: '\e192'; }

.glyphicon-sound-7-1:before {
  content: '\e193'; }

.glyphicon-copyright-mark:before {
  content: '\e194'; }

.glyphicon-registration-mark:before {
  content: '\e195'; }

.glyphicon-cloud-download:before {
  content: '\e197'; }

.glyphicon-cloud-upload:before {
  content: '\e198'; }

.glyphicon-tree-conifer:before {
  content: '\e199'; }

.glyphicon-tree-deciduous:before {
  content: '\e200'; }

.glyphicon-cd:before {
  content: '\e201'; }

.glyphicon-save-file:before {
  content: '\e202'; }

.glyphicon-open-file:before {
  content: '\e203'; }

.glyphicon-level-up:before {
  content: '\e204'; }

.glyphicon-copy:before {
  content: '\e205'; }

.glyphicon-paste:before {
  content: '\e206'; }

.glyphicon-alert:before {
  content: '\e209'; }

.glyphicon-equalizer:before {
  content: '\e210'; }

.glyphicon-king:before {
  content: '\e211'; }

.glyphicon-queen:before {
  content: '\e212'; }

.glyphicon-pawn:before {
  content: '\e213'; }

.glyphicon-bishop:before {
  content: '\e214'; }

.glyphicon-knight:before {
  content: '\e215'; }

.glyphicon-baby-formula:before {
  content: '\e216'; }

.glyphicon-tent:before {
  content: '\26fa'; }

.glyphicon-blackboard:before {
  content: '\e218'; }

.glyphicon-bed:before {
  content: '\e219'; }

.glyphicon-apple:before {
  content: '\f8ff'; }

.glyphicon-erase:before {
  content: '\e221'; }

.glyphicon-hourglass:before {
  content: '\231b'; }

.glyphicon-lamp:before {
  content: '\e223'; }

.glyphicon-duplicate:before {
  content: '\e224'; }

.glyphicon-piggy-bank:before {
  content: '\e225'; }

.glyphicon-scissors:before {
  content: '\e226'; }

.glyphicon-bitcoin:before {
  content: '\e227'; }

.glyphicon-btc:before {
  content: '\e227'; }

.glyphicon-xbt:before {
  content: '\e227'; }

.glyphicon-yen:before {
  content: '\00a5'; }

.glyphicon-jpy:before {
  content: '\00a5'; }

.glyphicon-ruble:before {
  content: '\20bd'; }

.glyphicon-rub:before {
  content: '\20bd'; }

.glyphicon-scale:before {
  content: '\e230'; }

.glyphicon-ice-lolly:before {
  content: '\e231'; }

.glyphicon-ice-lolly-tasted:before {
  content: '\e232'; }

.glyphicon-education:before {
  content: '\e233'; }

.glyphicon-option-horizontal:before {
  content: '\e234'; }

.glyphicon-option-vertical:before {
  content: '\e235'; }

.glyphicon-menu-hamburger:before {
  content: '\e236'; }

.glyphicon-modal-window:before {
  content: '\e237'; }

.glyphicon-oil:before {
  content: '\e238'; }

.glyphicon-grain:before {
  content: '\e239'; }

.glyphicon-sunglasses:before {
  content: '\e240'; }

.glyphicon-text-size:before {
  content: '\e241'; }

.glyphicon-text-color:before {
  content: '\e242'; }

.glyphicon-text-background:before {
  content: '\e243'; }

.glyphicon-object-align-top:before {
  content: '\e244'; }

.glyphicon-object-align-bottom:before {
  content: '\e245'; }

.glyphicon-object-align-horizontal:before {
  content: '\e246'; }

.glyphicon-object-align-left:before {
  content: '\e247'; }

.glyphicon-object-align-vertical:before {
  content: '\e248'; }

.glyphicon-object-align-right:before {
  content: '\e249'; }

.glyphicon-triangle-right:before {
  content: '\e250'; }

.glyphicon-triangle-left:before {
  content: '\e251'; }

.glyphicon-triangle-bottom:before {
  content: '\e252'; }

.glyphicon-triangle-top:before {
  content: '\e253'; }

.glyphicon-console:before {
  content: '\e254'; }

.glyphicon-superscript:before {
  content: '\e255'; }

.glyphicon-subscript:before {
  content: '\e256'; }

.glyphicon-menu-left:before {
  content: '\e257'; }

.glyphicon-menu-right:before {
  content: '\e258'; }

.glyphicon-menu-down:before {
  content: '\e259'; }

.glyphicon-menu-up:before {
  content: '\e260'; }

.login-wrapper {
  display: flex;
  justify-content: center;
  background: #00000078;
  height: 100vh; }
  .login-wrapper .login-block {
    margin-top: 30px;
    width: 350px;
    height: fit-content;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); }
    .login-wrapper .login-block .header-img img {
      width: 100%; }
    .login-wrapper .login-block .login-form .form-group label {
      font-weight: 600; }
    .login-wrapper .login-block .login-form .icon-position {
      position: absolute;
      right: 12px;
      top: 10px;
      cursor: pointer; }

.session-expired {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .session-expired .img-wrap {
    width: 200px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .session-expired .img-wrap img {
      max-width: 100%; }
  .session-expired--title {
    font-size: 1.75rem;
    color: #439c7c;
    margin: 0.625rem 0 0.625rem 0; }
  .session-expired--text {
    font-size: 16px;
    color: #182929;
    margin-bottom: 0.9375rem;
    text-align: center; }
  .session-expired .form-inline label {
    margin-right: 1rem; }
  .session-expired--input {
    width: 200px; }
  .session-expired .icon-position {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer; }

.no-internet {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-internet--content {
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.25rem; }
    .no-internet--content img {
      max-width: 90%; }
  .no-internet--title {
    font-size: 1.75rem;
    color: #439c7c;
    margin: 1.25rem 0 0.625rem 0; }
  .no-internet--text {
    font-size: 16px;
    color: #182929;
    margin-bottom: 0.9375rem; }

/* for forum notification*/
.forum-notify {
  display: flex;
  margin: 20px 0px; }
  .forum-notify--title {
    width: 380px;
    text-align: center; }
  .forum-notify--border {
    width: 100%;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 10px; }

/* for annoucement notification*/
.announcement-notify {
  display: flex;
  align-items: baseline; }
  .announcement-notify--from {
    width: 160px; }
  .announcement-notify--other {
    width: 190px; }
  .announcement-notify--border {
    border-bottom: 1px solid #dee2e6;
    width: 100%; }

.empty-state-wrapper {
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  .empty-state-wrapper .empty-state-title {
    font-size: 20px;
    font-weight: 600;
    color: #439c7c;
    margin: 20px 10px 0px 10px; }
  .empty-state-wrapper .empty-state-info {
    padding: 10px;
    width: 250px; }

.contain-vh {
  height: 65vh; }

.assessment-vs--fix-height {
  height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: hidden !important; }

.service-terminated {
  height: 100vh;
  background-color: #e5f8f4;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; }

.icon-preview-increase {
  font-size: 20px !important; }

.icon-preview-file {
  font-size: 25px; }

.agenda-modal ol {
  margin-left: 11px; }
  .agenda-modal ol li {
    padding: 0px 5px; }

.filename {
  font-weight: 400;
  padding: 5px 10px;
  margin: 0;
  background: #f7fdfc;
  border-width: 0 0 2px 0;
  border-bottom: 2px solid #439c7c; }

.submission {
  display: flex;
  height: calc(100vh - 187px) !important; }
  .submission .uploading-images {
    width: 19%; }
    .submission .uploading-images .files-tabs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
      .submission .uploading-images .files-tabs ul {
        display: flex;
        flex-direction: column;
        list-style: none; }
        .submission .uploading-images .files-tabs ul li {
          position: relative; }
          .submission .uploading-images .files-tabs ul li.file-image {
            padding: 15px;
            padding-left: 0;
            display: flex;
            align-items: center; }
            .submission .uploading-images .files-tabs ul li.file-image .move {
              cursor: move;
              height: 100%;
              display: flex;
              align-items: center;
              padding: 0px 15px;
              color: #bfbfbf; }
            .submission .uploading-images .files-tabs ul li.file-image .image {
              position: relative;
              height: 125px;
              width: 100%;
              background-color: #e9ecef; }
              .submission .uploading-images .files-tabs ul li.file-image .image img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                max-width: 100%;
                max-height: 100%; }
            .submission .uploading-images .files-tabs ul li.file-image:hover {
              background: #ebf9ff; }
          .submission .uploading-images .files-tabs ul li .delete {
            position: absolute;
            z-index: 5;
            top: 10px;
            right: 10px;
            background: #e9ecef;
            border-radius: 50%;
            display: flex;
            align-items: center;
            padding: 3px;
            cursor: pointer; }
            .submission .uploading-images .files-tabs ul li .delete:hover {
              background: #dee2e6; }
          .submission .uploading-images .files-tabs ul li.active {
            background: #ebf9ff; }
  .submission .preview-image {
    width: calc(100% - 19%);
    padding: 15px; }
    .submission .preview-image .preview-buttons {
      border: none;
      background-color: #ffffff;
      width: 20px;
      border-radius: 4px; }
      .submission .preview-image .preview-buttons .reload-reflect {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        display: inline-block; }
    .submission .preview-image .preview {
      height: calc(100% - 65px);
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      justify-content: center; }
      .submission .preview-image .preview .area {
        height: 100%; }

.inactive-user .icon-cross {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 18px;
  margin-right: 6px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  padding: 0px 5px;
  background: #9ba0a5;
  border-radius: 50%;
  font-size: 6px;
  font-weight: 600;
  color: #ffffff; }

.inactive-user .user-name {
  color: #6c757d;
  font-style: italic; }

.modal-title {
  width: 90%; }

.upskill {
  background-color: #f9f9fc;
  height: 100vh; }
  .upskill .navbar-light,
  .upskill .bg-light {
    background-color: #f4f1f9 !important; }
    .upskill .navbar-light .navbar-brand.lg,
    .upskill .bg-light .navbar-brand.lg {
      background-color: #f4f1f9;
      padding-bottom: 0rem;
      width: 140px;
      padding-top: 0px;
      padding-left: 10px; }
      .upskill .navbar-light .navbar-brand.lg svg,
      .upskill .bg-light .navbar-brand.lg svg {
        width: 130px; }
    .upskill .navbar-light .navbar-brand div svg,
    .upskill .bg-light .navbar-brand div svg {
      width: 130px; }
  .upskill aside {
    background-color: #2d1e48; }
    .upskill aside .main-menu .menu .menu--item {
      color: #6d49b1; }
      .upskill aside .main-menu .menu .menu--item .icon {
        color: #6d49b1;
        transition: background-color 0.5s ease; }
        .upskill aside .main-menu .menu .menu--item .icon:hover {
          background-color: rgba(109, 73, 177, 0.247); }
      .upskill aside .main-menu .menu .menu--item.active {
        color: #ffffff; }
        .upskill aside .main-menu .menu .menu--item.active .icon {
          background: #6d49b1;
          color: #ffffff;
          transition: background-color 0.5s ease; }
  .upskill .app--item:hover,
  .upskill .app--link:hover {
    border-color: #6d49b1 !important; }
    .upskill .app--item:hover .app--item--content,
    .upskill .app--link:hover .app--item--content {
      color: #6d49b1 !important; }
    .upskill .app--item:hover .app--link.active,
    .upskill .app--link:hover .app--link.active {
      color: #6d49b1;
      border-color: #6d49b1; }
  .upskill .inner__menu .app--link.active {
    color: #6d49b1;
    border-color: #6d49b1; }
  .upskill .left-content .app--link.active {
    color: #6d49b1;
    border-right: 0.4rem solid currentColor !important;
    border-color: #6d49b1 !important; }
  .upskill .form-control:focus {
    border-color: #6d49b1;
    box-shadow: 0 0 0 0.2rem rgba(109, 73, 177, 0.25); }
  .upskill .fuse-tabs .nav-tabs .nav-item .nav-link:hover,
  .upskill .files-tabs .nav-tabs .nav-item .nav-link:hover {
    color: #ffffff !important;
    border-color: #6d49b1;
    background: #6d49b1; }
  .upskill .fuse-tabs .nav-tabs .nav-item .nav-link.active,
  .upskill .files-tabs .nav-tabs .nav-item .nav-link.active {
    border-color: currentColor;
    color: #6d49b1; }
    .upskill .fuse-tabs .nav-tabs .nav-item .nav-link.active:hover,
    .upskill .files-tabs .nav-tabs .nav-item .nav-link.active:hover {
      color: #ffffff;
      border-color: currentColor; }
  .upskill .fuse-tabs .nav-tabs .nav-item.active .nav-link {
    color: #6d49b1;
    border-bottom: 4px solid #6d49b1; }
  .upskill .exam .exam--tabs .nav-link {
    color: #56437a; }
    .upskill .exam .exam--tabs .nav-link.active {
      color: #ffffff;
      background: #6d49b1;
      border-color: #6d49b1; }
  .upskill .course-module .module-item .icon-dot,
  .upskill .file-click,
  .upskill .table th {
    color: #6d49b1 !important; }
    .upskill .course-module .module-item .icon-dot .icon-download,
    .upskill .file-click .icon-download,
    .upskill .table th .icon-download {
      color: #6d49b1; }
  .upskill .accordion .collapse.show ~ .link {
    color: #6d49b1; }
    .upskill .accordion .collapse.show ~ .link .title--overflow {
      color: #6d49b1 !important; }
  .upskill .chapter--item .title--overflow {
    color: #1f0b0b !important; }
  .upskill .empty-state-wrapper .empty-state-title,
  .upskill .discussion .post-container .upload-sec-list li.add-more-img .upload-file:hover {
    color: #6d49b1; }
  .upskill .breadcrumb-item a {
    color: #6d49b1; }
  .upskill .discussion .post-container .post-link li .post-action.active {
    color: #6d49b1; }
  .upskill .wizard--steps .nav-link.active {
    color: #6d49b1; }
    .upskill .wizard--steps .nav-link.active .icon-check {
      background: #6d49b1; }
  .upskill .user .dropdown--item:hover,
  .upskill .add-program {
    background: #56437a; }
  .upskill .add-link > * span[class^='icon-'],
  .upskill .add-link > * [class*=' icon-'],
  .upskill .form-builder .form-cards.active:before,
  .upskill .form-builder .form-cards:focus:before,
  .upskill .form-builder .form-cards:active:before {
    background: #6d49b1; }
  .upskill .back-btn:hover {
    background-color: #f4f1f9; }
  .upskill .folder-content,
  .upskill .custom-calander.react-datepicker,
  .upskill .host-key {
    background-color: #f9f9fc; }
  .upskill .custom-calander.react-datepicker .react-datepicker__day--selected {
    background: rgba(109, 73, 177, 0.23); }
  .upskill .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow:hover {
    border-top-color: #6d49b1; }
  .upskill .custom-calander.react-datepicker .react-datepicker__day:hover {
    background: rgba(109, 73, 177, 0.09); }
  .upskill .custom-calander.react-datepicker .react-datepicker__navigation--previous {
    border-right-color: #6d49b1; }
    .upskill .custom-calander.react-datepicker .react-datepicker__navigation--previous:hover {
      border-right-color: #56437a; }
  .upskill .custom-calander.react-datepicker .react-datepicker__navigation--next {
    border-left-color: #6d49b1; }
    .upskill .custom-calander.react-datepicker .react-datepicker__navigation--next:hover {
      border-left-color: #56437a; }
  .upskill .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__year-read-view--down-arrow {
    border-top-color: #6d49b1; }
    .upskill .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__year-read-view--down-arrow:hover {
      border-top-color: #56437a; }
  .upskill .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__month-read-view--down-arrow {
    border-top-color: #6d49b1; }
    .upskill .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__month-read-view--down-arrow:hover {
      border-top-color: #56437a; }
  .upskill .folder__list .module--item.active {
    color: #6d49b1; }
    .upskill .folder__list .module--item.active .icon-folder {
      color: #6d49b1; }
  .upskill .folder__list .unit--item.active {
    color: #6d49b1; }
    .upskill .folder__list .unit--item.active .icon-folder {
      color: #6d49b1; }
  .upskill .folder__list .chapter--item.active {
    color: #6d49b1; }
    .upskill .folder__list .chapter--item.active .icon-file {
      color: #6d49b1; }
  .upskill .folder__list .add-action__green {
    color: #6d49b1; }
    .upskill .folder__list .add-action__green .icon-plus {
      background: #6d49b1; }
  .upskill .folder__list .add-action__blue {
    color: #14bdeb; }
    .upskill .folder__list .add-action__blue .icon-plus {
      background: #14bdeb; }
  .upskill .submission-left .student-list--item__active .student--info-name dt,
  .upskill .text-success {
    color: #6d49b1 !important; }
  .upskill .option--item:hover {
    background: #f4f1f9;
    border: 1px solid #6d49b1;
    color: #6d49b1;
    box-shadow: 0px 3px 6px 0 rgba(109, 73, 177, 0.15); }
  .upskill .discussion-group .group-item > span.img {
    background-color: #6d49b1; }
  .upskill .discussion-group .group-item > span.text {
    color: #6d49b1; }
  .upskill input:checked + .slider {
    background-color: #56437a; }
  .upskill .actions .list a:hover {
    background: rgba(109, 73, 177, 0.1);
    color: #6d49b1; }
  .upskill .driver--title {
    background-color: rgba(109, 73, 177, 0.09); }
  .upskill .settings .menu--list .menu--item:hover {
    background-color: rgba(109, 73, 177, 0.09); }
    .upskill .settings .menu--list .menu--item:hover span {
      color: #6d49b1; }
  .upskill .settings .menu--list .menu--item.active {
    background-color: rgba(109, 73, 177, 0.09); }
    .upskill .settings .menu--list .menu--item.active span {
      color: #6d49b1; }
  .upskill .form-builder .form-cards--footer ul li > *:hover {
    background: rgba(109, 73, 177, 0.1); }
  .upskill aside .side__menu .menu--list .menu--item {
    color: #6d49b1; }
    .upskill aside .side__menu .menu--list .menu--item .icon {
      color: #6d49b1;
      transition: background-color 0.5s ease; }
      .upskill aside .side__menu .menu--list .menu--item .icon:hover {
        background-color: rgba(109, 73, 177, 0.247); }
    .upskill aside .side__menu .menu--list .menu--item.active {
      color: #ffffff; }
      .upskill aside .side__menu .menu--list .menu--item.active .icon {
        background: #6d49b1;
        color: #ffffff;
        transition: background-color 0.5s ease; }
  .upskill .module-container .module-card--title {
    color: #6d49b1; }
  .upskill .module-container .module-card:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.14);
    background-color: rgba(109, 73, 177, 0.09); }
  .upskill .submission-body .submission-action--item__active,
  .upskill .submission-body .submission-action--item:hover {
    background-color: rgba(109, 73, 177, 0.2);
    background: rgba(109, 73, 177, 0.2);
    color: #6d49b1; }
    .upskill .submission-body .submission-action--item__active .icon-download,
    .upskill .submission-body .submission-action--item__active .icon-preview,
    .upskill .submission-body .submission-action--item:hover .icon-download,
    .upskill .submission-body .submission-action--item:hover .icon-preview {
      color: #6d49b1; }
  .upskill .feedback-trigger,
  .upskill .feedback-trigger__comments {
    background-color: rgba(109, 73, 177, 0.09); }
    .upskill .feedback-trigger:hover,
    .upskill .feedback-trigger__comments:hover {
      background-color: rgba(109, 73, 177, 0.2);
      color: #6d49b1; }
    .upskill .feedback-trigger .icon-feedback,
    .upskill .feedback-trigger__comments .icon-feedback {
      color: #6d49b1 !important; }
  .upskill .react-bs-table-sizePerPage-dropdown .btn-secondary,
  .upskill .react-bs-table-sizePerPage-dropdown .dropdown-toggle {
    background-color: #f4f1f9;
    border-color: #f4f1f9; }
    .upskill .react-bs-table-sizePerPage-dropdown .btn-secondary:active,
    .upskill .react-bs-table-sizePerPage-dropdown .dropdown-toggle:active {
      background-color: #6d49b1 !important;
      border-color: #56437a !important;
      color: #f4f1f9; }
  .upskill .submission-left .student--files-item__active {
    color: #6d49b1;
    background: #ffffff; }
    .upskill .submission-left .student--files-item__active .icon-download {
      color: #6d49b1; }
  .upskill .content-chapter .chapter--video__list--active {
    background-color: rgba(109, 73, 177, 0.1); }
  .upskill .listing--left .link {
    color: #6d49b1; }
  .upskill .form-builder--tools-list .dropdown-item:hover,
  .upskill .form-builder--tools-list .form-builder--tools > button:hover,
  .upskill .form-builder--tools__active > button {
    background: rgba(109, 73, 177, 0.1);
    color: #6d49b1; }
  .upskill .form-builder textarea.form-builder--textarea:focus {
    background: rgba(109, 73, 177, 0.09);
    border-bottom-color: #6d49b1; }
  .upskill .discussion-group .card-body {
    color: #6d49b1; }
  .upskill .profile-content--head {
    background: #56437a; }
  .upskill .profile-content--img-edit:hover {
    background: #6d49b1;
    color: #ffffff; }
  .upskill .form-builder--tools__active a {
    background: rgba(109, 73, 177, 0.2); }
  .upskill .submission-header--date {
    color: #6d49b1; }
  .upskill .badges-publish,
  .upskill .badges-green-fill,
  .upskill .badges-due,
  .upskill .badges-outline-secondary {
    color: #ffffff;
    background-color: #6d49b1; }
  .upskill .badges.active {
    color: #ffffff;
    background-color: #6d49b1; }
  .upskill .badges-success {
    color: #6d49b1;
    background: #f4f1f9; }
  .upskill .dropdown-item:active {
    color: #1f0b0b;
    text-decoration: none;
    background-color: rgba(109, 73, 177, 0.2); }
  .upskill .react-bs-table-sizePerPage-dropdown .btn-secondary:focus,
  .upskill .react-bs-table-sizePerPage-dropdown .dropdown-toggle:focus {
    background-color: #6d49b1;
    border-color: #6d49b1;
    color: #f4f1f9; }
  .upskill .react-bootstrap-table-pagination .dropdown-menu .dropdown-item a {
    color: #6d49b1; }
  .upskill .btn-green,
  .upskill .btn-success,
  .upskill .btn-primary {
    background-color: #6d49b1;
    color: #ffffff;
    border-color: #6d49b1;
    border-radius: 4px !important; }
    .upskill .btn-green:hover,
    .upskill .btn-success:hover,
    .upskill .btn-primary:hover {
      background: #56437a;
      border-radius: 4px !important; }
    .upskill .btn-green:active,
    .upskill .btn-success:active,
    .upskill .btn-primary:active {
      background: #56437a;
      background-color: #56437a !important;
      border-color: #56437a !important; }
  .upskill .btn-green-stroke,
  .upskill .btn-outline-success,
  .upskill .btn-outline-primary {
    background: #ffffff;
    border-color: #6d49b1;
    color: #6d49b1; }
    .upskill .btn-green-stroke:hover,
    .upskill .btn-outline-success:hover,
    .upskill .btn-outline-primary:hover {
      background: #6d49b1;
      color: #ffffff; }
    .upskill .btn-green-stroke:active,
    .upskill .btn-outline-success:active,
    .upskill .btn-outline-primary:active {
      background: #6d49b1;
      background-color: #6d49b1 !important;
      border-color: #6d49b1 !important;
      color: #ffffff; }
  .upskill .btn-outline-primary:not(:disabled):not(.disabled):active,
  .upskill .btn-outline-primary:not(:disabled):not(.disabled).active,
  .upskill .show > .btn-outline-primary.dropdown-toggle {
    background-color: #6d49b1;
    border-color: #6d49b1;
    color: #ffffff; }
  .upskill .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .upskill .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .upskill .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(109, 73, 177, 0.5); }
  .upskill .text-primary,
  .upskill .btn-add {
    color: #6d49b1 !important; }
    .upskill .text-primary:hover,
    .upskill .btn-add:hover {
      color: #56437a; }
  .upskill .btn-link {
    color: #6d49b1; }
    .upskill .btn-link:hover {
      color: #56437a; }
  .upskill .btn-like:hover {
    color: #6d49b1; }
  .upskill .page-item.active .page-link {
    color: #ffffff;
    background-color: #6d49b1;
    border-color: #6d49b1; }
  .upskill .page-item .page-link {
    color: #6d49b1; }
  .upskill .page-link:hover {
    color: #56437a;
    background-color: #f4f1f9; }
  .upskill .links {
    color: #6d49b1; }
    .upskill .links:hover {
      color: #6d49b1; }
  .upskill .links__red {
    color: #f71735; }
    .upskill .links__red:hover {
      color: #f71735; }
  .upskill .segment {
    animation: show 2s infinite;
    animation-fill-mode: forwards;
    opacity: 0; }
    .upskill .segment:nth-child(1) {
      animation-delay: 0.2s;
      fill: #2d1e48; }
    .upskill .segment:nth-child(2) {
      animation-delay: 0.4s;
      fill: #4f357e; }
    .upskill .segment:nth-child(3) {
      animation-delay: 0.6s;
      fill: #6d49b1; }
    .upskill .segment:nth-child(4) {
      animation-delay: 0.8s;
      fill: #977dc9; }
    .upskill .segment:nth-child(5) {
      animation-delay: 1s;
      fill: #c2b3df; }
    .upskill .segment:nth-child(6) {
      animation-delay: 1.2s;
      fill: #ede9f6; }
  .upskill .scrollbar, .upskill .h-80, .upskill .h-70, .upskill .h-60, .upskill .h-5, .upskill .scrollbar__onhover:hover, .upskill .assessment-vs--fix-height:hover, .upskill .exam--controls-right .question--card .question--body:hover, .exam--controls-right .question--card .upskill .question--body:hover, .upskill .chapter__list, .upskill .submission-left .student--files, .submission-left .upskill .student--files, .upskill .submission-body:hover,
  .upskill .submission-content__rr:hover, .upskill table.coded-grades, .upskill .discussion .post-container .status-post-sec, .discussion .post-container .upskill .status-post-sec, .upskill .discussion--container .status-post-sec, .discussion--container .upskill .status-post-sec, .upskill .tribute-container ul, .tribute-container .upskill ul, .upskill .sis .student-profile .profile-content__attendance-table .fix-height-table, .sis .student-profile .profile-content__attendance-table .upskill .fix-height-table, .upskill .side-notification .notification-list, .side-notification .upskill .notification-list, .upskill .Highlight__popup, .upskill .custom-calander.react-datepicker .react-datepicker__month-dropdown, .custom-calander.react-datepicker .upskill .react-datepicker__month-dropdown,
  .upskill .custom-calander.react-datepicker .react-datepicker__year-dropdown, .custom-calander.react-datepicker .upskill .react-datepicker__year-dropdown {
    --scrollbarBG: #cfd8dc;
    --thumbBG: $upskill-primary;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    overflow: auto !important; }
    .upskill .scrollbar::-webkit-scrollbar-track, .upskill .h-80::-webkit-scrollbar-track, .upskill .h-70::-webkit-scrollbar-track, .upskill .h-60::-webkit-scrollbar-track, .upskill .h-5::-webkit-scrollbar-track, .upskill .scrollbar__onhover:hover::-webkit-scrollbar-track, .upskill .assessment-vs--fix-height:hover::-webkit-scrollbar-track, .upskill .exam--controls-right .question--card .question--body:hover::-webkit-scrollbar-track, .exam--controls-right .question--card .upskill .question--body:hover::-webkit-scrollbar-track, .upskill .chapter__list::-webkit-scrollbar-track, .upskill .submission-left .student--files::-webkit-scrollbar-track, .submission-left .upskill .student--files::-webkit-scrollbar-track, .upskill .submission-body:hover::-webkit-scrollbar-track,
    .upskill .submission-content__rr:hover::-webkit-scrollbar-track, .upskill table.coded-grades::-webkit-scrollbar-track, .upskill .discussion .post-container .status-post-sec::-webkit-scrollbar-track, .discussion .post-container .upskill .status-post-sec::-webkit-scrollbar-track, .upskill .discussion--container .status-post-sec::-webkit-scrollbar-track, .discussion--container .upskill .status-post-sec::-webkit-scrollbar-track, .upskill .tribute-container ul::-webkit-scrollbar-track, .tribute-container .upskill ul::-webkit-scrollbar-track, .upskill .sis .student-profile .profile-content__attendance-table .fix-height-table::-webkit-scrollbar-track, .sis .student-profile .profile-content__attendance-table .upskill .fix-height-table::-webkit-scrollbar-track, .upskill .side-notification .notification-list::-webkit-scrollbar-track, .side-notification .upskill .notification-list::-webkit-scrollbar-track, .upskill .Highlight__popup::-webkit-scrollbar-track, .upskill .custom-calander.react-datepicker .react-datepicker__month-dropdown::-webkit-scrollbar-track, .custom-calander.react-datepicker .upskill .react-datepicker__month-dropdown::-webkit-scrollbar-track,
    .upskill .custom-calander.react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-track, .custom-calander.react-datepicker .upskill .react-datepicker__year-dropdown::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .upskill .scrollbar::-webkit-scrollbar, .upskill .h-80::-webkit-scrollbar, .upskill .h-70::-webkit-scrollbar, .upskill .h-60::-webkit-scrollbar, .upskill .h-5::-webkit-scrollbar, .upskill .scrollbar__onhover:hover::-webkit-scrollbar, .upskill .assessment-vs--fix-height:hover::-webkit-scrollbar, .upskill .exam--controls-right .question--card .question--body:hover::-webkit-scrollbar, .exam--controls-right .question--card .upskill .question--body:hover::-webkit-scrollbar, .upskill .chapter__list::-webkit-scrollbar, .upskill .submission-left .student--files::-webkit-scrollbar, .submission-left .upskill .student--files::-webkit-scrollbar, .upskill .submission-body:hover::-webkit-scrollbar,
    .upskill .submission-content__rr:hover::-webkit-scrollbar, .upskill table.coded-grades::-webkit-scrollbar, .upskill .discussion .post-container .status-post-sec::-webkit-scrollbar, .discussion .post-container .upskill .status-post-sec::-webkit-scrollbar, .upskill .discussion--container .status-post-sec::-webkit-scrollbar, .discussion--container .upskill .status-post-sec::-webkit-scrollbar, .upskill .tribute-container ul::-webkit-scrollbar, .tribute-container .upskill ul::-webkit-scrollbar, .upskill .sis .student-profile .profile-content__attendance-table .fix-height-table::-webkit-scrollbar, .sis .student-profile .profile-content__attendance-table .upskill .fix-height-table::-webkit-scrollbar, .upskill .side-notification .notification-list::-webkit-scrollbar, .side-notification .upskill .notification-list::-webkit-scrollbar, .upskill .Highlight__popup::-webkit-scrollbar, .upskill .custom-calander.react-datepicker .react-datepicker__month-dropdown::-webkit-scrollbar, .custom-calander.react-datepicker .upskill .react-datepicker__month-dropdown::-webkit-scrollbar,
    .upskill .custom-calander.react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar, .custom-calander.react-datepicker .upskill .react-datepicker__year-dropdown::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #f5f5f5; }
    .upskill .scrollbar::-webkit-scrollbar-thumb, .upskill .h-80::-webkit-scrollbar-thumb, .upskill .h-70::-webkit-scrollbar-thumb, .upskill .h-60::-webkit-scrollbar-thumb, .upskill .h-5::-webkit-scrollbar-thumb, .upskill .scrollbar__onhover:hover::-webkit-scrollbar-thumb, .upskill .assessment-vs--fix-height:hover::-webkit-scrollbar-thumb, .upskill .exam--controls-right .question--card .question--body:hover::-webkit-scrollbar-thumb, .exam--controls-right .question--card .upskill .question--body:hover::-webkit-scrollbar-thumb, .upskill .chapter__list::-webkit-scrollbar-thumb, .upskill .submission-left .student--files::-webkit-scrollbar-thumb, .submission-left .upskill .student--files::-webkit-scrollbar-thumb, .upskill .submission-body:hover::-webkit-scrollbar-thumb,
    .upskill .submission-content__rr:hover::-webkit-scrollbar-thumb, .upskill table.coded-grades::-webkit-scrollbar-thumb, .upskill .discussion .post-container .status-post-sec::-webkit-scrollbar-thumb, .discussion .post-container .upskill .status-post-sec::-webkit-scrollbar-thumb, .upskill .discussion--container .status-post-sec::-webkit-scrollbar-thumb, .discussion--container .upskill .status-post-sec::-webkit-scrollbar-thumb, .upskill .tribute-container ul::-webkit-scrollbar-thumb, .tribute-container .upskill ul::-webkit-scrollbar-thumb, .upskill .sis .student-profile .profile-content__attendance-table .fix-height-table::-webkit-scrollbar-thumb, .sis .student-profile .profile-content__attendance-table .upskill .fix-height-table::-webkit-scrollbar-thumb, .upskill .side-notification .notification-list::-webkit-scrollbar-thumb, .side-notification .upskill .notification-list::-webkit-scrollbar-thumb, .upskill .Highlight__popup::-webkit-scrollbar-thumb, .upskill .custom-calander.react-datepicker .react-datepicker__month-dropdown::-webkit-scrollbar-thumb, .custom-calander.react-datepicker .upskill .react-datepicker__month-dropdown::-webkit-scrollbar-thumb,
    .upskill .custom-calander.react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-thumb, .custom-calander.react-datepicker .upskill .react-datepicker__year-dropdown::-webkit-scrollbar-thumb {
      background-color: #6d49b1;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent)); }

.upskill-modal .modal-header {
  background-color: rgba(109, 73, 177, 0.2) !important; }

.upskill-modal .btn-green,
.upskill-modal .btn-success,
.upskill-modal .btn-primary {
  background-color: #6d49b1;
  color: #ffffff;
  border-color: #6d49b1;
  border-radius: 4px !important; }
  .upskill-modal .btn-green:hover,
  .upskill-modal .btn-success:hover,
  .upskill-modal .btn-primary:hover {
    background: #56437a;
    border-radius: 4px !important; }

.upskill-modal .btn-green-stroke,
.upskill-modal .btn-outline-success,
.upskill-modal .btn-outline-primary {
  background: #ffffff;
  border-color: #6d49b1;
  color: #6d49b1; }
  .upskill-modal .btn-green-stroke:hover,
  .upskill-modal .btn-outline-success:hover,
  .upskill-modal .btn-outline-primary:hover {
    background: #6d49b1;
    color: #ffffff; }

.upskill-modal .text-success {
  color: #6d49b1 !important; }

.upskill-modal .option--item:hover {
  background: #f4f1f9;
  border: 1px solid #6d49b1;
  color: #6d49b1;
  box-shadow: 0px 3px 6px 0 rgba(109, 73, 177, 0.15); }

.upskill-modal .upload-sec-list li.add-more-img .upload-file:hover {
  color: #6d49b1; }

.upskill-modal .upload-file:hover {
  color: #6d49b1; }

.upskill-modal .form-control:focus {
  border-color: #6d49b1;
  box-shadow: 0 0 0 0.2rem rgba(109, 73, 177, 0.25); }

.skeleton-view:empty {
  border: 1px solid #dee2e6;
  margin: auto auto 20px;
  width: 100%;
  height: 150px;
  /* change height to see repeat-y behavior */
  border-radius: 2px;
  background-image: radial-gradient(circle 20px at 38px 35px, #eee 99%, transparent 0), linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), linear-gradient(#eee 20px, transparent 0), linear-gradient(#eee 20px, transparent 0), linear-gradient(#eee 20px, transparent 0), linear-gradient(#eee 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 100px 200px, 50px 200px, 150px 14px, 350px 12px, 300px 12px, 250px 12px;
  background-position: 0 0, 0 0, 70px 15px, 70px 40px, 20px 65px, 20px 90px;
  animation: shine 1s infinite; }

@keyframes shine {
  to {
    background-position: 0 0, 100% 0, 70px 15px, 70px 40px, 20px 65px, 20px 90px; } }

.milestoneView:empty {
  display: inline-block;
  margin: auto 10px;
  width: 300px;
  height: 450px;
  /* change height to see repeat-y behavior */
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), linear-gradient(#eee 100%, transparent 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  animation: milestoneView 1s infinite; }

@keyframes milestoneView {
  to {
    background-position: 100% 400px;
    /* move highlight to right */ } }

/* Layout */
.error {
  color: #dc3545; }

.invalid .form-control {
  border-color: red; }

aside {
  width: 100px;
  min-width: 100px;
  background: #0d363d;
  height: 100vh;
  position: fixed; }
  aside .side__menu {
    padding: 0;
    height: 100%;
    overflow-y: auto;
    color: #ffffff;
    text-align: center; }
    aside .side__menu .menu--list {
      margin-top: 1rem;
      color: #79a797; }
      aside .side__menu .menu--list a {
        color: #79a797; }
      aside .side__menu .menu--list .menu--item {
        cursor: pointer;
        margin-bottom: 1rem; }
        aside .side__menu .menu--list .menu--item .icon {
          width: 52px;
          padding: 0.3rem 0.5rem;
          margin: auto;
          margin-bottom: 0.3rem;
          border-radius: 0.5rem;
          color: #79a797; }
          aside .side__menu .menu--list .menu--item .icon > span {
            font-size: 35px; }
          aside .side__menu .menu--list .menu--item .icon:hover {
            background-color: rgba(67, 156, 123, 0.247);
            transition: 0.5 linear; }
        aside .side__menu .menu--list .menu--item.active .icon {
          background: #439c7c;
          color: #ffffff; }
  aside .main-menu {
    padding: 4rem 1rem 0.5rem 0.5rem;
    height: 100%;
    overflow-y: auto;
    color: #79a797;
    text-align: center; }
    aside .main-menu .menu {
      margin-top: 1rem;
      list-style: none; }
      aside .main-menu .menu .menu--list {
        color: #79a797;
        text-transform: capitalize; }
      aside .main-menu .menu .menu--item {
        cursor: pointer;
        margin-bottom: 1rem; }
        aside .main-menu .menu .menu--item .icon {
          display: block;
          padding: 0.5rem 0.5rem;
          border-radius: 10px;
          margin-bottom: 5px;
          color: #79a797;
          font-size: 2.5em; }
          aside .main-menu .menu .menu--item .icon:hover {
            background-color: rgba(67, 156, 123, 0.247);
            transition: 0.5 linear; }
        aside .main-menu .menu .menu--item.active .icon {
          background: #439c7c;
          color: #ffffff;
          transition: background-color 0.5s ease; }

.scrollbar, .h-80, .h-70, .h-60, .h-5, .scrollbar__onhover:hover, .assessment-vs--fix-height:hover, .exam--controls-right .question--card .question--body:hover, .chapter__list, .submission-left .student--files, .submission-body:hover,
.submission-content__rr:hover, table.coded-grades, .discussion .post-container .status-post-sec, .discussion--container .status-post-sec, .tribute-container ul, .sis .student-profile .profile-content__attendance-table .fix-height-table, .side-notification .notification-list, .Highlight__popup, .custom-calander.react-datepicker .react-datepicker__month-dropdown,
.custom-calander.react-datepicker .react-datepicker__year-dropdown {
  --scrollbarBG: #cfd8dc;
  --thumbBG: $green;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  overflow: auto !important; }
  .scrollbar::-webkit-scrollbar-track, .h-80::-webkit-scrollbar-track, .h-70::-webkit-scrollbar-track, .h-60::-webkit-scrollbar-track, .h-5::-webkit-scrollbar-track, .scrollbar__onhover:hover::-webkit-scrollbar-track, .assessment-vs--fix-height:hover::-webkit-scrollbar-track, .exam--controls-right .question--card .question--body:hover::-webkit-scrollbar-track, .chapter__list::-webkit-scrollbar-track, .submission-left .student--files::-webkit-scrollbar-track, .submission-body:hover::-webkit-scrollbar-track,
  .submission-content__rr:hover::-webkit-scrollbar-track, table.coded-grades::-webkit-scrollbar-track, .discussion .post-container .status-post-sec::-webkit-scrollbar-track, .discussion--container .status-post-sec::-webkit-scrollbar-track, .tribute-container ul::-webkit-scrollbar-track, .sis .student-profile .profile-content__attendance-table .fix-height-table::-webkit-scrollbar-track, .side-notification .notification-list::-webkit-scrollbar-track, .Highlight__popup::-webkit-scrollbar-track, .custom-calander.react-datepicker .react-datepicker__month-dropdown::-webkit-scrollbar-track,
  .custom-calander.react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .scrollbar::-webkit-scrollbar, .h-80::-webkit-scrollbar, .h-70::-webkit-scrollbar, .h-60::-webkit-scrollbar, .h-5::-webkit-scrollbar, .scrollbar__onhover:hover::-webkit-scrollbar, .assessment-vs--fix-height:hover::-webkit-scrollbar, .exam--controls-right .question--card .question--body:hover::-webkit-scrollbar, .chapter__list::-webkit-scrollbar, .submission-left .student--files::-webkit-scrollbar, .submission-body:hover::-webkit-scrollbar,
  .submission-content__rr:hover::-webkit-scrollbar, table.coded-grades::-webkit-scrollbar, .discussion .post-container .status-post-sec::-webkit-scrollbar, .discussion--container .status-post-sec::-webkit-scrollbar, .tribute-container ul::-webkit-scrollbar, .sis .student-profile .profile-content__attendance-table .fix-height-table::-webkit-scrollbar, .side-notification .notification-list::-webkit-scrollbar, .Highlight__popup::-webkit-scrollbar, .custom-calander.react-datepicker .react-datepicker__month-dropdown::-webkit-scrollbar,
  .custom-calander.react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5; }
  .scrollbar::-webkit-scrollbar-thumb, .h-80::-webkit-scrollbar-thumb, .h-70::-webkit-scrollbar-thumb, .h-60::-webkit-scrollbar-thumb, .h-5::-webkit-scrollbar-thumb, .scrollbar__onhover:hover::-webkit-scrollbar-thumb, .assessment-vs--fix-height:hover::-webkit-scrollbar-thumb, .exam--controls-right .question--card .question--body:hover::-webkit-scrollbar-thumb, .chapter__list::-webkit-scrollbar-thumb, .submission-left .student--files::-webkit-scrollbar-thumb, .submission-body:hover::-webkit-scrollbar-thumb,
  .submission-content__rr:hover::-webkit-scrollbar-thumb, table.coded-grades::-webkit-scrollbar-thumb, .discussion .post-container .status-post-sec::-webkit-scrollbar-thumb, .discussion--container .status-post-sec::-webkit-scrollbar-thumb, .tribute-container ul::-webkit-scrollbar-thumb, .sis .student-profile .profile-content__attendance-table .fix-height-table::-webkit-scrollbar-thumb, .side-notification .notification-list::-webkit-scrollbar-thumb, .Highlight__popup::-webkit-scrollbar-thumb, .custom-calander.react-datepicker .react-datepicker__month-dropdown::-webkit-scrollbar-thumb,
  .custom-calander.react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-thumb {
    background-color: #439c7c;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent)); }

.h-80 {
  height: calc(100vh - 140px); }

.h-70 {
  height: calc(100vh - 147px); }

.h-60 {
  height: calc(100vh - 187px); }

.h-5 {
  height: calc(100vh - 230px); }

.h-n130 {
  height: calc(100vh - 153px); }

.scrollbar__onhover::-webkit-scrollbar, .assessment-vs--fix-height::-webkit-scrollbar, .exam--controls-right .question--card .question--body::-webkit-scrollbar {
  background-color: transparent;
  width: 5px; }

.scrollbar__onhover::-webkit-scrollbar-thumb, .assessment-vs--fix-height::-webkit-scrollbar-thumb, .exam--controls-right .question--card .question--body::-webkit-scrollbar-thumb {
  background-color: transparent; }

.scrollbar__onhover:hover, .assessment-vs--fix-height:hover, .exam--controls-right .question--card .question--body:hover {
  scrollbar-width: auto;
  overflow-x: hidden !important; }

/* Pages */
body {
  overflow: auto !important; }
  body > #zmmtg-root {
    display: none; }

main.sidebar-show {
  margin-left: 100px;
  display: block; }

.main--title {
  font-size: 20px;
  color: #1f0b0b;
  line-height: 27px; }

.block-title, .resources--title, .question--text {
  font-size: 1.12525rem;
  text-transform: uppercase;
  color: #1f0b0b;
  line-height: 26px; }

.title-border {
  font-size: 1.12525rem;
  text-transform: uppercase;
  color: #1f0b0b;
  line-height: 1.3;
  position: relative;
  font-weight: 600; }
  .title-border .text {
    display: inline-block;
    background: #ffffff;
    z-index: 1;
    position: relative;
    padding-right: 0.5rem; }
  .title-border .border {
    position: absolute;
    border-bottom: 1px solid #dee2e6;
    top: 16px;
    left: 0;
    right: 0; }

.search.input-group {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  background: #ffffff;
  overflow: hidden;
  width: 245px;
  max-width: 100%; }
  .search.input-group .icon-search {
    color: #c7c7c7;
    font-size: 20px; }

.multiple-video-main {
  display: flex;
  flex-wrap: wrap; }
  .multiple-video-main .video-container {
    width: 144px;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px; }
    .multiple-video-main .video-container p {
      padding: 6px; }
  .multiple-video-main .video-overlay {
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px; }
    .multiple-video-main .video-overlay p {
      color: #fff5f5;
      font-weight: 900;
      font-size: 16px;
      /* top: 12px; */ }

.feedback-modal .modal-header {
  display: block;
  padding: 20px 35px; }
  .feedback-modal .modal-header .modal-title {
    text-align: left !important;
    font-weight: 600;
    margin-bottom: 5px; }
  .feedback-modal .modal-header .subtitle {
    font-size: 0.74987rem;
    color: #6c757d; }

.feedback-modal .modal-body {
  padding: 20px 35px; }
  .feedback-modal .modal-body .info-block {
    padding: 15px;
    color: #33a5f7;
    border: 1px solid currentColor;
    margin-bottom: 20px;
    border-radius: 4px;
    border-left: 5px solid currentColor; }

.onsend-msg .modal-header {
  display: block; }
  .onsend-msg .modal-header .modal-title {
    color: #439c7c;
    font-weight: 600; }

.onsend-msg .modal-body {
  padding: 20px 60px; }
  .onsend-msg .modal-body .reachout-msg {
    color: #6c757d;
    text-align: center;
    margin-top: 20px;
    font-size: 12px; }
    .onsend-msg .modal-body .reachout-msg p {
      margin-bottom: 3px; }
    .onsend-msg .modal-body .reachout-msg img {
      height: 13px; }

.filter--title {
  font-size: 1.12525rem;
  text-transform: uppercase;
  color: #1f0b0b;
  line-height: 26px;
  padding-right: 17px;
  position: relative;
  display: block; }
  .filter--title [class^='icon-'],
  .filter--title [class*=' icon-'] {
    font-size: 14px;
    margin-left: 8px;
    position: absolute;
    top: 7px;
    right: 0;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    display: none; }

.filter--list {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding-top: 0.75rem; }

.app__container {
  background: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  min-height: calc(100vh - 110px);
  margin-top: 1.25rem; }

.app__wrapper {
  background: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 1.25rem; }

.app--item {
  color: #0d363d;
  border: 1px solid #f7f3ec;
  border-radius: 10px;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  display: inline-block;
  cursor: pointer;
  height: 95px;
  text-align: center;
  padding: 0.5rem 0; }
  .app--item--content {
    padding: 0 0.3125rem;
    position: relative;
    display: block;
    color: #0d363d; }
  .app--item:hover {
    border-color: #439c7c !important; }
    .app--item:hover .app--item--content {
      color: #439c7c; }
  .app--item:disabled, .app--item.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6; }

.blocks {
  background: #ffffff;
  overflow: hidden;
  border-radius: 10px !important; }

.rounded {
  border-radius: 10px !important; }

.app--link {
  cursor: pointer;
  padding: 0.8rem 1rem;
  margin: 0.3rem 0;
  display: block;
  color: #1f0b0b;
  border-radius: 0.4rem;
  z-index: 9;
  background: #ffffff;
  border: 1px solid #f7f3ec; }
  .app--link .text {
    font-size: 1.00012rem;
    padding-left: 0.5rem;
    width: calc(100% - 28px);
    overflow: hidden;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
  .app--link.active {
    color: #439c7c;
    border-right: 0.4rem solid currentColor !important;
    border-color: #439c7c;
    font-weight: 600; }
  .app--link .button {
    display: none;
    width: 210px; }
    .app--link .button .btn-secondary {
      border-radius: 0.8rem;
      padding: 0.091rem 0.5rem;
      display: inline-block; }
  .app--link:hover {
    border-color: #439c7c !important; }
    .app--link:hover .button {
      display: block; }
  .toggle__menu .app--link .text {
    width: 0;
    padding-left: 0;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }

.module--info a {
  color: #999999;
  margin-bottom: 0.5rem; }
  .module--info a > span {
    display: block;
    width: 80px; }
  .module--info a.active {
    color: #439c7c; }

.back-btn {
  position: absolute;
  right: -10px;
  top: 45px;
  border-radius: 50%;
  padding: 3px 5px;
  background: #ffffff;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: 1px solid #e5f8f4;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .back-btn:hover {
    background-color: #e5f8f4; }

.inner__menu {
  min-width: 260px;
  width: 260px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .inner__menu.toggle__menu {
    min-width: 100px;
    width: 100px; }

.chapter__list {
  height: calc(100vh - 280px); }

.chapter--item {
  display: flex;
  flex-direction: column-reverse; }
  .chapter--item .link {
    border: none !important; }

.chapter--items {
  display: flex;
  flex-direction: column; }

.drag--drop {
  border: 1px dashed #1f0b0b;
  border-radius: 10px;
  padding: 2rem 0.5rem;
  text-align: center;
  margin: 1rem 0 0;
  position: relative; }
  .drag--drop .drag-icons {
    flex-direction: column; }
  .drag--drop label {
    cursor: pointer;
    color: #439c7c; }
  .drag--drop input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    opacity: 0.01;
    cursor: pointer; }

.module-container {
  padding: 20px; }
  .module-container .module-card {
    cursor: pointer;
    border-radius: 4px;
    margin: 0px 15px 20px 0px;
    padding: 26px;
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11); }
    .module-container .module-card:hover {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.144);
      background-color: #f3f7fa; }
    .module-container .module-card--title {
      color: #439c7c;
      font-weight: 600; }
    .module-container .module-card--top {
      display: flex;
      justify-content: space-between;
      font-weight: 600; }
    .module-container .module-card--name {
      font-size: 18px;
      font-weight: 600;
      text-overflow: ellipsis;
      margin-bottom: 10px; }
    .module-container .module-card--units {
      font-size: 14px;
      font-weight: 600; }
      .module-container .module-card--units--number {
        display: block; }
    .module-container .module-card--chapters {
      font-size: 12px;
      font-weight: 600; }
      .module-container .module-card--chapters--number {
        display: block; }
    .module-container .module-card--description {
      font-size: 14px;
      color: #707070; }
      .module-container .module-card--description__link {
        color: #0facf3; }
        .module-container .module-card--description__link:hover {
          text-decoration: underline; }

.resources--video {
  min-height: 50vh;
  height: calc(100vh - 280px);
  max-height: 100%; }

.resources--title {
  padding-bottom: 15px;
  border-bottom: 1px solid #dee2e6; }

.course-module {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2.5rem; }
  .course-module .module-title {
    font-size: 1.00012rem;
    font-weight: 600;
    padding: 0.75rem 1.5rem; }
  .course-module .module-unit {
    padding: 0.75rem 1.5rem; }
  .course-module .module-item {
    border-bottom: 1px solid #dee2e6;
    display: flex;
    flex-direction: column-reverse;
    padding: 0.75rem 0; }
    .course-module .module-item:last-child {
      border: 0; }
  .course-module .module ul {
    margin-bottom: 0; }
  .course-module .unit-title {
    font-size: 1.00012rem;
    font-weight: 400;
    padding: 0.75rem 0;
    cursor: pointer; }
    .course-module .unit-title.link {
      border: 0; }
    .course-module .unit-title > span {
      display: inline-block;
      vertical-align: middle; }
      .course-module .unit-title > span:nth-child(2) {
        width: calc(100% - 40px); }

.online-course {
  border-left: 4px solid #439c7c !important;
  font-weight: 600; }
  .online-course .card-footer {
    background: #ffffff;
    border: 0;
    padding-top: 0; }
  .online-course .unit-list {
    font-weight: 400; }

.display-table .react-bootstrap-table .table-responsive {
  display: table !important; }

.sticky-filter {
  position: sticky;
  left: -8px; }

.grades--accordion {
  display: flex;
  flex-direction: column;
  border-radius: 10px; }
  .grades--accordion__body {
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1); }
    .grades--accordion__body .content-title {
      padding: 0.3125rem 1rem;
      background: #ffffff;
      display: flex;
      height: 48px;
      font-size: 16px;
      border-radius: 6px;
      font-weight: 600;
      color: #595959;
      align-items: center;
      cursor: pointer; }
      .grades--accordion__body .content-title .icon-angle {
        margin-left: auto;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear; }
    .grades--accordion__body .grade-collapse {
      padding: 20px 30px; }
      .grades--accordion__body .grade-collapse .table thead th {
        border-bottom: 1px solid #dee2e6; }
    .grades--accordion__body .collapse.show ~ .content-title .icon-angle,
    .grades--accordion__body .collapse.show + .content-title .icon-angle {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg); }

.sticky-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 0 0.3rem 0.3rem; }

.quiz-title {
  font-size: 1.12525rem;
  color: #1f0b0b;
  line-height: 26px; }

.pb-8 {
  padding-bottom: 7rem !important; }

.accordion .card {
  background-color: white;
  box-shadow: 0px 3px 6px #ebebeb;
  border: none;
  margin-bottom: 15px; }
  .accordion .card:hover {
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.2); }

.question--card {
  padding: 24px;
  margin-bottom: 24px;
  border: 1px solid transparent;
  border-radius: 8px; }
  .question--card ol {
    margin-left: 24px; }
  .question--card__correct {
    background: #f4ffff;
    border-color: #01cfc9; }
  .question--card__incorrect {
    background: #fff5f4;
    border-color: #ff7775; }
  .question--card__default {
    background: #ffffff;
    border-color: #dbdbdb !important; }
  .question--card .answer--text {
    padding: 1rem;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
    background: #ffffff;
    font-size: 1.00012rem;
    border: 1px solid #dbdbdb; }
    .question--card .answer--text:focus {
      border: 1px solid #8fcfb8;
      box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
    .question--card .answer--text__long {
      min-height: 350px; }
    .question--card .answer--text__short {
      min-height: 150px;
      max-height: 300px;
      overflow-y: auto; }

.question--text {
  margin-bottom: 24px;
  text-transform: initial; }

.question--option {
  list-style: none; }
  .question--option__text {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 600;
    margin-top: 8px;
    margin-right: 20px; }
  .question--option__correct {
    color: #439c7c;
    background-color: #e5f8f4 !important;
    border: 1px solid #439c7c; }
  .question--option__incorrect {
    background-color: rgba(255, 119, 117, 0.1) !important;
    border: 1px solid #ff7775; }

.question__with-img {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem; }

.question--optionitem {
  margin-bottom: 16px;
  padding: 8px 8px 8px 48px;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 1.3125rem;
  background: #ffffff;
  font-size: 1.00012rem;
  width: 96%; }
  .question--optionitem__open-ended {
    padding: 1.5rem; }
    .question--optionitem__open-ended textarea {
      width: 100%;
      min-height: 60px;
      padding: 0.5rem;
      overflow-y: auto; }

.question--optionicon {
  position: absolute;
  right: 0;
  padding-top: 10px; }

.answer-stats p {
  float: left;
  margin-right: 16px;
  font-weight: 600; }
  .answer-stats p .counts {
    margin: 0 3px; }

.quiz-complete {
  background: #ffffff; }
  .quiz-complete--top {
    padding: 24px 0; }
    .quiz-complete--top .img-place {
      width: 48px;
      height: 48px;
      display: block;
      text-align: center; }
      .quiz-complete--top .img-place img {
        max-width: 100%; }
    .quiz-complete--top .btn-hold {
      display: flex;
      flex-direction: column; }
    .quiz-complete--top .message {
      font-size: 1.37462rem;
      margin-bottom: 16px;
      font-weight: 600; }
  .quiz-complete__success {
    background: rgba(229, 248, 244, 0.8); }
    .quiz-complete__success .message {
      color: #439c7c; }
  .quiz-complete__fails {
    background: rgba(255, 84, 81, 0.04); }
    .quiz-complete__fails .message {
      color: #ff5451; }
  .quiz-complete--details {
    display: grid;
    grid-template-columns: 48px calc(100% - 48px - 175px - 48px) 175px;
    grid-column-gap: 24px; }
  .quiz-complete--body {
    padding: 24px 0; }
    .quiz-complete--body .resource {
      background: #ffffff;
      border-radius: 8px;
      padding: 24px; }
      .quiz-complete--body .resource .block-title, .quiz-complete--body .resource .resources--title, .quiz-complete--body .resource .question--text {
        position: relative;
        margin-bottom: 0.8rem;
        text-transform: initial; }
        .quiz-complete--body .resource .block-title:after, .quiz-complete--body .resource .resources--title:after, .quiz-complete--body .resource .question--text:after {
          right: 0;
          top: 50%;
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          background: rgba(219, 219, 219, 0.5); }
        .quiz-complete--body .resource .block-title--text {
          font-weight: 600;
          display: inline-block;
          padding-right: 1rem;
          background: #ffffff;
          position: relative;
          z-index: 2;
          line-height: 24px; }
      .quiz-complete--body .resource--list {
        list-style: none; }
      .quiz-complete--body .resource--item {
        margin-bottom: 12px;
        display: flex;
        align-items: center; }
        .quiz-complete--body .resource--item a {
          color: #595959;
          text-decoration: underline; }
        .quiz-complete--body .resource--item [class^='icon-'],
        .quiz-complete--body .resource--item [class*=' icon-'] {
          margin-right: 8px; }

.quiz-content {
  border-radius: 10px 10px 0px 0px; }

.quiz--total-ques {
  font-size: 0.875rem;
  display: inline-block; }
  .quiz--total-ques em {
    font-weight: normal;
    font-style: normal; }

.quiz--answered {
  width: 150px; }

.blue-header {
  background-color: #0facf3;
  padding: 0rem 0.625rem;
  color: #ffffff;
  height: 40px;
  line-height: 40px; }
  .blue-header--narator {
    margin-left: 0.9375rem; }
  .blue-header--right {
    background: rgba(255, 255, 255, 0.1);
    width: auto;
    float: right;
    margin-right: -10px; }
  .blue-header .dwn-all {
    color: #ffffff;
    font-size: 0.875rem;
    display: flex;
    justify-content: initial;
    align-items: center;
    padding: 0 20px;
    transition: all 0.3s ease; }
    @media (prefers-reduced-motion: reduce) {
      .blue-header .dwn-all {
        transition: none; } }
    .blue-header .dwn-all [class^='icon-'],
    .blue-header .dwn-all [class*=' icon-'] {
      font-size: 1.00012rem;
      margin-right: 0.5rem; }
    .blue-header .dwn-all:hover {
      background: #3fbdf6; }

.enlarge--image {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 78vh;
  padding: 10px; }
  .enlarge--image img {
    max-width: 100%;
    max-height: 100%; }

.enlarge--body {
  background-color: #f5f5f5;
  padding: 1.5rem 1rem;
  border-radius: 0rem 0rem 1rem 1rem; }
  .enlarge--body__angle {
    display: block;
    color: #439c7c;
    font-weight: 600; }

.image-modal .modal-header {
  background: #ffffff;
  border: 0;
  padding: 0;
  display: block;
  position: relative; }
  .image-modal .modal-header .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 10px 15px 0px 0px;
    font-size: 30px;
    background: grey;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    padding: 0px;
    color: white;
    font-weight: 300; }

.image-modal .modal-content {
  border-radius: 1rem; }

.image-modal .modal-body {
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.exam .main-content--header {
  padding: 1.25rem 1.5rem 0 1.5rem; }

.exam--total-ques,
.exam .date-time {
  color: #595959;
  font-size: 0.875rem;
  display: inline-block; }
  .exam--total-ques em,
  .exam .date-time em {
    font-weight: normal;
    font-style: normal; }
  .exam--total-ques b,
  .exam .date-time b {
    font-weight: 600; }

.exam .indicator__answered {
  background: #439c7c;
  color: #ffffff; }

.exam .indicator__marked {
  background: #fcb83f;
  color: #ffffff; }

.exam .indicator__skipped {
  background: #8b9393;
  color: #ffffff; }

.exam--title-block {
  display: flex;
  justify-content: initial;
  align-items: initial;
  margin-bottom: 0.625rem;
  color: #182929; }

.exam--title-text {
  font-size: 1.12525rem;
  float: left;
  margin: 0 1rem 0 0; }

.exam--title-timer {
  margin-left: auto;
  font-size: 1.12525rem; }

.exam .exam--tabs {
  border-radius: 5px 5px 0 0;
  border-bottom: 0; }
  .exam .exam--tabs .nav-item {
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(219, 219, 219, 0.5);
    margin-right: 0.625rem; }
  .exam .exam--tabs .nav-link {
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #439c7c; }
    .exam .exam--tabs .nav-link.active {
      background: #439c7c;
      color: #ffffff;
      border-color: #439c7c; }
    .exam .exam--tabs .nav-link .question-count {
      width: 24px;
      height: 24px;
      border-radius: 5px;
      display: inline-block;
      line-height: 24px;
      text-align: center;
      margin-left: 0.5rem;
      background: #e2e2e2;
      padding: 0 5px;
      color: #595959; }

.exam--controls {
  padding: 1.5rem 0 0 0;
  display: flex;
  justify-content: initial;
  align-items: initial;
  height: calc(100vh - 166px); }
  .exam--controls-left {
    width: 300px;
    margin-right: 2rem;
    background: #ffffff;
    padding: 1rem;
    box-shadow: -0.5px 3px 6px 1px rgba(0, 0, 0, 0.05); }
  .exam--controls-right {
    width: calc(100% - 300px); }
    .exam--controls-right .question--options {
      padding: 0.3125rem 0 0 0.3125rem; }
    .exam--controls-right .question--card {
      padding: 0; }
      .exam--controls-right .question--card .question--option.question__with-img {
        grid-template-columns: 1fr 1fr 1fr 1fr; }
      .exam--controls-right .question--card .question--body {
        max-height: calc(100vh - 248px);
        overflow-y: auto;
        padding-right: 16px; }

.exam--legends {
  font-size: 0.875rem;
  color: #595959;
  display: inline-flex;
  justify-content: initial;
  align-items: center;
  margin-right: 16px; }
  .exam--legends .indicator {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
    border-radius: 3px; }

.exam--pagination {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
  grid-gap: 1rem; }
  .exam--pagination-item > button {
    color: #595959;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
    background: rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    border: 2px solid transparent;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
    .exam--pagination-item > button.active {
      background: #74b9ff;
      color: #ffffff; }
    .exam--pagination-item > button:hover, .exam--pagination-item > button:focus {
      text-decoration: none;
      opacity: 0.8; }
    .exam--pagination-item > button.false.active__marked, .exam--pagination-item > button.active.active__marked {
      background: #fcb83f;
      border-color: #fcaf26;
      color: #ffffff; }
    .exam--pagination-item > button.active.active__marked {
      box-shadow: 0 0 0 3px rgba(252, 184, 63, 0.5); }
    .exam--pagination-item > button.false.active__answered, .exam--pagination-item > button.active.active__answered {
      background: #439c7c;
      border-color: #3b8a6e;
      color: #ffffff; }
    .exam--pagination-item > button.active.active__answered {
      box-shadow: 0 0 0 3px rgba(67, 156, 124, 0.5); }

.exam--start-end {
  display: flex;
  align-items: center;
  padding: 2.5rem 0;
  flex-direction: column;
  color: #182929; }
  .exam--start-end .img-place {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
  .exam--start-end .instruction-text {
    display: block;
    font-size: 1.00012rem; }
    .exam--start-end .instruction-text p,
    .exam--start-end .instruction-text ul {
      margin-bottom: 1.5rem; }
    .exam--start-end .instruction-text ul {
      margin-left: 1.5rem; }
      .exam--start-end .instruction-text ul li {
        font-weight: 600;
        margin-bottom: 16px; }

.exam--completion {
  text-align: center; }
  .exam--completion > span {
    font-size: 1.75rem;
    font-weight: 600;
    color: #439c7c;
    margin-bottom: 1rem;
    display: block; }
  .exam--completion > p {
    font-size: 1.12525rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    padding: 0 5rem;
    color: #182929; }
  .exam--completion .btn-hold.v-btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .exam--completion .answer-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem; }

.uploaded-img {
  margin-right: 15px;
  position: relative;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background: #ffffff; }
  .uploaded-img__question {
    height: 400px;
    width: 400px; }
    .uploaded-img__question .enlarge-icon {
      position: absolute;
      right: 0;
      margin-top: 10px;
      margin-right: 15px;
      font-size: 18px;
      padding: 5px 9px;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background: #439c7c;
      color: white; }
      .uploaded-img__question .enlarge-icon:hover {
        background: #388167; }
      .uploaded-img__question .enlarge-icon:active {
        border-color: #306f59; }
  .uploaded-img__option {
    height: 200px;
    width: 200px;
    margin-left: 38px; }
    .uploaded-img__option .enlarge-icon {
      position: absolute;
      right: 0;
      margin-top: 8px;
      margin-right: 8px;
      font-size: 18px;
      padding: 3px 7px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: #439c7c;
      color: white; }
      .uploaded-img__option .enlarge-icon:hover {
        background: #388167; }
      .uploaded-img__option .enlarge-icon:active {
        border-color: #306f59; }
  .uploaded-img img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

.quiz-progress {
  background-color: #439c7c; }

.n-retakeable {
  display: flex;
  padding: 4px 10px;
  background: #ffe4b4;
  color: #1f0b0b;
  font-weight: 600;
  align-items: center;
  height: 28px;
  border-radius: 4px; }

.answer--text {
  resize: vertical;
  overflow: auto; }

.review-questions {
  display: flex;
  flex-direction: column; }
  .review-questions.active .review--done,
  .review-questions.active .review--remaining {
    color: #ffffff; }

.review--done {
  font-style: italic;
  color: #696969;
  text-align: left; }
  .review--done:active {
    color: #ffffff; }

.review--remaining {
  font-style: italic;
  color: #cb6915;
  text-align: left; }
  .review--remaining:active {
    color: #ffffff; }

.marks {
  font-style: italic;
  margin-top: 5px;
  margin-left: 15px;
  color: #6c757d; }

.quiz-height {
  max-height: calc(100vh - 250px); }

.proctoring-bg {
  background: #fff4f3 !important; }
  .proctoring-bg--text {
    color: #ff5451; }

.proctoring-title {
  display: flex;
  justify-content: space-between; }
  .proctoring-title p {
    font-weight: 600;
    font-size: 1.00012rem; }

.proctoring-top {
  display: flex;
  flex-direction: row;
  justify-content: space-around; }

.proctoring-body {
  width: 100%;
  min-height: 500px;
  background-color: #f5f5f5;
  border-radius: 4px; }
  .proctoring-body--card {
    width: 20%;
    padding: 10px;
    margin: 20px 10px;
    height: 115px;
    background-color: #ffffff;
    border-radius: 4px; }
  .proctoring-body--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 25px 0px; }
    .proctoring-body--text .recording-wave {
      background: #fff;
      min-width: 300px;
      border: 1px;
      border-radius: 1.5rem;
      min-height: 60px;
      padding: 1rem;
      align-items: center;
      display: flex;
      margin: 0.5rem; }
    .proctoring-body--text .audio-button {
      margin: 20px; }
  .proctoring-body--warning {
    font-weight: 600;
    text-align: center;
    background: #ffe4b4;
    padding: 5px; }
  .proctoring-body--image {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px; }
    .proctoring-body--image .image-button {
      margin: 20px; }

.proctoring-button {
  border: none;
  width: 620px; }

.proctoring-button_collapse {
  width: 620px; }

.proctoring-icon {
  display: inline-block; }
  .proctoring-icon__rotate {
    -webkit-transition: all 240ms ease-in-out;
    -moz-transition: all 240ms ease-in-out;
    -o-transition: all 240ms ease-in-out;
    transition: all 240ms ease-in-out;
    position: absolute;
    right: 0;
    bottom: 7px; }

.proctoring-text__center {
  text-align: center; }

.proctoring_testing {
  color: #182929;
  border: 2px solid #cbcbcb;
  font-weight: 600;
  font-size: 15px; }
  .proctoring_testing--icon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .proctoring_testing--icon i {
      color: #696969; }

.proctoring_success {
  color: #439c7c;
  border: 2px solid #439c7c;
  font-weight: 600;
  font-size: 15px; }
  .proctoring_success--icon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .proctoring_success--icon i {
      color: #439c7c; }

.proctoring_fail {
  color: #ff7775;
  border: 2px solid #ff7775;
  font-weight: 600;
  font-size: 15px; }
  .proctoring_fail--icon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .proctoring_fail--icon i {
      color: #ff7775; }

.proctoring_primary {
  border: 2px solid #0facf3; }

.test--fail {
  color: #ff7775; }

.test--notification {
  text-align: center; }

.test--line {
  height: 10px;
  border-left: 2px solid #0b6847; }

.exam-proctoring {
  margin-top: -26px; }
  .exam-proctoring .accordion .button {
    cursor: pointer;
    display: block;
    border: none;
    width: 600px; }

.vs-content {
  padding: 0 40px;
  position: relative; }
  .vs-content--left {
    float: right;
    position: sticky;
    position: -webkit-sticky;
    top: 0; }
  .vs-content--right {
    width: 78%; }

.form-builder--tools__head {
  background: #f5f5f5;
  padding: 0.5rem 1rem;
  color: #595959; }

.form-builder--tools-list {
  margin-bottom: 0;
  border: 1px solid rgba(219, 219, 219, 0.5);
  border-radius: 5px;
  overflow: hidden;
  width: 238px;
  background: #ffffff; }

.form-builder--tools__active > button,
.form-builder--tools__active a {
  background: rgba(1, 207, 201, 0.1);
  color: #439c7c;
  display: block;
  text-align: left; }

.exam--success {
  border: 3px solid #439c7c;
  position: relative; }
  .exam--success .parameter {
    background-color: #439c7c;
    color: white;
    padding: 10px; }
    .exam--success .parameter--accordian {
      width: 600px;
      background-color: #439c7c;
      display: flex;
      justify-content: space-around; }
  .exam--success .proctoring-button {
    background-color: #439c7c;
    color: white; }
    .exam--success .proctoring-button:hover {
      color: white; }
  .exam--success .proctoring-button_collapse {
    background-color: #439c7c;
    color: white;
    width: 620px; }
  .exam--success .proctoring-icon__dot {
    color: #73f1c3; }
  .exam--success .proctoring-icon {
    position: absolute;
    right: 0;
    bottom: 7px; }

.exam--fail {
  border: 3px solid #ff7775; }
  .exam--fail .parameter {
    background-color: #ff7775;
    color: white;
    padding: 10px; }
    .exam--fail .parameter--accordian {
      width: 600px;
      background-color: #ff7775;
      display: flex;
      justify-content: space-around; }
  .exam--fail .proctoring-button {
    background-color: #ff7775;
    color: white; }
    .exam--fail .proctoring-button:hover {
      color: white; }
  .exam--fail .proctoring-button_collapse {
    background-color: #ff7775;
    color: white;
    width: 620px; }

.section {
  position: relative; }
  .section--break {
    text-align: center;
    margin-bottom: 1.25rem; }
    .section--break-text {
      color: #adb5bd; }
      .section--break-text em {
        font-style: normal; }
    .section--break__legend {
      position: relative;
      margin-bottom: 1.25rem;
      text-align: center; }
      .section--break__legend::after {
        right: 0;
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        background: rgba(219, 219, 219, 0.5); }
      .section--break__legend .text {
        font-weight: 600;
        display: inline-block;
        padding: 0 1rem;
        background: #ffffff;
        position: relative;
        z-index: 2;
        line-height: 24px; }

.parameter__body {
  height: 111px;
  width: 148px; }

.videoField {
  transform: scaleX(-1); }

@media (min-width: 1400px) {
  .xlg-exam {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

.goto-quiz {
  padding: 10px 30px; }

.text-blue {
  color: #0facf3; }

.background-blue {
  background-color: #E5F8F4; }

.date-center {
  margin: 0 auto;
  width: 79%; }

.date-selection {
  font-size: 16px;
  font-weight: 600;
  padding: 0 0px 1rem;
  color: #439c7c; }
  .date-selection ul {
    list-style-type: none;
    display: inline;
    padding: 0px 10px; }
    .date-selection ul .selected {
      color: #439c7c; }
    .date-selection ul li {
      display: inline;
      color: #6c757d;
      padding: 0px 25px;
      cursor: pointer; }
      .date-selection ul li:hover {
        color: #439c7c; }
      .date-selection ul li:active {
        color: #439c7c; }
  .date-selection span {
    cursor: pointer; }

.password-key {
  display: inline-flex;
  background-color: #f8fdff;
  border: 1px #e8e8e8 solid;
  padding: 5px 12px;
  margin-right: 10px;
  font-size: 0.74987rem;
  position: relative; }
  .password-key .password:after {
    content: ' ';
    position: absolute;
    height: 65%;
    background-color: #e8e8e8;
    width: 1px;
    left: 65px; }
  .password-key .key {
    font-weight: 600;
    margin: 0px 10px; }

.live-filters div.clear-date {
  height: fit-content;
  align-self: flex-end;
  cursor: pointer; }
  .live-filters div.clear-date a {
    font-size: 0.875rem;
    color: #6c757d; }
    .live-filters div.clear-date a:hover {
      text-decoration: underline; }

.media-date {
  width: 200px; }

.media-body .media-body-title {
  display: flex; }
  .media-body .media-body-title .small span.mr-3 {
    word-break: break-word; }
  .media-body .media-body-title .tag {
    font-size: 0.62475rem;
    background: #e9ecef;
    padding: 2px 9px;
    border-radius: 15px;
    color: #1f0b0b;
    border: 1px solid #dee2e6;
    width: fit-content;
    height: fit-content;
    margin-bottom: 10px; }

.record-live .input-group-append .btn {
  z-index: 0; }

.media-duration {
  position: absolute;
  bottom: 0px;
  padding-bottom: 1.8rem; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.upload-area {
  border: 2px dashed #cfe1f0;
  margin-top: 8px;
  max-width: 700px;
  border-radius: 10px; }
  .upload-area .hold {
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding: 20px 0; }
    .upload-area .hold .text-block {
      display: block;
      padding: 10px 25px; }
      .upload-area .hold .text-block .title {
        margin-bottom: 6px;
        display: block; }
    .upload-area .hold .upload-file {
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      cursor: pointer; }

.text-pointer {
  cursor: pointer; }

.assignment-date {
  font-size: 0.875rem; }
  .assignment-date .note {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1.25rem;
    text-align: right; }

.goto-assignment [aria-label='breadcrumb'] {
  position: relative; }
  .goto-assignment [aria-label='breadcrumb'] .feedback-trigger {
    position: absolute;
    top: 0;
    right: 0; }

.option--item {
  width: 160px;
  text-align: center;
  color: #6c757d;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1.5625rem 0rem;
  position: relative;
  float: left;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear; }
  .option--item:hover {
    background: #eafaf5;
    border: 1px solid #439c7c;
    color: #439c7c;
    box-shadow: 0px 3px 6px 0 rgba(67, 156, 123, 0.151); }
  .option--item .icon-check {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: -12px;
    color: #ffffff;
    display: none;
    background: #439c7c;
    font-size: 16px;
    line-height: 24px;
    text-align: center; }
  .option--item.active {
    background: #eafaf5;
    border: 1px solid #439c7c;
    color: #439c7c;
    box-shadow: 0px 3px 6px 0 rgba(67, 156, 123, 0.151); }
    .option--item.active .icon-check {
      display: block; }

.assignment-files .submission-body {
  display: flex;
  flex-wrap: wrap; }
  .assignment-files .submission-body .block {
    width: 408px;
    margin-right: 15px; }

.general-label, .submission-left .student--info-name dd,
.submission-left .student--grade-status dd {
  font-size: 0.74987rem;
  color: #182929; }
  .general-label__light, .submission-left .student--info-name dd,
  .submission-left .student--grade-status dd {
    color: #707070; }

.img-place {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden; }
  .img-place img {
    max-width: 100%; }

.feedback-trigger {
  width: auto;
  border-radius: 4px;
  padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem;
  line-height: normal;
  margin: 0rem 0.5rem 0 1rem;
  background-color: #e9ecef;
  display: flex;
  color: #182929; }
  .feedback-trigger .icon-feedback {
    font-size: 1.3125rem; }
  .feedback-trigger__comments, .feedback-trigger:hover {
    background: rgba(116, 224, 185, 0.3); }
    .feedback-trigger__comments *, .feedback-trigger:hover * {
      color: #439c7c; }

.submission-left {
  background: #e9ecef;
  padding: 0;
  width: 22%;
  border-right: 1px solid #dbdbdb; }
  .submission-left .files-tabs .nav-tabs {
    background: #ffffff;
    border-bottom: 1px solid #dbdbdb;
    position: sticky;
    top: 0; }
    .submission-left .files-tabs .nav-tabs .nav-item {
      width: 50%; }
      .submission-left .files-tabs .nav-tabs .nav-item .nav-link {
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        align-items: center; }
  .submission-left .files-tabs .tab-content {
    background: transparent;
    border-radius: 0; }
  .submission-left .files-tabs .file-preview-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 100%;
    max-height: 100%; }
  .submission-left .student--files {
    transform-origin: top;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    width: 100%;
    font-size: 0.74987rem;
    list-style: none;
    margin: 0;
    height: calc(100vh - 227px);
    overflow: auto;
    cursor: pointer; }
    .submission-left .student--files-item {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: initial;
      align-items: center;
      font-size: 0.875rem;
      word-break: break-word; }
      .submission-left .student--files-item .actions {
        margin-left: 0.5rem;
        display: none;
        font-size: 0.875rem; }
      .submission-left .student--files-item__active {
        color: #439c7c;
        background: #ffffff; }
        .submission-left .student--files-item__active .actions {
          display: inline-block;
          color: #439c7c; }
      .submission-left .student--files-item:hover {
        background: #f8f9fa; }
    .submission-left .student--files__date-title {
      cursor: auto !important;
      text-decoration: underline;
      font-weight: 600; }
  .submission-left .student--info {
    width: 73%;
    display: flex;
    justify-content: initial;
    align-items: initial; }
    .submission-left .student--info-name {
      width: calc(100% - 48px); }
  .submission-left .student--grade-status {
    width: 27%;
    border-left: 1px solid #dbdbdb;
    padding-left: 0.625rem;
    text-align: right; }
  .submission-left .student--info-name,
  .submission-left .student--grade-status {
    margin-bottom: 0; }
    .submission-left .student--info-name dt,
    .submission-left .student--grade-status dt {
      font-weight: normal; }
    .submission-left .student--info-name dd,
    .submission-left .student--grade-status dd {
      margin-bottom: 0; }

.submission-right {
  width: calc(100% - 22%); }

.submission-content {
  height: 100%; }
  .submission-content__with-comments .submission-content__rl {
    width: calc(100% - 30%); }
  .submission-content__with-comments .submission-content__rr {
    width: 30%;
    display: block;
    height: 100%; }
  .submission-content__rl {
    width: 100%;
    border-right: 1px solid #dbdbdb; }
  .submission-content__rr {
    display: none; }

.submission-header {
  box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0.3125rem 1rem 0.3125rem 1.5rem;
  line-height: 35px;
  background: #ffffff; }
  .submission-header .submission-header--grade {
    border-left: 1px solid rgba(219, 219, 219, 0.5);
    padding-left: 10px;
    margin-left: 20px;
    display: inline-flex;
    justify-content: initial;
    align-items: center; }
    .submission-header .submission-header--grade .general-label, .submission-header .submission-header--grade .submission-left .student--info-name dd, .submission-left .student--info-name .submission-header .submission-header--grade dd,
    .submission-header .submission-header--grade .submission-left .student--grade-status dd, .submission-left .student--grade-status .submission-header .submission-header--grade dd {
      color: #343a40;
      font-size: 0.875rem;
      margin-right: 8px; }
  .submission-header .file-paginate {
    list-style: none;
    display: flex;
    justify-content: initial;
    align-items: center;
    margin: 0; }
    .submission-header .file-paginate--item {
      display: block;
      margin: 0 2px;
      cursor: pointer;
      color: #595959; }
      .submission-header .file-paginate--item.next .icon-angle, .submission-header .file-paginate--item.pre .icon-angle {
        font-weight: 800 !important;
        color: #000000 !important; }

.submission-body {
  padding: 1.25rem;
  height: 95%;
  margin-top: 10px; }
  .submission-body .block {
    background: #ffffff;
    box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.06);
    padding: 1.25rem 1.5rem;
    margin-bottom: 1.5rem; }
  .submission-body .submission--file-list {
    display: grid;
    grid-template-columns: calc(100% - 120px) 120px; }
  .submission-body .submission--file-details {
    display: grid;
    grid-template-columns: 60px calc(100% - 60px);
    align-items: center; }
    .submission-body .submission--file-details > [class^='icon-'],
    .submission-body .submission--file-details [class*='icon-'] {
      font-size: 48px;
      color: #595959; }
  .submission-body .submission--file-info {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .submission-body .submission--file-name {
    font-size: 1.00012rem;
    font-weight: 600;
    color: #182929;
    overflow: hidden;
    text-overflow: ellipsis; }
  .submission-body .submission-action {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: initial;
    align-items: center;
    justify-self: end; }
    .submission-body .submission-action--item {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #e9ecef;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #182929;
      font-size: 1.00012rem;
      margin-right: 0.5rem;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
      cursor: pointer;
      position: relative; }
      .submission-body .submission-action--item:last-child {
        margin-right: 0; }
      .submission-body .submission-action--item__active, .submission-body .submission-action--item:hover {
        background: rgba(116, 224, 185, 0.3); }
        .submission-body .submission-action--item__active *, .submission-body .submission-action--item:hover * {
          color: #439c7c; }
      .submission-body .submission-action--item__active::before {
        content: '';
        width: 6px;
        height: 6px;
        background: #439c7c;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: 2px; }
      .submission-body .submission-action--item.redirect {
        background: rgba(116, 224, 185, 0.3); }
        .submission-body .submission-action--item.redirect * {
          color: #439c7c; }
        .submission-body .submission-action--item.redirect:after {
          content: ' ';
          position: absolute;
          background: #439c7c;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          top: 1px;
          right: 3px; }
  .submission-body .submission--accordion .content-title {
    background: white !important;
    border-bottom: 1px solid #dbdbdb;
    padding: 8px 0;
    color: #182929;
    font-weight: 600;
    font-size: 0.875rem; }

.submission--accordion,
.comment--accordion {
  display: flex;
  flex-direction: column-reverse; }
  .submission--accordion .content-title,
  .comment--accordion .content-title {
    padding: 0.3125rem 1rem;
    background: #e8e8e8;
    font-weight: normal;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .submission--accordion .content-title .icon-angle,
    .comment--accordion .content-title .icon-angle {
      margin-left: auto;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear; }
  .submission--accordion .sticky-title,
  .comment--accordion .sticky-title {
    position: sticky;
    top: 0px; }
  .submission--accordion .collapse.show ~ .content-title .icon-angle,
  .submission--accordion .collapse.show + .content-title .icon-angle,
  .comment--accordion .collapse.show ~ .content-title .icon-angle,
  .comment--accordion .collapse.show + .content-title .icon-angle {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg); }

.comment .assessment-block {
  padding: 1rem;
  border-bottom: 1px solid #dbdbdb; }
  .comment .assessment-block--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem; }
  .comment .assessment-block--body {
    display: flex;
    justify-content: initial;
    align-items: center; }
    .comment .assessment-block--body .form-group {
      margin-bottom: 0; }
  .comment .assessment-block .form-group.form-inline {
    position: relative; }
    .comment .assessment-block .form-group.form-inline .form-control {
      width: 100px;
      padding-right: 40px; }
    .comment .assessment-block .form-group.form-inline .input-group-append {
      position: absolute;
      right: 0; }
      .comment .assessment-block .form-group.form-inline .input-group-append .input-group-text {
        background: transparent;
        border: none; }

.comment--item {
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: 42px calc(100% - 42px);
  position: relative;
  border-bottom: 1px solid rgba(219, 219, 219, 0.3); }
  .comment--item dl,
  .comment--item dd {
    margin-bottom: 0;
    font-size: 0.75rem; }
  .comment--item dt {
    width: calc(100% - 15px);
    display: flex;
    justify-content: initial;
    align-items: center;
    font-weight: normal;
    font-size: 0.75rem;
    margin-bottom: 5px; }
    .comment--item dt .user-name {
      font-weight: 600; }
  .comment--item dd {
    color: #595959; }
    .comment--item dd p {
      margin-bottom: 0; }
    .comment--item dd .form-group {
      margin-bottom: 0; }
      .comment--item dd .form-group textarea.form-control {
        max-height: 150px;
        overflow: auto;
        padding-right: 2.8125rem;
        position: relative; }
      .comment--item dd .form-group .upload-file {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 36px;
        padding: 2px 0px 2px 3px;
        right: 35px;
        font-size: 16px;
        background: transparent;
        border-radius: 50%;
        cursor: pointer;
        transform: rotate(30deg);
        -webkit-transform: rotate(30deg);
        -moz-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        margin: 0; }
        .comment--item dd .form-group .upload-file input[type='file'] {
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0;
          filter: opacity(0);
          cursor: pointer;
          visibility: hidden; }
        .comment--item dd .form-group .upload-file:hover {
          background: rgba(232, 232, 232, 0.65); }
  .comment--item .ellipsis-menu {
    position: absolute;
    right: 5px;
    top: 8px; }
    .comment--item .ellipsis-menu .dropdown-toggle {
      width: 24px;
      height: 24px;
      font-size: 0.74987rem; }

.comment--accordion {
  position: relative; }
  .comment--accordion .feedback-trigger {
    position: absolute;
    top: 0;
    right: 35px;
    background: transparent;
    margin: 0; }

.file-click, button.file-click {
  color: #439c7c !important;
  font-size: 0.875rem;
  display: inline-flex;
  justify-content: initial;
  align-items: center;
  word-break: break-word; }
  .file-click .icon-download {
    font-size: 1.00012rem;
    color: #439c7c; }

button.file-click {
  background: none;
  border: none; }

.submission-body,
.submission-content__rr {
  overflow-y: auto; }
  .submission-body .PdfHighlighter,
  .submission-content__rr .PdfHighlighter {
    position: relative; }
  .submission-body::-webkit-scrollbar,
  .submission-content__rr::-webkit-scrollbar {
    background-color: transparent;
    width: 5px; }
  .submission-body::-webkit-scrollbar-thumb,
  .submission-content__rr::-webkit-scrollbar-thumb {
    background-color: transparent; }

.w-450 {
  max-width: 450px; }

.online-resources .title {
  font-weight: 600;
  margin-bottom: 6px;
  position: relative; }

.online-resources label {
  color: #6c757d;
  font-weight: 600; }

.online-resources .line:after {
  content: ' ';
  height: 1px;
  width: 130%;
  position: absolute;
  top: 10px;
  left: 80px;
  background: #dee2e6; }

.online-resources .normal {
  color: #6c757d;
  font-weight: 400; }

.online-resources .submission-body {
  display: flex;
  flex-wrap: wrap; }
  .online-resources .submission-body .block {
    width: 345px;
    margin-right: 18px; }

.block {
  background: #ffffff;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.06);
  padding: 1.25rem 1.5rem; }
  .block .file-list {
    display: grid;
    grid-template-columns: calc(100% - 75px) 75px; }
    .block .file-list .file-details {
      display: grid;
      grid-template-columns: 60px calc(100% - 60px);
      align-items: center; }
      .block .file-list .file-details > [class^='icon-'],
      .block .file-list .file-details [class*='icon-'] {
        font-size: 48px;
        color: #595959; }
      .block .file-list .file-details .file-name {
        font-size: 1.00012rem;
        font-weight: 600;
        color: #1f0b0b;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 295px;
        padding-right: 7px; }
  .block .action {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: initial;
    align-items: center;
    justify-self: end; }
    .block .action--item {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #e9ecef;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #182929;
      font-size: 1.00012rem;
      margin-right: 0.5rem;
      transition: all 0.2s linear;
      cursor: pointer;
      position: relative; }
      .block .action--item__active, .block .action--item:hover {
        background: rgba(116, 224, 185, 0.3); }
        .block .action--item__active *, .block .action--item:hover * {
          color: #439c7c; }

.coded-uploadasg {
  padding: 1.25rem;
  background: #fcfcfc;
  border: 1px solid #dee2e6;
  margin-bottom: 1.5rem;
  border-radius: 4px; }
  .coded-uploadasg .title {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 20px; }
    .coded-uploadasg .title .hr {
      background: #dee2e6;
      height: 1px;
      width: 100%;
      margin-left: 10px; }

table.coded-grades {
  border-spacing: 0;
  border-collapse: separate;
  display: block;
  overflow-x: scroll; }
  table.coded-grades thead tr > th:nth-child(4) {
    border-right: 3px solid #c1c8d0; }
  table.coded-grades thead tr > th {
    white-space: nowrap;
    max-width: fit-content; }
  table.coded-grades tbody tr > td:nth-child(4) {
    border-right: 3px solid #c1c8d0; }
  table.coded-grades tbody tr > td {
    white-space: nowrap;
    max-width: fit-content; }
  table.coded-grades tbody tr.active {
    background: #e1f2ec;
    font-weight: 700; }

.course-assignment {
  font-size: 14px;
  color: #595959; }

.profile-content--info {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
  padding: 2rem;
  margin-bottom: 2rem; }
  .profile-content--info-body .icon-position {
    position: absolute;
    right: 8px;
    top: 10px; }
  .profile-content--info-body .profile-content--edit .form-control {
    display: block;
    min-width: 300px; }
  .profile-content--info-body .profile-content--edit-row {
    width: 100%;
    border-bottom: 1px solid #f0efef;
    padding: 14px 0;
    margin-bottom: 1rem; }
  .profile-content--info-body .profile-content--edit .title--border .border-line {
    top: 22px; }
  .profile-content--info-body .profile-content--edit .title--border .text-blue {
    color: #0facf3; }
  .profile-content--info-body .title--border {
    position: relative; }
    .profile-content--info-body .title--border .text {
      display: inline-block;
      background: #ffffff;
      z-index: 1;
      position: relative;
      padding-right: 0.5rem;
      font-weight: 600; }
    .profile-content--info-body .title--border .border-line {
      position: absolute;
      border-bottom: 0.5px solid #dee2e6;
      top: 16px;
      left: 0;
      right: 0; }

.profile-content--head {
  background: #439c7c;
  height: 80px;
  margin: -2rem -2rem 3rem -2rem; }

.profile-content--img {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 40px;
  border-radius: 50%; }
  .profile-content--img > span {
    background: #ffffff;
    overflow: hidden;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; }
    .profile-content--img > span img {
      max-width: 100%; }
  .profile-content--img-edit {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #ffffff;
    position: absolute;
    bottom: 1px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
    cursor: pointer;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear; }
    .profile-content--img-edit [class^='icon-'],
    .profile-content--img-edit [class*=' icon-'] {
      font-size: 0.74987rem;
      color: #182929; }
    .profile-content--img-edit:hover {
      background: #439c7c; }
      .profile-content--img-edit:hover [class^='icon-'],
      .profile-content--img-edit:hover [class*=' icon-'] {
        color: #ffffff; }

.profile-content--row {
  border-bottom: 1px solid #f0efef;
  padding: 14px 0;
  color: #182929;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: initial; }
  .profile-content--row .label {
    text-transform: uppercase;
    display: block; }
  .profile-content--row .details {
    color: #707070;
    display: block; }
  .profile-content--row:last-child {
    border-bottom: 0; }

.profile-content--edit .btn-holder {
  width: 100%;
  border-top: 1px solid #dbdbdb;
  padding: 16px 0 0 0;
  margin-top: 20px; }

.profile-content--table {
  width: 100%; }
  .profile-content--table * {
    color: #182929; }
  .profile-content--table th {
    font-weight: 600;
    padding: 5px 0; }
  .profile-content--table .multiple-level {
    display: block;
    padding: 5px 0; }

.modal-body .upload-img .filepicker {
  padding: 40px;
  background-color: #ffffff; }
  .modal-body .upload-img .filepicker .browser-text {
    font-size: 13px; }
    .modal-body .upload-img .filepicker .browser-text p > span {
      color: #439c7c; }
  .modal-body .upload-img .filepicker span.image-required {
    font-size: 10px;
    color: #adb5bd; }
  .modal-body .upload-img .filepicker .dz-preview .dz-error-mark,
  .modal-body .upload-img .filepicker .dz-preview .dz-success-mark {
    position: initial;
    margin: 0; }

.form-group .intl-tel-input.allow-dropdown input {
  width: 250px; }

.form-group .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  display: inline-flex; }

.form-group .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 10px; }

.form-group .error-msg {
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px; }

.discussion {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 15px;
  padding: 0 1px;
  transition: all 200ms ease-in; }
  @media (prefers-reduced-motion: reduce) {
    .discussion {
      transition: none; } }
  .discussion .tab-content {
    min-height: 1px; }
  .discussion-overflow {
    overflow: hidden !important; }
  .discussion .post-background {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1031; }
  .discussion .post-container {
    background: #ffffff;
    border: 1px solid #dee2e6;
    padding: 0.5rem 1rem;
    position: relative;
    border-radius: 3px;
    margin-bottom: 20px; }
    .discussion .post-container .post-link {
      border-bottom: 1px solid #dee2e6;
      list-style: none; }
      .discussion .post-container .post-link li {
        display: inline-block; }
        .discussion .post-container .post-link li .post-action {
          padding: 10px 0;
          font-size: 14px;
          margin-right: 15px;
          cursor: pointer;
          border-bottom: 2px solid transparent; }
          .discussion .post-container .post-link li .post-action.active {
            color: #439c7c;
            border-bottom: 2px solid currentColor; }
          .discussion .post-container .post-link li .post-action label {
            margin: 0;
            cursor: pointer; }
    .discussion .post-container .post-footer {
      height: 0px;
      overflow: hidden;
      border-top: 1px solid #dee2e6;
      padding: 10px 0 0;
      margin: 0;
      visibility: hidden;
      transition: all 150ms ease-in; }
      @media (prefers-reduced-motion: reduce) {
        .discussion .post-container .post-footer {
          transition: none; } }
      .discussion .post-container .post-footer li {
        display: inline-block;
        font-size: 12;
        margin-right: 15px;
        position: relative;
        overflow: hidden;
        cursor: pointer; }
        .discussion .post-container .post-footer li:hover {
          text-decoration: underline; }
        .discussion .post-container .post-footer li:last-child {
          margin: 0; }
        .discussion .post-container .post-footer li .btn:first-child {
          margin-right: 10px; }
        .discussion .post-container .post-footer li .btn:last-child {
          margin-right: 0; }
    .discussion .post-container .upload-sec-list {
      display: block;
      margin: 0.23rem 0; }
      .discussion .post-container .upload-sec-list li {
        display: inline-block;
        vertical-align: top;
        margin: 0 0.5rem 0.5rem 0;
        width: 120px;
        position: relative; }
        .discussion .post-container .upload-sec-list li img {
          width: 120px;
          height: 120px;
          object-fit: cover;
          object-position: center;
          max-width: 100%; }
        .discussion .post-container .upload-sec-list li .progress {
          display: flex;
          margin: 0;
          border-radius: 0; }
        .discussion .post-container .upload-sec-list li .close {
          cursor: pointer;
          position: absolute;
          top: 0px;
          right: 5px; }
        .discussion .post-container .upload-sec-list li.add-more-img {
          overflow: hidden; }
          .discussion .post-container .upload-sec-list li.add-more-img .upload-file {
            display: block;
            position: relative;
            border: 1px solid #dee2e6;
            font-size: 5rem;
            padding: 0 1rem;
            text-align: center;
            cursor: pointer;
            font-weight: 200;
            margin: 0; }
            .discussion .post-container .upload-sec-list li.add-more-img .upload-file input {
              opacity: 0;
              position: absolute;
              left: 0;
              cursor: pointer;
              z-index: 0;
              font-size: 14px; }
            .discussion .post-container .upload-sec-list li.add-more-img .upload-file:hover {
              color: #439c7c; }
    .discussion .post-container .status-remove {
      cursor: pointer;
      color: #1f0b0b;
      line-height: 0.5;
      position: absolute;
      right: 4px;
      top: 10px;
      display: none; }
      .discussion .post-container .status-remove:hover {
        color: #1f0b0b; }
    .discussion .post-container .status-post-sec {
      position: relative;
      max-height: 250px; }
      .discussion .post-container .status-post-sec .rdw-editor-main {
        overflow: unset; }
      .discussion .post-container .status-post-sec .preview-action a {
        margin: 0 4px; }
    .discussion .post-container .media-middle {
      max-width: calc(100% - 70px);
      padding: 10px 0; }
    .discussion .post-container-show {
      z-index: 1032; }
      .discussion .post-container-show .status-remove {
        display: block; }
      .discussion .post-container-show .post-footer {
        margin-top: 0.5rem;
        display: block;
        height: auto;
        visibility: visible; }
  .discussion .dropdown {
    cursor: pointer; }
  .discussion textarea {
    border: 0;
    height: 34px; }
    .discussion textarea:focus {
      outline: 0;
      box-shadow: none; }
  .discussion--container {
    border: 1px solid #dee2e6;
    margin-bottom: 20px;
    padding: 0.4rem 0.6rem;
    border-radius: 4px; }
    .discussion--container .user--image img {
      object-fit: cover;
      object-position: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #dee2e6; }
    .discussion--container .user--image .initialName {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #dee2e6;
      text-align: center;
      background-color: #e4e6e7;
      font-size: 1.2rem;
      padding: 0.3rem 0.1rem;
      font-weight: 600;
      color: #439c7c; }
    .discussion--container .user-post {
      font-weight: 600; }
      .discussion--container .user-post > span {
        color: #6c757d; }
    .discussion--container .content {
      margin: 10px 0;
      padding: 0.2rem 0.5rem; }
    .discussion--container .comment-img-preview {
      display: block;
      list-style: none; }
      .discussion--container .comment-img-preview li {
        display: inline-block;
        margin: 10px 10px 0 0; }
        .discussion--container .comment-img-preview li .close {
          cursor: pointer; }
        .discussion--container .comment-img-preview li img {
          width: 200px; }
    .discussion--container .media-count,
    .discussion--container .media-respond {
      border-top: 1px solid #dbdbdb;
      padding: 8px 0 10px;
      font-size: 12px; }
    .discussion--container .media-respond {
      padding-bottom: 0; }
      .discussion--container .media-respond .collapse,
      .discussion--container .media-respond .collapsing {
        margin: 0 -0.6rem -0.432rem;
        padding: 0.4rem 0.6rem; }
        .discussion--container .media-respond .collapse .user--image img,
        .discussion--container .media-respond .collapsing .user--image img {
          width: 30px;
          height: 30px; }
        .discussion--container .media-respond .collapse .user--image .initialName,
        .discussion--container .media-respond .collapsing .user--image .initialName {
          padding: 0px;
          width: 30px;
          height: 30px;
          line-height: 30px; }
        .discussion--container .media-respond .collapse .comment-area,
        .discussion--container .media-respond .collapsing .comment-area {
          display: inline-block;
          width: 100%;
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          padding: 5px 26px 5px 10px;
          min-height: 32px;
          background: #f3f7fa !important;
          position: relative; }
        .discussion--container .media-respond .collapse .media,
        .discussion--container .media-respond .collapsing .media {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #dee2e6; }
          .discussion--container .media-respond .collapse .media:first-child,
          .discussion--container .media-respond .collapsing .media:first-child {
            margin-top: 0; }
          .discussion--container .media-respond .collapse .media:last-child,
          .discussion--container .media-respond .collapsing .media:last-child {
            margin-top: 10px; }
          .discussion--container .media-respond .collapse .media .content,
          .discussion--container .media-respond .collapsing .media .content {
            margin: 5px 0 0;
            padding: 0.2rem 0.3rem;
            background: #f3f7fa;
            border-radius: 4px;
            display: inline-block; }
          .discussion--container .media-respond .collapse .media .user-coment,
          .discussion--container .media-respond .collapsing .media .user-coment {
            font-weight: 600;
            font-size: 14px; }
            .discussion--container .media-respond .collapse .media .user-coment .badges,
            .discussion--container .media-respond .collapsing .media .user-coment .badges {
              line-height: 22px;
              margin-bottom: 6px; }
      .discussion--container .media-respond a {
        margin-right: 15px;
        display: inline-block;
        cursor: pointer; }
        .discussion--container .media-respond a:hover {
          color: #439c7c; }
    .discussion--container .status-post-sec {
      position: relative;
      max-height: 250px; }
      .discussion--container .status-post-sec .upload-file {
        position: absolute;
        right: 9px;
        overflow: hidden;
        top: 7px;
        cursor: pointer;
        margin: 0; }
        .discussion--container .status-post-sec .upload-file span {
          font-size: 1.37462rem; }
        .discussion--container .status-post-sec .upload-file input {
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0;
          -webkit-filter: opacity(0);
          filter: opacity(0);
          cursor: pointer;
          visibility: hidden; }
      .discussion--container .status-post-sec .preview-action a {
        margin: 0 4px; }
    .discussion--container .image-list-block {
      list-style: none;
      position: relative;
      text-align: center; }
      .discussion--container .image-list-block li {
        display: inline-block;
        max-width: 500px;
        margin: 0 auto 10px;
        position: relative;
        cursor: pointer; }
      .discussion--container .image-list-block img {
        max-width: 100%;
        max-height: 600px; }
      .discussion--container .image-list-block .additional-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        background: rgba(0, 0, 0, 0.411765);
        color: #ffffff;
        font-size: 2.49988rem;
        padding: 80px 0;
        cursor: pointer; }
      .discussion--container .image-list-block.img-lt-bl-tw li {
        margin: 1px 1px;
        border: 1px solid #dee2e6;
        border-radius: 2px; }
        .discussion--container .image-list-block.img-lt-bl-tw li img {
          width: 230px;
          height: 230px;
          -o-object-fit: cover;
          object-fit: cover; }
      .discussion--container .image-list-block.img-lt-bl-odw {
        margin: auto;
        max-width: 600px; }
        .discussion--container .image-list-block.img-lt-bl-odw li {
          margin: 1px 1px;
          border: 1px solid #dee2e6;
          border-radius: 2px; }
          .discussion--container .image-list-block.img-lt-bl-odw li:first-child img {
            width: 500px;
            height: 300px; }
          .discussion--container .image-list-block.img-lt-bl-odw li img {
            width: 247px;
            height: 150px;
            -o-object-fit: cover;
            object-fit: cover; }
    .discussion--container .comment-img-post {
      max-width: 300px;
      display: block; }
      .discussion--container .comment-img-post img {
        max-width: 100%; }
  .discussion .upload-sec-file li {
    display: block;
    margin: 10px 10px;
    position: relative; }
    .discussion .upload-sec-file li .image-hold {
      width: 70px;
      display: inline-block;
      padding: 0px 10px;
      vertical-align: top; }
      .discussion .upload-sec-file li .image-hold img {
        max-width: 100%; }
    .discussion .upload-sec-file li .file-icon {
      width: 40px;
      height: 40px;
      display: inline-block; }
      .discussion .upload-sec-file li .file-icon.file-doc, .discussion .upload-sec-file li .file-icon.file-default {
        background: url("../assets/images/file-type/doc.svg"); }
      .discussion .upload-sec-file li .file-icon.file-pdf {
        background: url("../assets/images/file-type/pdf.svg"); }
      .discussion .upload-sec-file li .file-icon.file-excel {
        background: url("../assets/images/file-type/excel.svg"); }
    .discussion .upload-sec-file li .file-info {
      display: inline-block;
      max-width: calc(100% - 100px); }
      .discussion .upload-sec-file li .file-info .file-title {
        margin: 0;
        font-size: 1.00012rem; }
    .discussion .upload-sec-file li .close {
      vertical-align: top;
      margin-left: 15px;
      float: none;
      cursor: pointer; }
    .discussion .upload-sec-file li .progress {
      display: flex;
      width: 98%;
      max-width: 98%; }
  .discussion-group .group-list {
    margin: 1.25rem 0; }
    .discussion-group .group-list .block-title, .discussion-group .group-list .resources--title, .discussion-group .group-list .question--text {
      margin-bottom: 0.25rem; }
    .discussion-group .group-list .badges {
      margin: 0.75rem 0.25rem 0 0; }
  .discussion-group .group-item {
    padding: 0.75rem 1rem;
    margin: 0 -1.5rem;
    cursor: pointer;
    display: block; }
    .discussion-group .group-item > span {
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle; }
      .discussion-group .group-item > span.text {
        text-overflow: ellipsis;
        width: calc(100% - 48px);
        margin: 0;
        overflow: hidden;
        white-space: nowrap; }
      .discussion-group .group-item > span.img {
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        border-radius: 50%;
        background-color: #439c7c;
        color: #ffffff;
        font-size: 12px;
        overflow: hidden; }
        .discussion-group .group-item > span.img img {
          width: 22px;
          height: 22px;
          object-fit: cover;
          object-position: center; }
    .discussion-group .group-item .dropdown {
      display: inline-block; }
  .discussion-group .action {
    cursor: pointer;
    display: inline-block; }
    .discussion-group .action .icon {
      color: #439c7c; }
  .discussion-group .user {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    grid-gap: 1rem; }
    .discussion-group .user-item {
      position: relative;
      width: 42px;
      margin: auto;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle; }
      .discussion-group .user-item img {
        object-position: top;
        object-fit: cover;
        width: 42px;
        height: 42px;
        border-radius: 50%; }
      .discussion-group .user-item .dropdown {
        border-radius: 50%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.45);
        padding: 12px 13px;
        cursor: pointer;
        display: none; }
      .discussion-group .user-item:last-child {
        margin-right: 0; }
      .discussion-group .user-item:hover .dropdown {
        display: block; }
  .discussion-group .friend img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%; }
  .discussion-group .card .card-img-top {
    clip-path: none; }

.modal .upload-file {
  display: inline-block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  margin: 0; }
  .modal .upload-file input {
    position: absolute;
    opacity: 0;
    left: 0;
    cursor: pointer;
    z-index: 0;
    display: none; }
  .modal .upload-file:hover {
    color: #439c7c; }

.modal .upload-sec-file li {
  display: block;
  margin: 10px 10px;
  position: relative; }
  .modal .upload-sec-file li .image-hold {
    width: 70px;
    display: inline-block;
    padding: 0px 10px;
    vertical-align: top; }
    .modal .upload-sec-file li .image-hold img {
      max-width: 100%; }
  .modal .upload-sec-file li .file-icon {
    width: 40px;
    height: 40px;
    display: inline-block; }
    .modal .upload-sec-file li .file-icon.file-doc, .modal .upload-sec-file li .file-icon.file-default {
      background: url("../assets/images/file-type/doc.svg"); }
    .modal .upload-sec-file li .file-icon.file-pdf {
      background: url("../assets/images/file-type/pdf.svg"); }
    .modal .upload-sec-file li .file-icon.file-excel {
      background: url("../assets/images/file-type/excel.svg"); }
  .modal .upload-sec-file li .file-info {
    display: inline-block;
    max-width: calc(100% - 100px); }
    .modal .upload-sec-file li .file-info .file-title {
      margin: 0;
      font-size: 1.00012rem; }
  .modal .upload-sec-file li .close {
    vertical-align: top;
    margin-left: 15px;
    float: none;
    cursor: pointer; }
  .modal .upload-sec-file li .progress {
    display: flex;
    width: 98%;
    max-width: 98%; }

.modal .upload-sec-list {
  display: block;
  margin: 0.23rem 0; }
  .modal .upload-sec-list li {
    display: inline-block;
    vertical-align: top;
    margin: 0 0.5rem 0.5rem 0;
    width: 120px;
    position: relative; }
    .modal .upload-sec-list li img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      object-position: center;
      max-width: 100%; }
    .modal .upload-sec-list li .progress {
      display: flex;
      margin: 0;
      border-radius: 0; }
    .modal .upload-sec-list li .close {
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 5px; }
    .modal .upload-sec-list li.add-more-img {
      overflow: hidden; }
      .modal .upload-sec-list li.add-more-img .upload-file {
        display: block;
        position: relative;
        border: 1px solid #dee2e6;
        font-size: 5rem;
        padding: 0 1rem;
        text-align: center;
        cursor: pointer;
        font-weight: 200;
        margin: 0; }
        .modal .upload-sec-list li.add-more-img .upload-file input {
          opacity: 0;
          position: absolute;
          left: 0;
          cursor: pointer;
          z-index: 0;
          font-size: 14px; }
        .modal .upload-sec-list li.add-more-img .upload-file:hover {
          color: #439c7c; }

.group-community {
  border-bottom: 1px solid #dee2e6;
  margin: -1rem -1rem 1rem -1rem; }
  .group-community .group-info {
    padding: 1rem 1.75rem; }
  .group-community .group-img {
    height: 230px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

.tribute-container {
  z-index: 1000000; }
  .tribute-container ul {
    max-height: 350px;
    overflow: auto; }

.z-feedpost {
  z-index: 1029 !important; }

.chatbot {
  margin-top: 22px;
  margin-bottom: 0; }
  .chatbot .message-header {
    display: none; }
  .chatbot--ltpanel {
    margin: 0;
    padding: 0; }
  .chatbot--body {
    width: 100%;
    padding: 1.5rem 10%;
    height: calc(100vh - 206px);
    overflow: auto; }
  .chatbot--image {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background: #dbdbdb;
    overflow: hidden; }
    .chatbot--image img {
      width: 58px;
      object-fit: contain; }
  .chatbot--message {
    background: #f3f7fa;
    font-size: 0.875rem;
    padding: 16px 24px;
    min-height: 58px;
    margin: 0;
    border-radius: 0 10px 10px 10px;
    color: #182929;
    display: inline-flex;
    justify-content: initial;
    align-items: center;
    min-width: 65px; }
  .chatbot--interaction {
    width: 90%;
    margin-bottom: 1.5rem;
    display: grid; }
    .chatbot--interaction__bot {
      grid-template-columns: 58px calc(100% - 58px - 16px);
      grid-column-gap: 1.5rem; }
    .chatbot--interaction__user {
      margin-left: auto;
      justify-items: end; }
      .chatbot--interaction__user .chatbot--message {
        background: #439c7c;
        border-radius: 10px 0 10px 10px;
        color: #ffffff; }
  .chatbot--footer {
    border-top: 1px solid #dbdbdb;
    width: 100%;
    padding: 1.5rem 10%;
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: calc(100% - 48px - 16px) 48px;
    align-items: end; }
  .chatbot--textarea {
    border: 1px solid rgba(191, 191, 191, 0.5);
    padding: 12px 24px;
    color: #182929;
    font-size: 1.00012rem;
    border-radius: 10px;
    min-height: 48px;
    max-height: 48px;
    height: auto;
    resize: none; }
    .chatbot--textarea:focus {
      color: #495057;
      background-color: #fff;
      border-color: #8fcfb8;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }
  .chatbot--send-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #439c7c;
    color: #ffffff;
    border: none; }
  .chatbot--rtpanel {
    height: calc(100vh - 108px);
    padding: 16px 0;
    background: #fff;
    overflow: auto;
    border-radius: 10px;
    margin-right: 20px; }
  .chatbot--details {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 216px;
    border-bottom: 1px solid #dbdbdb;
    flex-direction: column; }
  .chatbot--image-place {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    background: #dbdbdb;
    margin-bottom: 8px; }
    .chatbot--image-place img {
      width: 82px;
      object-fit: contain; }
  .chatbot--name {
    font-size: 1.49975rem;
    font-weight: 600;
    padding: 2px 0;
    color: #182929; }
    .chatbot--name__sub {
      font-size: 1.25rem;
      font-weight: normal; }

.submission-header {
  box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0.3125rem 1rem 0.3125rem 1.5rem;
  line-height: 35px; }
  .submission-header--date {
    color: #439c7c;
    display: inline;
    padding: 0 1.25rem; }
  .submission-header .label {
    color: #343a40; }
  .submission-header .submission-header--grade {
    border-left: 1px solid rgba(219, 219, 219, 0.5);
    padding-left: 10px;
    margin-left: 20px;
    display: inline-flex;
    justify-content: initial;
    align-items: center; }
    .submission-header .submission-header--grade .edit {
      cursor: pointer;
      border-radius: 4px;
      background-color: #e9ecef;
      padding: 0.0625rem 0.625rem 0.0625rem 0.9375rem;
      font-size: 1.37462rem;
      display: inline;
      margin: 0rem 0.3125rem 0 0; }

.main-content,
.right-content {
  padding: 1.5rem;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  position: relative; }
  .main-content--header,
  .right-content--header {
    margin: -1.5rem -1.5rem 0 -1.5rem;
    border-bottom: 1px solid rgba(219, 219, 219, 0.8); }

.inner_loader {
  z-index: 99;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }

.toogle-block {
  border-top: 1px solid #e9ecef;
  margin-top: 1.25rem;
  padding: 1.25rem 0 0 0; }
  .toogle-block--title {
    color: #182929;
    font-weight: 600;
    margin-bottom: 0.625rem; }
  .toogle-block--toggle {
    color: #0facf3;
    margin-left: 0.625rem;
    cursor: pointer;
    font-weight: 400; }
  .toogle-block ul {
    margin: 0;
    list-style-type: none; }
    .toogle-block ul li {
      color: #6c757d;
      font-size: 0.875rem;
      margin-bottom: 8px; }
      .toogle-block ul li a {
        color: #6c757d; }
        .toogle-block ul li a:hover {
          text-decoration: underline; }

.total {
  color: #595959;
  font-size: 0.875rem; }
  .total em {
    font-style: normal;
    font-weight: normal; }

.main-app-tab {
  margin: -1.5rem -1.5rem 0 -1.5rem;
  border-radius: 10px 10px 0 0;
  overflow: hidden; }

.ellipsis-menu .dropdown-toggle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  color: #595959;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  -o-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  cursor: pointer; }
  .ellipsis-menu .dropdown-toggle:after {
    display: none; }
  .ellipsis-menu .dropdown-toggle:hover {
    background: rgba(232, 232, 232, 0.65); }

.ellipsis-menu__with-bg .dropdown-toggle {
  background: rgba(232, 232, 232, 0.65); }

.listing {
  display: grid;
  position: relative;
  grid-column-gap: 20px;
  margin: 0 -1.5rem;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5); }
  .listing--left .icon-place {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 1.5rem;
    float: left; }
  .listing--title {
    font-size: 1.00012rem;
    font-weight: 600;
    color: #182929;
    margin-bottom: 0.5rem;
    display: block; }
  .listing--desc {
    color: #595959;
    margin-bottom: 1rem; }
  .listing--misc > * {
    margin-right: 1.5rem; }
  .listing--right > * {
    float: right; }
  .listing--small {
    font-size: 0.74987rem; }
    .listing--small .bold {
      font-weight: 600; }

.helper {
  font-style: italic;
  color: #707070;
  margin: 5px 0;
  display: inline-block; }

.grade-table table.table tbody tr td {
  max-width: 300px; }

.grade-tabs .card {
  cursor: default; }
  .grade-tabs .card .card-top-title {
    padding: 20px;
    font-weight: 600;
    background-color: #e5f8f4;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .grade-tabs .card .gradebook-table .gradebook-table--header th {
    border-bottom: none;
    padding: 0;
    padding-left: 10px;
    text-transform: none; }
  .grade-tabs .card .gradebook-table tbody tr td:first-child {
    border-right: 1px solid #dee2e6; }
  .grade-tabs .card .gradebook-table tfoot tr td:first-child {
    border-right: 1px solid #dee2e6; }

.gradebook-card:hover {
  box-shadow: none !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none; }

.gradebook-card .gradebook-card--body {
  overflow-x: scroll; }

.report-card {
  text-align: center; }
  .report-card .report-card--header {
    display: flex;
    justify-content: space-between; }
    .report-card .report-card--header .header-right {
      display: flex;
      text-align: left;
      font-weight: 600; }
      .report-card .report-card--header .header-right h6 {
        text-transform: uppercase;
        margin-bottom: 0; }
  .report-card .report-card__title {
    font-size: 24px;
    font-weight: 500;
    margin: 20px; }
  .report-card .card {
    border: 1px solid #c9c9c9;
    height: auto; }
  .report-card .report-card__body .title-left:before {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 100%;
    background-color: #dee2e6;
    left: 0; }
  .report-card .report-card__body .card-top-title {
    color: #439c7c;
    text-transform: uppercase;
    font-size: 1.00012rem;
    font-weight: 500;
    padding: 10px 10px 0px; }
  .report-card .report-card__body .card-body {
    padding: 0; }
    .report-card .report-card__body .card-body table th {
      color: #595959;
      font-size: 14px;
      font-weight: 600;
      border-bottom: none !important; }
    .report-card .report-card__body .card-body th {
      position: relative; }

@media (max-width: 575.98px) {
  .grade-tabs .card .card-top-title {
    display: block; } }

.table-grade {
  background-color: #f8fdff;
  border: 1px solid #e8e8e8; }
  .table-grade table th {
    color: #595959;
    font-size: 14px;
    font-weight: 600;
    border-bottom: none !important; }

.content-unit .unit--header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  .content-unit .unit--header .header-title {
    font-size: 18px;
    font-weight: 600; }

.content-chapter .chapter-title {
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dee2e6; }

.content-chapter .chapter--video {
  width: 100%;
  display: flex;
  justify-content: space-between; }
  .content-chapter .chapter--video__list {
    height: 70px;
    width: 100%;
    padding-left: 20px;
    font-weight: 600;
    position: relative; }
    .content-chapter .chapter--video__list img {
      height: 50px; }
    .content-chapter .chapter--video__list--active {
      background-color: #e5f8f4; }
      .content-chapter .chapter--video__list--active i {
        color: #439c7c;
        position: absolute;
        left: 0;
        padding-left: 6px; }

.quiz--item {
  color: #595959;
  display: flex;
  align-items: center; }
  .quiz--item .icon-quiz {
    font-size: 1.2rem;
    margin-right: 0.375rem; }
  .quiz--item.active .icon-quiz {
    color: #439c7c; }
  .quiz--item.active .quiz--title {
    font-weight: 600;
    color: #439c7c; }

.listing--attachfile {
  list-style: none; }
  .listing--attachfile .attachfile--item {
    color: #439c7c;
    word-break: break-word;
    padding: 0.3125rem 0;
    display: flex;
    justify-content: normal;
    align-items: center; }
    .listing--attachfile .attachfile--item .action {
      display: inline-block;
      margin-left: 0.5rem;
      font-size: 0.875rem;
      cursor: pointer; }

.refer-card {
  border: 1px solid #439c7c;
  padding: 30px;
  border-radius: 5px;
  background: #ebfdfd;
  font-size: 16px; }
  .refer-card .row:nth-child(1) {
    border-bottom: 1px solid #439c7c;
    padding-bottom: 10px; }
  .refer-card .row:nth-child(2) {
    padding-top: 10px; }
  .refer-card .max-earn {
    font-size: 0.74987rem;
    color: #007bff; }

.refer-code {
  border: 1px solid #439c7c;
  padding: 10px 20px;
  border-radius: 5px;
  background: #ebfdfd;
  font-size: 16px; }

.refer-btn {
  position: relative; }
  .refer-btn .icon-duplicate {
    position: absolute;
    bottom: 0;
    margin-bottom: 2px; }
  .refer-btn--label {
    padding-left: 33px; }

.refer-modal .modal-header {
  display: none; }

.refer-modal ul {
  list-style: square;
  margin-left: 35px; }
  .refer-modal ul li {
    padding: 6px 8px;
    list-style: square !important; }

.parent-signup {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  padding: 0.25rem;
  justify-content: center;
  max-width: 900px;
  margin: auto; }
  .parent-signup__wrapper {
    background: #ffffff;
    display: flex;
    height: 100%;
    max-height: 560px; }
    .parent-signup__wrapper .left-side {
      width: 40%;
      max-width: 100%;
      padding: 1.5rem;
      display: inline-block;
      background: #439C7C;
      position: relative; }
      .parent-signup__wrapper .left-side .left-content {
        max-width: 100%;
        margin: auto;
        color: #ffffff; }
        .parent-signup__wrapper .left-side .left-content .logo {
          margin: 4rem 0; }
        .parent-signup__wrapper .left-side .left-content .title {
          font-size: 24px; }
      .parent-signup__wrapper .left-side .left-img img {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%; }
    .parent-signup__wrapper .right-side {
      width: 60%;
      max-width: 100%;
      padding: 1.5rem;
      background: #ffffff; }
      .parent-signup__wrapper .right-side .signup--form {
        padding: 1.25rem 0; }
        .parent-signup__wrapper .right-side .signup--form .icon-position {
          position: absolute;
          right: 8px;
          top: 8px; }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .parent-signup__wrapper .left-side .left-content .logo {
    width: 200px; } }

@media (max-width: 575.98px) {
  .parent-signup {
    display: block;
    padding: 0; }
    .parent-signup__wrapper {
      display: block;
      height: 100vh;
      max-height: 100vh; }
      .parent-signup__wrapper .left-side {
        width: 100%; }
        .parent-signup__wrapper .left-side .left-content {
          display: flex;
          align-items: center;
          justify-content: center; }
          .parent-signup__wrapper .left-side .left-content .logo {
            width: 250px; }
          .parent-signup__wrapper .left-side .left-content .title {
            display: none; }
        .parent-signup__wrapper .left-side .left-img img {
          display: none; }
      .parent-signup__wrapper .right-side {
        width: 100%;
        margin: 0; } }

.Student .green-text--center {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 50vh;
  align-items: center;
  color: #245543;
  font-size: 18px; }

.Student .main-content--header {
  padding: 1.5rem;
  display: flex;
  flex-direction: row-reverse; }

.Student .main-content--body {
  margin-top: 1.5rem; }
  .Student .main-content--body .student-card {
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: row; }
    .Student .main-content--body .student-card__img .student-img {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50%;
      object-fit: cover; }
    .Student .main-content--body .student-card__content {
      margin-left: 1rem; }
      .Student .main-content--body .student-card__content .student-name {
        color: #439c7c;
        font-size: 16px;
        font-weight: 600; }

.text-blue {
  color: #0FACF3; }

.modal-open {
  padding-right: 0px !important; }

.break-text {
  word-break: break-word; }

.btn-grey {
  background: #E7E9E9;
  color: #182929; }

.sis .student-profile .profile-header {
  display: flex;
  padding: 0 1.25rem 1.25rem;
  margin: 0 -1.5rem 1rem;
  border-bottom: 1px solid #e8e8e8; }
  .sis .student-profile .profile-header__image img {
    width: 61px;
    height: 61px;
    border-radius: 50%; }
  .sis .student-profile .profile-header__information {
    line-height: 10px;
    margin: 0 1.25rem;
    padding-right: 40px;
    border-right: 1px solid #e8e8e8; }
  .sis .student-profile .profile-header__grade {
    display: flex; }
    .sis .student-profile .profile-header__grade .grade-info {
      margin: 0.5rem 1.25rem; }

.sis .student-profile .profile-content__filter {
  display: flex;
  margin-bottom: 1rem; }
  .sis .student-profile .profile-content__filter .form-group {
    margin-left: 0.5rem; }

.sis .student-profile .profile-content__select {
  display: flex; }
  .sis .student-profile .profile-content__select .css-2b097c-container,
  .sis .student-profile .profile-content__select .css-14jk2my-container {
    min-width: 180px; }
  .sis .student-profile .profile-content__select .time-input {
    display: flex;
    width: 200px; }
    .sis .student-profile .profile-content__select .time-input .form-control-l {
      border-radius: 0.25rem 0 0 0.25rem; }
    .sis .student-profile .profile-content__select .time-input .form-control-r {
      border-radius: 0 0.25rem 0.25rem 0; }

.sis .student-profile .profile-content__attendance {
  display: flex; }
  .sis .student-profile .profile-content__attendance .grade-info {
    margin: 0 0.25rem; }
    .sis .student-profile .profile-content__attendance .grade-info span {
      font-size: 12px; }

.sis .student-profile .profile-content__chart {
  display: flex;
  margin-bottom: 1.25rem; }
  .sis .student-profile .profile-content__chart .chart-items {
    width: 350px;
    padding: 0 1rem; }
    .sis .student-profile .profile-content__chart .chart-items .title {
      color: #439c7c;
      font-weight: 600; }
  .sis .student-profile .profile-content__chart .overview-chart .chart {
    height: 20px;
    border-radius: 4px;
    margin: 0.5rem 0 0.5rem; }
    .sis .student-profile .profile-content__chart .overview-chart .chart .chart-green {
      background: #6edbb4; }
    .sis .student-profile .profile-content__chart .overview-chart .chart .chart-yellow {
      background: #ffcc52; }
    .sis .student-profile .profile-content__chart .overview-chart .chart .chart-red {
      background: #fd785d; }
    .sis .student-profile .profile-content__chart .overview-chart .chart .chart-gray {
      background-color: #6c757d;
      width: 20%; }
  .sis .student-profile .profile-content__chart .overview-chart .chart-status span {
    font-size: 12px; }

.sis .student-profile .profile-content__table {
  border: 1px solid #e8e8e8;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
  margin-bottom: 1.25rem;
  border-radius: 0.625rem; }
  .sis .student-profile .profile-content__table .th-additionalcourse th {
    border: 0;
    position: relative; }
    .sis .student-profile .profile-content__table .th-additionalcourse th:after {
      content: '';
      position: absolute;
      width: 85%;
      height: 1px;
      background-color: #e8e8e8;
      top: 23px;
      left: 170px; }
    .sis .student-profile .profile-content__table .th-additionalcourse th span {
      background: #ffffff;
      z-index: 1;
      position: relative;
      padding-right: 0.5rem; }

.sis .student-profile .profile-content__result .card {
  cursor: default; }
  .sis .student-profile .profile-content__result .card .card-top-title {
    padding: 20px;
    font-weight: 600;
    background-color: #e5f8f4;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .sis .student-profile .profile-content__result .card .gradebook-table .gradebook-table--header th {
    border-bottom: none;
    padding: 0;
    padding-left: 10px;
    text-transform: none; }
  .sis .student-profile .profile-content__result .card .gradebook-table tbody tr td:first-child {
    border-right: 1px solid #dee2e6; }
  .sis .student-profile .profile-content__result .card .gradebook-table tfoot tr td:first-child {
    border-right: 1px solid #dee2e6; }

.sis .student-profile .profile-content .attendancetable-wrap {
  overflow-x: hidden; }

.sis .student-profile .profile-content__attendance-table {
  overflow-x: hidden;
  max-width: 100%;
  position: relative; }
  .sis .student-profile .profile-content__attendance-table .fix-height-table {
    width: 100%;
    max-height: calc(100vh - 362px);
    overflow: auto; }
  .sis .student-profile .profile-content__attendance-table table {
    border-collapse: separate;
    border-spacing: 0; }
  .sis .student-profile .profile-content__attendance-table th {
    text-transform: none;
    color: #182929;
    border-bottom: 1px solid #e8e8e8; }
    .sis .student-profile .profile-content__attendance-table th .th-2 {
      font-size: 14px;
      font-weight: 100;
      color: #a3a3a3; }
  .sis .student-profile .profile-content__attendance-table tr > th:nth-child(2) {
    border-left: 1px solid #e8e8e8; }
  .sis .student-profile .profile-content__attendance-table tr > td:nth-child(2) {
    border-left: 1px solid #e8e8e8; }
  .sis .student-profile .profile-content__attendance-table .secondary-header tr:first-child > th:nth-child(-n + 2) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 0;
    background: #ffffff; }
  .sis .student-profile .profile-content__attendance-table .secondary-header .text-green {
    color: #439c7c; }
  .sis .student-profile .profile-content__attendance-table .secondary-header th {
    color: #a3a3a3;
    font-size: 14px;
    border-top: 1px solid #e8e8e8; }
  .sis .student-profile .profile-content__attendance-table .table-hsticky th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #ffffff; }

.sis .student-profile .profile-content__student-info .profile-header__image img {
  width: 70px;
  height: 70px;
  border-radius: 50%; }

.sis .student-profile .profile-content__student-info .custom-radio {
  width: 150px; }

.sis .student-profile .profile-content__student-info .custom-file-label::after {
  font-weight: 600;
  color: #007bff;
  content: 'Select';
  background: none !important;
  border-left: none !important;
  border-radius: 0 0.25rem 0.25rem 0; }

.sis .student-profile .profile-content__student-info .guardianinfo-wrapper {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 30px;
  position: relative; }
  .sis .student-profile .profile-content__student-info .guardianinfo-wrapper .title {
    font-weight: 600;
    position: absolute;
    top: -11px;
    left: 15px;
    padding: 0 5px;
    background: white; }
  .sis .student-profile .profile-content__student-info .guardianinfo-wrapper .cancel-action {
    position: absolute;
    top: -13px;
    right: -11px;
    background: #ebebeb;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    text-align: center;
    color: #838383;
    padding: 1px 1px; }

.sis .student-profile .profile-content__student-info .programinfo-table tr th,
.sis .student-profile .profile-content__student-info .programinfo-table tr td {
  padding-bottom: 0.5rem; }

.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td:nth-child(-n + 2),
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th:nth-child(-n + 2) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 0;
  background: #ffffff; }

.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td:nth-child(2),
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th:nth-child(2) {
  left: 161px;
  background: #ffffff; }

.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td.border-2x,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th.border-2x {
  border-right: 2px solid #dee2e6; }

.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td .dropup,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td .dropright,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td .dropdown,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td .dropleft,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th .dropup,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th .dropright,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th .dropdown,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th .dropleft {
  position: inherit; }

.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > td:nth-child(-n + 2),
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > th:nth-child(-n + 2) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 2;
  background: #ffffff; }

.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > td:nth-child(2),
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > th:nth-child(2) {
  left: 161px;
  background: #ffffff; }

.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > td.border-2x,
.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > th.border-2x {
  border-right: 2px solid #dee2e6; }

.sis .student-profile .profile-content .fix-columns__2cols .react-bootstrap-table table > thead.th-position tr th {
  z-index: 0 !important; }

.sis .student-profile .profile-content .fix-columns .fixed-col {
  width: 137px; }

.sis .student-profile .status-box {
  width: 10px;
  height: 10px;
  border: 0;
  display: inline-flex;
  margin-right: 0.5rem; }
  .sis .student-profile .status-box--green {
    background-color: #6edbb4; }
  .sis .student-profile .status-box--red {
    background-color: #fd785d; }
  .sis .student-profile .status-box--yellow {
    background-color: #ffcc52; }

.sis .student-profile .status-circle {
  width: 10px;
  height: 10px;
  border: 0;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 0.5rem; }
  .sis .student-profile .status-circle--green {
    background-color: #6edbb4; }
  .sis .student-profile .status-circle--red {
    background-color: #fd785d; }
  .sis .student-profile .status-circle--yellow {
    background-color: #ffcc52; }
  .sis .student-profile .status-circle--gray {
    background-color: #6c757d; }

.sis .student-profile .text-grey {
  color: #a3a3a3; }

.sis .student-profile .info-font {
  font-weight: 600;
  font-size: 18px; }

.gradesheet .card-title {
  padding: 20px;
  font-size: 1rem;
  font-weight: 600;
  background-color: #e5f8f4;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.overallgrade .breakdown-line {
  position: relative; }
  .overallgrade .breakdown-line span {
    display: inline-block;
    padding: 0.25rem;
    background: #ffffff;
    position: relative;
    z-index: 2;
    line-height: 24px;
    top: 14px;
    text-transform: uppercase; }

.overallgrade .detailedgrade-card {
  margin-top: 1.5rem; }
  .overallgrade .detailedgrade-card .card-title {
    font-size: 1rem;
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 0; }

.text-green {
  color: #439c7c; }

.certificate {
  display: flex;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  color: #6c757d;
  z-index: 1;
  position: relative;
  align-items: center; }
  .certificate .media-body .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.625rem;
    color: #111111; }

.buttons-design {
  display: flex;
  flex-direction: row;
  column-gap: 10px; }

/* custom component */
.accordion .level--title.link {
  border-bottom: none; }

.accordion .link {
  cursor: pointer;
  display: block;
  margin: 0;
  border-bottom: 1px solid #dee2e6; }
  .accordion .link span,
  .accordion .link .icon-angle {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    display: block; }

.accordion .collapse.show + .link {
  color: #439c7c; }
  .accordion .collapse.show + .link .icon-angle {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg); }

.accordion .collapse.show ~ .link {
  color: #439c7c; }

.accordion .collapsing ~ .link {
  color: #439c7c; }
  .accordion .collapsing ~ .link .icon-angle {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg); }

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 0;
  outline-offset: 0;
  box-shadow: none; }

.btn {
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  .btn-green {
    background-color: #439c7c;
    color: #ffffff;
    border-radius: 4px !important; }
    .btn-green:hover {
      background: #347860;
      border-color: #347860;
      color: #ffffff; }
  .btn-blue {
    background-color: #0facf3;
    color: #ffffff;
    border-color: #0facf3;
    border-radius: 4px !important; }
    .btn-blue:hover {
      background: #0a8bc5;
      border-color: #0a8bc5;
      color: #ffffff; }
  .btn-green-stroke {
    background: #ffffff;
    border-color: #439c7c;
    color: #439c7c; }
    .btn-green-stroke:hover {
      background: #439c7c;
      color: #ffffff; }
  .btn-cancel {
    background: #ffffff;
    border-color: #ffffff;
    color: #6c757d; }
    .btn-cancel:hover {
      color: #1f0b0b; }
  .btn-gray {
    background-color: #e9ecef;
    color: #1f0b0b;
    border-radius: 4px !important;
    border: 1px solid #adb5bd; }
    .btn-gray:hover {
      background: #dadfe4; }
  .btn-add {
    color: #439c7c;
    padding: 0px; }
  .btn-white {
    background-color: #ffffff !important;
    color: #0facf3; }
  .btn-like {
    text-decoration: none;
    padding: 0 0; }
    .btn-like:hover {
      color: #439c7c;
      text-decoration: none; }
  .btn-alert {
    background: #ffffff;
    color: #ff5451; }
  .btn-view {
    color: #0facf3; }
    .btn-view:hover {
      color: #0facf3; }
  .btn-white {
    color: #007bff;
    background-color: #ffffff; }
    .btn-white:hover {
      color: #0062cc; }
  .btn-cross {
    cursor: pointer;
    color: #439c7c; }

.cancel {
  margin-right: 20px;
  color: #1f0b0b;
  cursor: pointer;
  background: transparent;
  border: 0; }
  .cancel:hover {
    color: #1f0b0b; }

.links {
  font-size: 0.875rem;
  color: #439c7c;
  cursor: pointer;
  background: transparent;
  border: transparent; }
  .links:hover {
    text-decoration: underline;
    color: #439c7c; }
  .links__blue {
    color: #0facf3; }
    .links__blue:hover {
      color: #0facf3; }
  .links__red {
    color: #fb6d6b; }
    .links__red:hover {
      color: #fb6d6b; }
  .links__default {
    color: #8b9393; }
  .links__disabled {
    cursor: not-allowed;
    color: #8b9393; }
    .links__disabled:hover {
      text-decoration: none;
      color: #8b9393; }

.cancel-action {
  background: #ebebeb;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  color: #838383;
  padding: 1px 1px; }

.btn-sm, .btn-group-sm > .btn {
  height: 32px;
  line-height: 1; }

.btn-w150 {
  width: 150px; }

.carets {
  width: 0;
  height: 0;
  display: inline-block;
  border: 5px solid transparent;
  background: none; }
  .carets-down {
    border-top-color: rgba(67, 156, 124, 0.3); }
    .carets-down.active {
      border-top-color: #439c7c; }
    .carets-down:hover {
      border-top-color: #439c7c; }
  .carets-up {
    border-bottom-color: rgba(67, 156, 124, 0.3); }
    .carets-up.active {
      border-bottom-color: #439c7c; }
    .carets-up:hover {
      border-bottom-color: #439c7c; }

.custom-button-split .dropdown-toggle {
  position: relative;
  padding-right: 2.5rem; }
  .custom-button-split .dropdown-toggle .split-arrow {
    padding: 0 0.625rem;
    background: rgba(255, 255, 255, 0.25);
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 30px;
    height: 33px; }
    .custom-button-split .dropdown-toggle .split-arrow:before {
      display: inline-block;
      content: '';
      height: 0;
      width: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 6px;
      border-top-color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -30%); }
  .custom-button-split .dropdown-toggle:after {
    display: none; }

.breadcrumb {
  background: transparent;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.00012rem;
  font-weight: 400;
  padding: 0.625rem 0;
  border-radius: 0; }
  .breadcrumb-item {
    color: #182929; }

.carousel-indicators {
  margin-bottom: 10px; }
  .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #439c7c;
    background: #ffffff;
    opacity: 1; }
    .carousel-indicators li.active {
      background: #439c7c; }

.styled-editor-link {
  position: relative; }
  .styled-editor-link:hover:after {
    background-size: 15px 15px;
    display: inline-block;
    background-color: white;
    color: black;
    width: 15px;
    height: 15px;
    margin-left: -65%;
    content: '';
    position: absolute; }

.content-unit {
  padding: 1.5rem; }
  .content-unit .unit--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
    .content-unit .unit--header .header-title {
      font-size: 18px;
      font-weight: 600; }

.user .dropdown-toggle:after {
  display: none; }

.user .user-img {
  height: 44px;
  width: 44px;
  border-radius: 20%;
  border: 1px solid #f3f7fa; }

.user .dropdown-menu {
  border: 1px solid #dbdbdb;
  padding: 0; }
  .user .dropdown-menu ul {
    list-style: none;
    margin-bottom: 0; }

.user .dropdown--item {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0.625rem 1.25rem;
  cursor: pointer; }
  .user .dropdown--item > button {
    padding: 0;
    text-decoration: none;
    width: 100%;
    text-align: left;
    color: initial; }
  .user .dropdown--item .user-info {
    width: 100%;
    min-width: 18.125rem;
    display: grid;
    grid-template-columns: 48px calc(100% - 58px);
    grid-column-gap: 10px;
    align-items: center;
    margin-left: 0; }
  .user .dropdown--item .user--img-hold {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    .user .dropdown--item .user--img-hold img {
      max-width: 100%; }
  .user .dropdown--item:hover {
    background: #439c7c; }
    .user .dropdown--item:hover a,
    .user .dropdown--item:hover > button,
    .user .dropdown--item:hover .user--email {
      color: #ffffff; }
  .user .dropdown--item a {
    display: block; }

.dropdown-toggle.no-caret:after {
  display: none; }

.filter__custom .react-datepicker-wrapper {
  width: 100px; }

.filter__custom .react-datepicker__input-container input {
  height: 39px;
  width: 92px; }

.filter__custom label {
  margin-bottom: 0px; }

.filter__custom .form-group {
  margin-bottom: 0rem; }

.filter__custom .e-date {
  margin-right: 1rem; }

.custom-se__filters {
  margin-bottom: 1rem; }
  .custom-se__filters .form-group {
    margin-bottom: 0rem !important; }
  .custom-se__filters label {
    margin-bottom: 0; }

.custom-se__custom {
  margin-left: 0rem; }

.custom-se__dropdown .grades-drop {
  width: 140px !important;
  margin-right: 1rem; }

.server-error {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center; }
  .server-error svg {
    margin: 10px; }

.modal {
  z-index: 999999; }
  .modal-backdrop.fade.in {
    opacity: 0.4; }
  .modal.in.fade {
    opacity: 1; }
    .modal.in.fade .modal-dialog {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0); }
  .modal .bottom-btn {
    margin-top: 20px; }
  .modal-body {
    margin: 0; }
  .modal .warning-modal--header {
    background-color: #fff4f3;
    justify-content: center;
    border: 0; }
    .modal .warning-modal--header .warning-modal--title {
      color: #ff5451;
      font-size: 1.12525rem;
      font-weight: 600;
      line-height: 1.5; }
  .modal .modal-body .info-block {
    padding: 15px;
    color: #33a5f7;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid currentColor;
    border-left: 5px solid currentColor; }
  .modal .modal-body p {
    color: #595959; }
  .modal .modal-body ul li {
    margin: 0px 15px; }
  .modal-header {
    background: #e5f8f4;
    border: 0; }
  .modal-footer {
    border-top: none; }
  .modal .tab-content {
    min-height: 10px; }
  .modal-content {
    word-break: break-word; }

.custom-modal {
  display: none;
  position: fixed;
  z-index: 9999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9); }
  .custom-modal.show {
    display: block; }
  .custom-modal .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px; }
  .custom-modal #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px; }
  .custom-modal .modal-content,
  .custom-modal #caption {
    animation-name: zoom;
    animation-duration: 0.6s; }

@keyframes zoom {
  from {
    transform: scale(0); }
  to {
    transform: scale(1); } }
  .custom-modal .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s; }
  .custom-modal .close:hover,
  .custom-modal .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer; }
  @media only screen and (max-width: 700px) {
    .custom-modal .modal-content {
      width: 100%; } }

.navbar {
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.1); }
  .navbar-light {
    background-color: #f3f7fa !important; }
    .navbar-light .navbar-brand {
      text-align: center;
      cursor: pointer; }
      .navbar-light .navbar-brand.lg {
        width: 100px;
        background: #0d363d;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-left: -1rem;
        margin-top: -0.5rem;
        margin-bottom: -1rem; }
        .navbar-light .navbar-brand.lg svg {
          width: 40px; }

.nav-tabs .nav-link {
  border-width: 0 0 2px 0;
  border-color: transparent;
  margin: 0;
  color: #6c757d; }
  .nav-tabs .nav-link.active {
    border-color: currentColor;
    color: #439c7c; }

.pagination {
  justify-content: end; }
  .pagination .page-item {
    margin: 0 !important; }
    .pagination .page-item .page-link {
      padding: 0.25rem 0.5rem;
      font-size: 14px; }

.table.small-td-p td {
  padding: 0.25rem; }

.table th {
  color: #439c7c;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.00012rem;
  vertical-align: middle; }
  .table th:focus {
    box-shadow: none;
    outline: 0; }
  .table th.sortable {
    cursor: pointer; }
    .table th.sortable[aria-label*='desc'] .react-bootstrap-table-sort-order .caret {
      width: 32px;
      height: 32px;
      position: relative;
      top: -7px;
      right: -11px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #439c7c transparent; }
    .table th.sortable[aria-label*='asc'] .react-bootstrap-table-sort-order .caret {
      width: 32px;
      height: 32px;
      position: relative;
      top: 1px;
      right: -11px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #439c7c transparent transparent transparent; }
    .table th.sortable .order {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 10px;
      opacity: 0.4; }
      .table th.sortable .order .dropup,
      .table th.sortable .order .dropdown {
        display: block;
        position: absolute;
        left: 50%; }
        .table th.sortable .order .dropup .caret,
        .table th.sortable .order .dropdown .caret {
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid; }
      .table th.sortable .order .dropup {
        top: 7px; }
        .table th.sortable .order .dropup .caret {
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent #439c7c transparent; }
      .table th.sortable .order .dropdown {
        bottom: -7px; }
        .table th.sortable .order .dropdown .caret {
          border-width: 5px 5px 0 5px;
          border-color: #439c7c transparent transparent transparent; }

.table th,
.table td {
  border-top: 0; }

.table td .low {
  color: #00b795; }

.table td .medium {
  color: #0facf3; }

.table td .high {
  color: #ff5451; }

.table-responsive {
  display: table; }

.fix-columns {
  overflow-x: auto; }
  .fix-columns__1col .react-bootstrap-table table > tbody > tr > td:nth-child(-n + 2),
  .fix-columns__1col .react-bootstrap-table table > tbody > tr > th:nth-child(-n + 2),
  .fix-columns__1col .react-bootstrap-table table > thead > tr > td:nth-child(-n + 2),
  .fix-columns__1col .react-bootstrap-table table > thead > tr > th:nth-child(-n + 2) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 0;
    background: #ffffff; }
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td:nth-child(-n + 2),
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th:nth-child(-n + 2) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    background: #ffffff; }
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td:nth-child(2),
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th:nth-child(2) {
    left: 324px;
    background: #ffffff; }
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td.border-2x,
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th.border-2x {
    border-right: 2px solid #dee2e6; }
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td .dropup,
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td .dropright,
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td .dropdown,
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > td .dropleft,
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th .dropup,
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th .dropright,
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th .dropdown,
  .fix-columns__2cols .react-bootstrap-table table > tbody > tr > th .dropleft {
    position: inherit; }
  .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > td:nth-child(-n + 2),
  .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > th:nth-child(-n + 2) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    background: #ffffff; }
  .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > td:nth-child(2),
  .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > th:nth-child(2) {
    left: 324px;
    background: #ffffff; }
  .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > td.border-2x,
  .fix-columns__2cols .react-bootstrap-table table > thead > tr:first-child > th.border-2x {
    border-right: 2px solid #dee2e6; }
  .fix-columns__3cols .react-bootstrap-table table > tbody > tr > td:nth-child(-n + 3),
  .fix-columns__3cols .react-bootstrap-table table > tbody > tr > th:nth-child(-n + 3),
  .fix-columns__3cols .react-bootstrap-table table > thead > tr > td:nth-child(-n + 3),
  .fix-columns__3cols .react-bootstrap-table table > thead > tr > th:nth-child(-n + 3) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 0;
    background: #ffffff; }
  .fix-columns__3cols .react-bootstrap-table table > tbody > tr > td:nth-child(2),
  .fix-columns__3cols .react-bootstrap-table table > tbody > tr > th:nth-child(2),
  .fix-columns__3cols .react-bootstrap-table table > thead > tr > td:nth-child(2),
  .fix-columns__3cols .react-bootstrap-table table > thead > tr > th:nth-child(2) {
    left: 64px;
    background: #ffffff; }
  .fix-columns__3cols .react-bootstrap-table table > tbody > tr > td:nth-child(3),
  .fix-columns__3cols .react-bootstrap-table table > tbody > tr > th:nth-child(3),
  .fix-columns__3cols .react-bootstrap-table table > thead > tr > td:nth-child(3),
  .fix-columns__3cols .react-bootstrap-table table > thead > tr > th:nth-child(3) {
    left: calc(286px + 64px);
    background: #ffffff; }
  .fix-columns .fixed-col {
    width: 300px; }

.table-wrap.position-relative {
  top: 22px; }
  .table-wrap.position-relative ._loading_overlay_overlay {
    top: 22px; }

.fuse-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px; }
  .fuse-tabs .nav li {
    padding: 0; }
    .fuse-tabs .nav li a {
      color: #6c757d;
      font-weight: 600;
      border-bottom: 4px solid transparent;
      font-size: 1.00012rem; }
      .fuse-tabs .nav li a:hover {
        color: #ffffff;
        border-color: #439c7c;
        background: #439c7c; }
      .fuse-tabs .nav li a:focus {
        background-color: none; }
    .fuse-tabs .nav li.active a {
      color: #439c7c;
      border-bottom: 4px solid #439c7c; }
      .fuse-tabs .nav li.active a:hover {
        color: #ffffff; }
  .fuse-tabs .nav-tabs {
    border: none; }
    .fuse-tabs .nav-tabs .nav-item {
      cursor: pointer; }
      .fuse-tabs .nav-tabs .nav-item.active .nav-link {
        color: #439c7c;
        border-bottom: 4px solid #439c7c; }
        .fuse-tabs .nav-tabs .nav-item.active .nav-link:hover {
          color: #ffffff; }
      .fuse-tabs .nav-tabs .nav-item .nav-link {
        padding: 20px 45px;
        border-radius: 0 !important;
        text-transform: uppercase;
        font-size: 1.00012rem;
        font-weight: 600; }
        .fuse-tabs .nav-tabs .nav-item .nav-link:hover {
          color: #ffffff;
          border-color: #439c7c;
          background: #439c7c; }
  .fuse-tabs .button {
    text-align: right;
    margin: 12px 20px 0px 0px; }

.resource-tab {
  color: #182929; }

.form--wrapper .nav-tabs {
  margin: 1rem auto; }
  .form--wrapper .nav-tabs.nav-fill {
    border: none;
    position: relative; }
    .form--wrapper .nav-tabs.nav-fill .nav-link {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      border: none;
      margin-right: 10px;
      border-top: 2px solid currentColor;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0; }
      .form--wrapper .nav-tabs.nav-fill .nav-link .proc {
        position: relative;
        min-height: 16px; }
      .form--wrapper .nav-tabs.nav-fill .nav-link .text > span {
        margin-right: 5px; }
      .form--wrapper .nav-tabs.nav-fill .nav-link .img,
      .form--wrapper .nav-tabs.nav-fill .nav-link .num {
        margin-right: 5px;
        display: none; }
      .form--wrapper .nav-tabs.nav-fill .nav-link.active {
        color: #0facf3; }
      .form--wrapper .nav-tabs.nav-fill .nav-link.done {
        color: #439c7c; }
        .form--wrapper .nav-tabs.nav-fill .nav-link.done .img {
          display: inline; }
        .form--wrapper .nav-tabs.nav-fill .nav-link.done .text > span {
          display: none; }

.form--wrapper .tab-content {
  padding: 1rem 1.25rem 1rem 3.25rem; }
  .form--wrapper .tab-content .title {
    font-size: 16px;
    text-transform: uppercase;
    margin-left: -1.8rem; }
    .form--wrapper .tab-content .title img {
      margin-right: 6px; }
  .form--wrapper .tab-content .btn-grp {
    border-top: 1px solid #dee2e6; }
    .form--wrapper .tab-content .btn-grp .btn {
      text-transform: uppercase;
      padding-left: 1.5rem;
      padding-right: 1.5rem; }
    .form--wrapper .tab-content .btn-grp .cancel {
      color: #1f0b0b;
      margin-right: 25px;
      padding-left: 0;
      padding-right: 0; }

.tab-content {
  background: #ffffff;
  width: 100%;
  min-height: 200px; }

.fuse-link {
  margin: 25px 20px 20px;
  color: #007bff;
  text-decoration: none; }

.dropdownselect {
  position: relative;
  width: 100%; }
  .dropdownselect__btn {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .dropdownselect__menu {
    width: 100%;
    position: absolute;
    z-index: 4;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box; }
    .dropdownselect__menu--open {
      inset: 0px;
      position: fixed;
      z-index: 3; }
    .dropdownselect__menu .css-26l3qy-menu {
      margin: 0 !important; }
    .dropdownselect__menu .css-9gakcf-option {
      background-color: #ffffff !important;
      color: #495057 !important; }

.popup-notification-wrap {
  position: fixed;
  width: 340px;
  bottom: 20px;
  right: 40px;
  z-index: 99999; }
  .popup-notification-wrap .popup-notification {
    display: none;
    width: 340px;
    height: 100px;
    position: relative;
    background: #ffffff;
    z-index: 1060;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
    border-radius: 5px; }
    .popup-notification-wrap .popup-notification.show {
      display: block; }
    .popup-notification-wrap .popup-notification .discard:after {
      content: '\002B';
      position: absolute;
      top: 0px;
      right: 9px;
      font-size: 20px;
      transform: rotate(-44deg); }
    .popup-notification-wrap .popup-notification .content {
      font-size: 12px;
      cursor: pointer;
      width: 100%;
      height: 100%; }
      .popup-notification-wrap .popup-notification .content:hover {
        background: #f3f7fa;
        z-index: -5; }
      .popup-notification-wrap .popup-notification .content .aside .icon {
        position: absolute;
        left: 25px;
        top: 38px;
        z-index: 0; }
        .popup-notification-wrap .popup-notification .content .aside .icon:before {
          content: ' ';
          position: absolute;
          width: 35px;
          height: 35px;
          left: -6px;
          top: -4px;
          background-color: #e5f8f4;
          border-radius: 50%;
          z-index: -1; }
      .popup-notification-wrap .popup-notification .content .body {
        padding: 15px 30px 10px 70px; }
        .popup-notification-wrap .popup-notification .content .body .title {
          font-size: 12px;
          font-weight: 900;
          margin-bottom: 5px;
          height: 15px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
        .popup-notification-wrap .popup-notification .content .body .discription {
          line-height: 14px;
          font-size: 12px;
          height: 45px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis; }
    .popup-notification-wrap .popup-notification .branding {
      position: absolute;
      bottom: 5px;
      right: 10px; }
      .popup-notification-wrap .popup-notification .branding img {
        width: 80px; }

.notification-detail .notification-title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 5px; }

.notification-detail .notification-content .notification-message {
  font-size: 14px;
  margin-bottom: 10px; }

.notification-detail .notification-content .notification-img img {
  width: 100%; }

.side-notification {
  max-width: 100%;
  position: fixed;
  width: 400px;
  background-color: #ffffff;
  height: 100%;
  padding: 15px 0 0 0;
  top: 0;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 1050;
  transition: right 0 0.25s ease-in-out,  opacity 0 0.25s ease-in-out; }
  .side-notification-wrap {
    display: none;
    left: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 1040;
    background: rgba(0, 0, 0, 0.56);
    transition: right 0 0.2s ease-in-out,  opacity 0 0.2s ease-in-out;
    max-width: 100%; }
    .side-notification-wrap.show {
      display: block;
      right: 0; }
  .side-notification-close {
    height: 100%;
    margin-left: 400px; }
  .side-notification hr {
    margin-bottom: 0px; }
  .side-notification .close {
    margin-right: 1rem; }
  .side-notification .notification-header {
    padding: 0 15px; }
  .side-notification .notification-list {
    height: calc(100vh - 60px);
    padding-top: 0.5rem; }
    .side-notification .notification-list .formatted-date {
      margin: 0 0 0 10px;
      text-transform: uppercase;
      font-weight: 600; }
    .side-notification .notification-list .notification {
      width: 100%;
      padding: 15px 10px 15px 15px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      cursor: pointer;
      margin-bottom: 1px; }
      .side-notification .notification-list .notification.unread {
        border-left: 6px solid #17a2b8;
        background: #f3f7fa; }
        .side-notification .notification-list .notification.unread.title {
          font-weight: 600; }
      .side-notification .notification-list .notification:hover {
        background: #f8f8f8; }
      .side-notification .notification-list .notification .body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%; }
        .side-notification .notification-list .notification .body .title {
          font-weight: 600;
          margin: 0 0 5px 0;
          -webkit-line-clamp: 1; }
        .side-notification .notification-list .notification .body .notification-img {
          width: 100%;
          height: 100%;
          padding: 10px 0px; }
        .side-notification .notification-list .notification .body .message {
          -webkit-line-clamp: 2;
          font-size: 12px; }
        .side-notification .notification-list .notification .body .date {
          font-size: 12px;
          margin-top: 10px; }
        .side-notification .notification-list .notification .body .title,
        .side-notification .notification-list .notification .body .message {
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; }
      .side-notification .notification-list .notification .marker {
        margin-top: 5px;
        display: block;
        width: 12px;
        height: 12px;
        border: 2px solid #ced4da;
        cursor: pointer;
        border-radius: 50%; }
        .side-notification .notification-list .notification .marker:hover {
          border-color: #7ec7ad; }
        .side-notification .notification-list .notification .marker.checked {
          background: #439c7c; }
  .side-notification__message {
    margin-top: 15px;
    display: flex;
    justify-content: center; }
    .side-notification__message.btm-message {
      padding-bottom: 10px; }

.notification-count {
  position: relative; }
  .notification-count .count-wrap {
    position: absolute;
    height: 20px;
    top: 0;
    right: 30px;
    width: 20px; }
  .notification-count .count {
    position: absolute;
    right: -6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dc3545;
    color: #ffffff;
    border-radius: 50%;
    font-size: 11px; }

form .form--body {
  height: 315px;
  overflow-y: auto;
  overflow-x: hidden; }

.form-group > label {
  color: #595959;
  margin-right: 1.25rem; }

.form-group__inline {
  display: flex;
  justify-content: initial;
  align-items: center; }

.form-group .css-yk16xz-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #8fcfb8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 156, 124, 0.25); }

.form-group .css-yk16xz-control:hover {
  border-color: #8fcfb8; }

.form-section {
  margin-bottom: 1.25rem; }
  .form-section--head {
    position: relative;
    margin-bottom: 1.25rem; }
    .form-section--head::after {
      right: 0;
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      background: rgba(219, 219, 219, 0.5); }
  .form-section--text {
    font-weight: 600;
    display: inline-block;
    padding-right: 1rem;
    background: #ffffff;
    position: relative;
    z-index: 2;
    line-height: 24px; }

.form--footer {
  padding: 1rem 0 0 0;
  border-top: 1px solid #dbdbdb;
  display: flex;
  flex-direction: row-reverse; }
  .form--footer .btn {
    margin-left: 1rem; }
  .form--footer.end {
    position: absolute;
    bottom: 0;
    margin: 0 -1.5rem; }
  .form--footer.mr-btm {
    margin-bottom: 20px; }

.form-block--title {
  color: #595959;
  margin-bottom: 0.625rem;
  position: relative;
  font-weight: 600;
  padding: 0.5rem 0; }
  .form-block--title:before {
    content: '';
    height: 1px;
    background: #dbdbdb;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%; }
  .form-block--title span {
    padding-right: 0.625rem;
    background: #ffffff;
    display: inline-block;
    position: relative;
    z-index: 2; }

/* For Big Loader */
.big-loader {
  height: 100vh;
  position: relative; }

.inner_loader {
  height: 200px; }

/* .small-loader {
  height: 200px;
  position: relative;
} */
.path1 {
  stroke-dasharray: 300;
  stroke-width: 0.85;
  stroke-dashoffset: 0;
  fill-opacity: 1;
  fill: #fff;
  animation: 1s ease-in-out infinite;
  animation-delay: 0.2s;
  transform-origin: center center; }

.path2 {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  stroke-width: 0.85;
  fill-opacity: 0;
  fill: #fff;
  animation: dashfill 0.8s linear infinite;
  animation-delay: 0.2s;
  transform-origin: center center; }

svg.svg-loader {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

@keyframes dashfill {
  0% {
    stroke-dashoffset: 300;
    fill-opacity: 0; }
  75% {
    fill-opacity: 0; }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1; } }

/* Text Animation */
.fm-light {
  text-align: center;
  background: #fff;
  width: 50px;
  height: 38px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.fm-light .text-loading {
  color: #afafaf;
  font-size: 30px;
  font-weight: 300;
  margin: 0;
  font-family: Montserrat, sans-serif; }

.fm-light .text-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  mix-blend-mode: color;
  background: #00aeef;
  animation: textwidth 1s linear infinite;
  animation-delay: 0.2s;
  transform-origin: center center; }

@keyframes textwidth {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

/* .loader-small {
  height: calc(100vh - 50px);
} */
.loader-small svg.svg-loader {
  width: 60px;
  height: 60px; }

/* .big-loader svg.svg-loader {
  width: 100px;
  height: 100px;
} */
.loader-small svg.svg-loader .path1,
.loader-small svg.svg-loader .path2 {
  stroke-width: 2; }

.loader-small .fm-light {
  width: 30px;
  height: 28px; }

.loader-small .fm-light .text-loading {
  font-size: 20px; }

/* custom secon loader */
@keyframes show {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* .container {
padding-top: 100px;
} */
.col-center {
  float: none;
  margin: auto;
  text-align: center; }

/* #loader {
  width: 100px;
  height: 100px;
  margin: auto;
} */
.segment {
  animation: show 2s infinite;
  animation-fill-mode: forwards;
  opacity: 0; }

.segment:nth-child(1) {
  animation-delay: 0.2s;
  fill: #0d363d; }

.segment:nth-child(2) {
  animation-delay: 0.4s;
  fill: #174e4d; }

.segment:nth-child(3) {
  animation-delay: 0.6s;
  fill: #439c7c; }

.segment:nth-child(4) {
  animation-delay: 0.8s;
  fill: #80b8a3; }

.segment:nth-child(5) {
  animation-delay: 1s;
  fill: #bfe0d9; }

.segment:nth-child(6) {
  animation-delay: 1.2s;
  fill: #e5f8f4; }

.card {
  color: #1f0b0b;
  cursor: pointer;
  height: 100%;
  border: 0; }
  .card-img-top {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    height: 100px;
    object-fit: cover;
    object-position: center; }
  .card .indicator {
    width: 15px;
    height: 15px; }
  .card-title {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0; }

.badges {
  padding: 0.2rem 0.8rem;
  border-radius: 20px;
  line-height: 1;
  display: inline-block; }
  .badges__rounder {
    border-radius: 20px; }
  .badges-success {
    color: #439c7c;
    background: #e1f2ec; }
  .badges-warning {
    color: #efc829;
    background: #fdf9e7; }
  .badges-secondary {
    color: #6c757d;
    background: #f8f9fa; }
  .badges-danger {
    color: #dc3545;
    background: #fae3e5; }
  .badges-outline-success {
    color: #439c7c;
    border: 1px solid currentColor; }
  .badges-outline-warning {
    color: #efc829;
    border: 1px solid currentColor; }
  .badges-outline-secondary {
    color: #6c757d;
    border: 1px solid currentColor;
    background: #d8dbdd; }
  .badges-outline-danger {
    color: #dc3545;
    border: 1px solid currentColor; }
  .badges-outline-blue {
    color: #0facf3;
    border: 1px solid currentColor;
    background: #d1effd; }
  .badges-outline-refer {
    color: #0facf3;
    border: 1px solid currentColor;
    background: #ffffff; }
  .badges-publish, .badges-green-fill {
    color: #ffffff;
    background-color: #439c7c; }
  .badges-red {
    color: #ffffff;
    background-color: #ff7775; }
  .badges.active {
    background: #439c7c;
    color: #ffffff; }
  .badges-overallgrade {
    color: #439c7c;
    font-size: 1.00012rem;
    font-weight: 600;
    border-radius: 5px;
    padding: 0.5rem 0.75rem;
    background: #f0f8f5; }
  .badges-notattempt {
    color: #ffffff;
    background-color: #fcb83f; }
  .badges-failed {
    color: #ffffff;
    background-color: #ff7775; }

.progress {
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  margin: 0rem 0rem;
  height: 0.5rem;
  background-color: #d0ebe1; }
  .progress.full-length {
    width: 100%;
    margin: 0; }

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  cursor: not-allowed; }

.custom-control-label {
  display: block; }

.toolbar {
  position: sticky;
  width: 100%;
  top: -21px; }

/* third party */
.zoom-content {
  position: relative !important;
  background-color: black;
  width: calc(100% - 0);
  height: calc(100vh - 200px) !important; }
  .zoom-content .meeting-app {
    max-width: 100%;
    max-height: 100%; }
  .zoom-content .meeting-client,
  .zoom-content .meeting-client-inner {
    position: relative;
    overflow: auto; }
  .zoom-content .diaout-layer {
    width: 100% !important; }
  .zoom-content .active-main {
    height: calc(100vh - 200px) !important; }
  .zoom-content .show-participants {
    max-width: calc(100% - 400px); }
  .zoom-content canvas {
    width: 100% !important;
    height: 100% !important; }
  .zoom-content #wc-content {
    display: flex; }
    .zoom-content #wc-content #wc-container-right,
    .zoom-content #wc-content #wc-container-left {
      float: none; }
    .zoom-content #wc-content .participant-scrollbar,
    .zoom-content #wc-content .chat-scrollbar {
      max-height: calc(100vh - 367px) !important; }
    .zoom-content #wc-content .participant-scrollbar {
      max-height: calc(100% - 400px); }
    .zoom-content #wc-content .chat-control-to {
      float: left; }

.zoom-wrapper {
  z-index: 1040; }
  .zoom-wrapper #wc-content #videoMenu:after,
  .zoom-wrapper #wc-content #audioMenu:after {
    display: none; }
  .zoom-wrapper #wc-content .dropdown.open .dropdown-menu,
  .zoom-wrapper #wc-content .dropup.open .dropdown-menu {
    display: block; }
  .zoom-wrapper #wc-content .popmenu > li > a {
    display: block; }
  .zoom-wrapper #wc-content #wc-footer button {
    color: #ffffff; }
  .zoom-wrapper #wc-content .chat-control .chat-control-to {
    display: inline-block; }
  .zoom-wrapper #wc-content .chat-participant-header {
    position: relative; }
    .zoom-wrapper #wc-content .chat-participant-header .btn-toolbar {
      position: absolute;
      left: 5px;
      top: 10px; }

.ReactModal__Overlay--after-open {
  z-index: 1040 !important; }

.ReactModal__Overlay .dropdown.open .dropdown-menu,
.ReactModal__Overlay .dropup.open .dropdown-menu {
  display: block; }

.ReactModal__Overlay .popmenu > li > a {
  display: block; }

.ReactModal__Overlay #wc-footer button {
  color: #ffffff; }

.ReactModal__Overlay .chat-control .chat-control-to {
  display: inline-block; }

.ReactModal__Overlay .chat-participant-header {
  position: relative; }
  .ReactModal__Overlay .chat-participant-header .btn-toolbar {
    position: absolute;
    left: 5px;
    top: 10px; }

.css-b8ldur-Input input[type='text']:focus {
  outline: #ffffff !important; }

.css-1hwfws3 .css-1g6gooi {
  opacity: 0; }

.custom-calander.react-datepicker {
  border-radius: 10px;
  border: 0;
  font-family: 'Source Sans Pro', sans-serif;
  display: block;
  overflow: hidden; }
  .custom-calander.react-datepicker .react-datepicker__navigation--previous {
    border-right-color: #439c7c; }
    .custom-calander.react-datepicker .react-datepicker__navigation--previous:hover {
      border-right-color: #245543; }
  .custom-calander.react-datepicker .react-datepicker__navigation--next {
    border-left-color: #439c7c; }
    .custom-calander.react-datepicker .react-datepicker__navigation--next:hover {
      border-left-color: #245543; }
  .custom-calander.react-datepicker .react-datepicker__month-container {
    width: 100%; }
  .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__year-read-view--down-arrow,
  .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__month-read-view--down-arrow,
  .custom-calander.react-datepicker .-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .custom-calander.react-datepicker .-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
  .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
  .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__year-read-view--down-arrow,
  .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__month-read-view--down-arrow {
    border-top-color: #439c7c; }
    .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__year-read-view--down-arrow:hover,
    .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__month-read-view--down-arrow:hover,
    .custom-calander.react-datepicker .-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow:hover,
    .custom-calander.react-datepicker .-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow:hover,
    .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow:hover,
    .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow:hover,
    .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__year-read-view--down-arrow:hover,
    .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__month-read-view--down-arrow:hover {
      border-top-color: #245543; }
      .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__year-read-view--down-arrow:hover:before,
      .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__month-read-view--down-arrow:hover:before,
      .custom-calander.react-datepicker .-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow:hover:before,
      .custom-calander.react-datepicker .-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow:hover:before,
      .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow:hover:before,
      .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow:hover:before,
      .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__year-read-view--down-arrow:hover:before,
      .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__month-read-view--down-arrow:hover:before {
        border-top-color: #245543; }
    .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__year-read-view--down-arrow:before,
    .custom-calander.react-datepicker .react-datepicker__year-read-view .react-datepicker__month-read-view--down-arrow:before,
    .custom-calander.react-datepicker .-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow:before,
    .custom-calander.react-datepicker .-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow:before,
    .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow:before,
    .custom-calander.react-datepicker .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow:before,
    .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__year-read-view--down-arrow:before,
    .custom-calander.react-datepicker .react-datepicker__month-read-view .react-datepicker__month-read-view--down-arrow:before {
      border-top-color: #439c7c; }
  .custom-calander.react-datepicker .react-datepicker__header {
    border: 0;
    padding: 0.5rem 0.4rem 0; }
    .custom-calander.react-datepicker .react-datepicker__header .react-datepicker__day-names {
      font-size: 1.00012rem;
      font-weight: 600; }
  .custom-calander.react-datepicker .react-datepicker__day-name,
  .custom-calander.react-datepicker .react-datepicker__day {
    width: 1.9rem;
    width: auto;
    display: inline-block; }
  .custom-calander.react-datepicker .react-datepicker__day--selected {
    background: #8fcfb8;
    font-weight: 600; }
  .custom-calander.react-datepicker .react-datepicker__day--keyboard-selected:hover,
  .custom-calander.react-datepicker .react-datepicker__month-text--keyboard-selected:hover,
  .custom-calander.react-datepicker .react-datepicker__quarter-text--keyboard-selected:hover,
  .custom-calander.react-datepicker .react-datepicker__year-text--keyboard-selected:hover,
  .custom-calander.react-datepicker .react-datepicker__day--keyboard-selected,
  .custom-calander.react-datepicker .react-datepicker__month-text--keyboard-selected,
  .custom-calander.react-datepicker .react-datepicker__quarter-text--keyboard-selected,
  .custom-calander.react-datepicker .react-datepicker__year-text--keyboard-selected {
    background: transparent; }
  .custom-calander.react-datepicker .react-datepicker__day {
    color: #182929;
    font-size: 0.875rem;
    outline: 0; }
    .custom-calander.react-datepicker .react-datepicker__day:hover {
      background: #d7eee5; }
  .custom-calander.react-datepicker .react-datepicker__day--disabled {
    color: #ccc; }
  .custom-calander.react-datepicker .react-datepicker__week,
  .custom-calander.react-datepicker .react-datepicker__day-names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1rem; }
    .custom-calander.react-datepicker .react-datepicker__week > *,
    .custom-calander.react-datepicker .react-datepicker__day-names > * {
      margin: 0; }
  .custom-calander.react-datepicker .react-datepicker__month-dropdown,
  .custom-calander.react-datepicker .react-datepicker__year-dropdown {
    background-color: #ffffff;
    border: none;
    box-shadow: 1px 1px 10px #ededed;
    height: 170px;
    overflow-y: scroll; }
    .custom-calander.react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option,
    .custom-calander.react-datepicker .react-datepicker__month-dropdown .react-datepicker__year-option,
    .custom-calander.react-datepicker .react-datepicker__year-dropdown .react-datepicker__month-option,
    .custom-calander.react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option {
      padding: 4px 0px; }
      .custom-calander.react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option:hover,
      .custom-calander.react-datepicker .react-datepicker__month-dropdown .react-datepicker__year-option:hover,
      .custom-calander.react-datepicker .react-datepicker__year-dropdown .react-datepicker__month-option:hover,
      .custom-calander.react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option:hover {
        background-color: #d7eee5; }
    .custom-calander.react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option--selected_month .react-datepicker__month-option--selected,
    .custom-calander.react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option--selected_month .react-datepicker__year-option--selected,
    .custom-calander.react-datepicker .react-datepicker__month-dropdown .react-datepicker__year-option--selected_year .react-datepicker__month-option--selected,
    .custom-calander.react-datepicker .react-datepicker__month-dropdown .react-datepicker__year-option--selected_year .react-datepicker__year-option--selected,
    .custom-calander.react-datepicker .react-datepicker__year-dropdown .react-datepicker__month-option--selected_month .react-datepicker__month-option--selected,
    .custom-calander.react-datepicker .react-datepicker__year-dropdown .react-datepicker__month-option--selected_month .react-datepicker__year-option--selected,
    .custom-calander.react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option--selected_year .react-datepicker__month-option--selected,
    .custom-calander.react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option--selected_year .react-datepicker__year-option--selected {
      color: #439c7c; }

.custom-calander.lg .react-datepicker__day-names {
  margin: 1rem 0 0; }

.custom-calander.lg .react-datepicker__day {
  line-height: 3rem; }
  .custom-calander.lg .react-datepicker__day:after {
    font-family: 'fuseclassroom', Arial, Helvetica, sans-serif !important;
    vertical-align: middle;
    margin-left: 0.5rem; }
  .custom-calander.lg .react-datepicker__day.absent:after {
    content: '\e913'; }
  .custom-calander.lg .react-datepicker__day.present:after {
    content: '\e915';
    color: #007bff; }
  .custom-calander.lg .react-datepicker__day.holiday:after {
    content: 'H';
    font-size: 0.5em;
    color: #007bff; }

@media (max-width: 575.98px) {
  .navbar-brand svg {
    width: auto;
    height: 27px; }
  .navbar-collapse {
    flex-basis: 70%; }
    .navbar-collapse.collapse:not(.show) {
      display: flex; }
    .navbar-collapse .form-inline,
    .navbar-collapse .navbar-text {
      display: none; }
  .user.dropdown .dropdown-menu {
    position: absolute; }
  .bg-80:after {
    right: 0 !important; }
  section[role='top--section'].features {
    background: #174e4d;
    height: auto; }
  .navbar-light .navbar-brand.lg {
    background: transparent;
    margin: 0;
    padding: 0;
    width: auto; }
  aside {
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 1; }
    aside .side__menu {
      padding: 0;
      height: auto; }
      aside .side__menu .menu {
        margin: 0.25rem 0; }
        aside .side__menu .menu--list {
          display: inline-block;
          margin-top: 5px; }
          aside .side__menu .menu--list .menu--item {
            margin-bottom: 0;
            display: inline-block; }
            aside .side__menu .menu--list .menu--item .icon {
              padding: 0.2rem 0.25rem;
              font-size: 1rem;
              margin-bottom: 2px; }
          aside .side__menu .menu--list > * {
            margin-bottom: 0;
            margin-right: 15px;
            display: inline-block; }
            aside .side__menu .menu--list > *:last-child {
              margin-right: 0; }
  main.sidebar-show {
    margin-left: 0;
    padding-top: 1rem;
    padding-bottom: 80px; }
  .app__container {
    min-height: auto;
    margin-bottom: 1rem;
    margin-top: 0;
    height: auto !important;
    padding: 1rem 1rem; }
  .h-80 {
    height: auto; }
  .d-md-flex .inner__menu {
    min-width: auto;
    width: 100%;
    max-width: 100%; }
    .d-md-flex .inner__menu .blocks[role='tablist'].flex-column {
      flex-direction: unset !important;
      flex-wrap: nowrap;
      overflow: auto; }
    .d-md-flex .inner__menu .blocks[role='tablist'] a {
      display: block;
      max-width: 50%; }
    .d-md-flex .inner__menu .blocks[role='tablist'] .app--link {
      margin: 0;
      padding: 0.25rem 0.75rem;
      font-size: 14px;
      display: inline-block; }
      .d-md-flex .inner__menu .blocks[role='tablist'] .app--link .text {
        font-size: 1em; }
    .d-md-flex .inner__menu .back-btn {
      display: none; }
    .d-md-flex .inner__menu .chapter__list {
      height: auto !important;
      display: flex;
      flex-wrap: nowrap;
      border-bottom: 1px solid #dee2e6; }
      .d-md-flex .inner__menu .chapter__list .accordion {
        min-width: 250px; }
        .d-md-flex .inner__menu .chapter__list .accordion .link {
          border: 0; }
  .d-md-flex .tab-content {
    max-width: 100%; }
    .d-md-flex .tab-content .media {
      overflow: auto;
      display: block; }
      .d-md-flex .tab-content .media .media-date {
        margin: 0; }
      .d-md-flex .tab-content .media-body {
        margin: 15px 0; }
        .d-md-flex .tab-content .media-body .media-body-title {
          display: block; }
    .d-md-flex .tab-content .multiple-video-main .video-container {
      margin-right: 4px; }
    .d-md-flex .tab-content .assignment-date .note {
      min-width: 110px; }
  .d-md-flex .react-bootstrap-table {
    width: 100%;
    overflow: auto; }
  .submission-header {
    padding: 0.3125rem 0.25rem 0.3125rem 0.5rem; }
    .submission-header--date {
      padding: 0; }
  .exam .main-content--header .exam--title-block {
    display: block; }
  .exam--controls {
    padding: 1.5rem 0;
    display: block;
    justify-content: initial;
    align-items: initial; }
    .exam--controls-left {
      width: 100%;
      margin-right: 0; }
    .exam--controls-right {
      width: 100%;
      margin-top: 2rem;
      padding: 0 0; }
  .exam--legends {
    font-size: 0.875rem;
    color: #595959;
    display: inline-flex;
    justify-content: initial;
    align-items: center;
    margin-right: 16px; }
    .exam--legends .indicator {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 8px;
      border-radius: 3px; }
  .exam--pagination {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    grid-gap: 1rem; }
    .exam--pagination-item a {
      color: #595959;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.05);
      font-size: 0.875rem;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      .exam--pagination-item a.active {
        background: #74b9ff;
        color: #ffffff; }
      .exam--pagination-item a:hover {
        opacity: 0.8; }
  .exam--start-end {
    display: flex;
    align-items: center;
    padding: 2.5rem 0;
    flex-direction: column;
    color: #182929; }
    .exam--start-end .img-place {
      text-align: center;
      margin-bottom: 2.5rem;
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .exam--start-end .img-place img {
        width: 100%; }
    .exam--start-end .instruction-text {
      display: block;
      font-size: 1.00012rem; }
      .exam--start-end .instruction-text p,
      .exam--start-end .instruction-text ul {
        margin-bottom: 1.5rem; }
      .exam--start-end .instruction-text ul {
        margin-left: 1.5rem; }
        .exam--start-end .instruction-text ul li {
          margin-bottom: 16px; }
  .exam--completion {
    text-align: center; }
    .exam--completion > span {
      font-size: 1.75rem;
      font-weight: 600;
      color: #439c7c;
      margin-bottom: 1rem;
      display: block; }
    .exam--completion > p {
      font-size: 1.12525rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
      padding: 0 5rem;
      color: #182929; }
    .exam--completion .btn-hold.v-btns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .exam--completion .answer-stats {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem; }
  .discussion--container .media {
    display: flex !important; }
    .discussion--container .media-body {
      margin: 0 8px !important; }
  .filter--title {
    cursor: pointer; }
    .filter--title [class^='icon-'],
    .filter--title [class*=' icon-'] {
      display: inline-block; }
  .filter--list {
    max-height: 0;
    overflow: hidden;
    padding-top: 0.75rem;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
    .filter--list.show {
      max-height: 10000px; }
  .submission {
    display: block;
    height: auto; }
    .submission-right, .submission-left {
      width: auto; }
    .submission-right .submission-header {
      display: block !important; }
      .submission-right .submission-header > div {
        width: 100% !important; }
    .submission--accordion .file-block {
      display: block; }
      .submission--accordion .file-block .submission--file-info {
        padding-left: 50px;
        margin-bottom: 15px; }
    .submission-content__rr {
      width: 500px !important;
      max-width: 80% !important; }
    .submission-content__with-comments .submission-content__rl {
      width: 100% !important;
      margin-left: -220px; }
  .chatbot {
    margin-top: 0; }
    .chatbot .str-chat {
      height: calc(100vh - 220px);
      /* Use vh as a fallback for browsers that do not support Custom Properties */
      height: calc((var(--vh, 1vh) * 100) - 220px); }
    .chatbot .message-header {
      position: sticky;
      top: 0;
      display: block;
      padding: 10px;
      background: #439c7c; }
      .chatbot .message-header h6 {
        margin: 0;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff; }
  #scrollable-div.h-80 {
    height: calc(100vh - 250px); } }

@media (max-width: 767.98px) and (orientation: landscape) {
  .navbar-brand svg {
    width: auto;
    height: 27px; }
  .navbar-collapse {
    flex-basis: 70%; }
    .navbar-collapse.collapse:not(.show) {
      display: flex; }
    .navbar-collapse .form-inline,
    .navbar-collapse .navbar-text {
      display: none; }
  .user.dropdown .dropdown-menu {
    position: absolute; }
  .bg-80:after {
    right: 0 !important; }
  section[role='top--section'].features {
    background: #174e4d;
    height: auto; }
  .navbar-light .navbar-brand.lg {
    background: transparent;
    margin: 0;
    padding: 0;
    width: auto; }
  aside {
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 1; }
    aside .side__menu {
      padding: 0;
      height: auto; }
      aside .side__menu .menu {
        margin: 0.25rem 0; }
        aside .side__menu .menu--list {
          display: inline-block;
          margin-top: 5px; }
          aside .side__menu .menu--list .menu--item {
            margin-bottom: 0;
            display: inline-block; }
            aside .side__menu .menu--list .menu--item .icon {
              padding: 0.2rem 0.25rem;
              font-size: 1rem;
              margin-bottom: 2px; }
          aside .side__menu .menu--list > * {
            margin-bottom: 0;
            margin-right: 15px;
            display: inline-block; }
            aside .side__menu .menu--list > *:last-child {
              margin-right: 0; }
  main.sidebar-show {
    margin-left: 0;
    padding-top: 1rem;
    padding-bottom: 80px; }
  .app__container {
    min-height: auto;
    margin-bottom: 1rem;
    margin-top: 0;
    height: auto !important;
    padding: 1rem 1rem; }
  .h-80 {
    height: auto; }
  .d-md-flex .inner__menu {
    min-width: auto;
    width: 100%;
    max-width: 100%; }
    .d-md-flex .inner__menu .blocks[role='tablist'].flex-column {
      flex-direction: unset !important;
      flex-wrap: nowrap;
      overflow: auto; }
    .d-md-flex .inner__menu .blocks[role='tablist'] a {
      display: block;
      max-width: 50%; }
    .d-md-flex .inner__menu .blocks[role='tablist'] .app--link {
      margin: 0;
      padding: 0.25rem 0.75rem;
      font-size: 14px;
      display: inline-block; }
      .d-md-flex .inner__menu .blocks[role='tablist'] .app--link .text {
        font-size: 1em; }
    .d-md-flex .inner__menu .back-btn {
      display: none; }
    .d-md-flex .inner__menu .chapter__list {
      height: auto !important;
      display: flex;
      flex-wrap: nowrap;
      border-bottom: 1px solid #dee2e6; }
      .d-md-flex .inner__menu .chapter__list .accordion {
        min-width: 250px; }
        .d-md-flex .inner__menu .chapter__list .accordion .link {
          border: 0; }
  .d-md-flex .tab-content {
    max-width: 100%; }
    .d-md-flex .tab-content .media {
      overflow: auto;
      display: block; }
      .d-md-flex .tab-content .media .media-date {
        margin: 0; }
      .d-md-flex .tab-content .media-body {
        margin: 15px 0; }
        .d-md-flex .tab-content .media-body .media-body-title {
          display: block; }
    .d-md-flex .tab-content .multiple-video-main .video-container {
      margin-right: 4px; }
    .d-md-flex .tab-content .assignment-date .note {
      min-width: 110px; }
  .d-md-flex .react-bootstrap-table {
    width: 100%;
    overflow: auto; }
  .submission-header {
    padding: 0.3125rem 0.25rem 0.3125rem 0.5rem; }
    .submission-header--date {
      padding: 0; }
  .exam .main-content--header .exam--title-block {
    display: block; }
  .exam--controls {
    padding: 1.5rem 0;
    display: block;
    justify-content: initial;
    align-items: initial; }
    .exam--controls-left {
      width: 100%;
      margin-right: 0; }
    .exam--controls-right {
      width: 100%;
      margin-top: 2rem;
      padding: 0 0; }
  .exam--legends {
    font-size: 0.875rem;
    color: #595959;
    display: inline-flex;
    justify-content: initial;
    align-items: center;
    margin-right: 16px; }
    .exam--legends .indicator {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 8px;
      border-radius: 3px; }
  .exam--pagination {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    grid-gap: 1rem; }
    .exam--pagination-item a {
      color: #595959;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.05);
      font-size: 0.875rem;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      .exam--pagination-item a.active {
        background: #74b9ff;
        color: #ffffff; }
      .exam--pagination-item a:hover {
        opacity: 0.8; }
  .exam--start-end {
    display: flex;
    align-items: center;
    padding: 2.5rem 0;
    flex-direction: column;
    color: #182929; }
    .exam--start-end .img-place {
      text-align: center;
      margin-bottom: 2.5rem;
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .exam--start-end .img-place img {
        width: 100%; }
    .exam--start-end .instruction-text {
      display: block;
      font-size: 1.00012rem; }
      .exam--start-end .instruction-text p,
      .exam--start-end .instruction-text ul {
        margin-bottom: 1.5rem; }
      .exam--start-end .instruction-text ul {
        margin-left: 1.5rem; }
        .exam--start-end .instruction-text ul li {
          margin-bottom: 16px; }
  .exam--completion {
    text-align: center; }
    .exam--completion > span {
      font-size: 1.75rem;
      font-weight: 600;
      color: #439c7c;
      margin-bottom: 1rem;
      display: block; }
    .exam--completion > p {
      font-size: 1.12525rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
      padding: 0 5rem;
      color: #182929; }
    .exam--completion .btn-hold.v-btns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .exam--completion .answer-stats {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem; }
  .discussion--container .media {
    display: flex !important; }
    .discussion--container .media-body {
      margin: 0 8px !important; }
  .filter--title {
    cursor: pointer; }
    .filter--title [class^='icon-'],
    .filter--title [class*=' icon-'] {
      display: inline-block; }
  .filter--list {
    max-height: 0;
    overflow: hidden;
    padding-top: 0.75rem;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
    .filter--list.show {
      max-height: 10000px; }
  .submission {
    display: block;
    height: auto; }
    .submission-right, .submission-left {
      width: auto; }
    .submission-right .submission-header {
      display: block !important; }
      .submission-right .submission-header > div {
        width: 100% !important; }
    .submission--accordion .file-block {
      display: block; }
      .submission--accordion .file-block .submission--file-info {
        padding-left: 50px;
        margin-bottom: 15px; }
    .submission-content__rr {
      width: 500px !important;
      max-width: 80% !important; }
    .submission-content__with-comments .submission-content__rl {
      width: 100% !important;
      margin-left: -220px; }
  .chatbot {
    margin-top: 0; }
    .chatbot .str-chat {
      height: calc(100vh - 220px);
      /* Use vh as a fallback for browsers that do not support Custom Properties */
      height: calc((var(--vh, 1vh) * 100) - 220px); }
    .chatbot .message-header {
      position: sticky;
      top: 0;
      display: block;
      padding: 10px;
      background: #439c7c; }
      .chatbot .message-header h6 {
        margin: 0;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff; }
  #scrollable-div.h-80 {
    height: calc(100vh - 250px); } }

@media screen and (min-width: 1920px) {
  .assignment-files .submission-body {
    display: flex;
    flex-wrap: wrap; }
    .assignment-files .submission-body .block {
      width: 585px;
      margin-right: 24px; } }

@media (max-width: 991.98px) {
  .navbar .navbar-toggler {
    display: none; }
  .navbar-collapse {
    flex-basis: 80%; }
    .navbar-collapse.collapse:not(.show) {
      display: flex; } }
