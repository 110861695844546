$interactive-controls: #439c7c;
$error: #e55055;
$background: #ffffff;
$interactive-controls-hover: #339672 !default;
$interactive-controls-active: #2ca579 !default;
$itab-items-default: #6a446c !default;
$nav-items-header: #c3b4c4 !default;
$interactive-controls-border: #dbd2dc !default;
$background-placeholder: #e7e1e8 !default;
$interactive-controls-light-hover: #f3f0f3 !default;
$body-text: #111111;
