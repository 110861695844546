.auth-main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93vh;

  .auth-form-wrapper {
    display: flex;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    border: 1.5px solid $background-placeholder;
    padding: 48px;
    background-color: $background;
    width: 450px;
    flex-direction: column;

    .text-danger {
      color: $error;
    }

    .auth-footer {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      column-gap: 16px;
      justify-content: flex-end;
    }
    .title {
      color: $interactive-controls;
      margin-bottom: 25px;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
    }
  }
}

.form-title {
  margin-bottom: 10px;
}

.link-services-policies {
  text-decoration: underline;
}

.custom-switch {
  display: flex;
  align-items: center;
  margin: 1rem;

  .custom-control-label::after {
    top: calc(0.5rem + 2px);
  }

  .custom-control-label::before {
    top: 0.5rem;
  }

  .toggle-item {
    background-color: transparent;
    color: $body-text;
    font-size: rem(14px);
    border-radius: rem(6px);
    padding: rem(6px 16px);
    text-transform: uppercase;
    transition: linear 0.3s;
    cursor: pointer;
    &.active {
      background-color: $interactive-controls;
      color: $background;
      box-shadow: 0px 4px 12px rgba(76, 30, 79, 0.32);
    }
  }
  .custom-control-label {
    font-size: 20px;
  }
}
