.btn-btn {
  padding: 12px 20px 12px 20px;
  border-radius: 8px;
  border-width: 1.5px;
  border-style: solid;
  height: 44px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  font-size: larger;
  font-weight: 600;
  &-primary {
    background-color: $interactive-controls !important;
    border-color: $interactive-controls !important;
    color: $background !important;
    &:hover,
    &:focus {
      background-color: $interactive-controls-hover !important;
      border-color: $interactive-controls-hover !important;
    }
    &:active {
      background-color: $interactive-controls-active !important;
      border-color: $interactive-controls-active !important;
    }
    &:disabled {
      background-color: $nav-items-header !important;
      border-color: $nav-items-header !important;
      color: $background !important;
      cursor: not-allowed;
    }
    &:focus-visible {
      box-shadow: 0 0 0 4px rgba($interactive-controls, 0.3); // 0.25rem = 4px
    }
  }

  &-secondary {
    background-color: transparent !important;
    border-color: $interactive-controls !important;
    color: $interactive-controls !important;
    &:hover,
    &:focus {
      background-color: $background-placeholder !important;
      border-color: $interactive-controls !important;
      color: $interactive-controls !important;
    }
    &:active {
      background-color: $interactive-controls-border !important;
      border-color: $interactive-controls !important;
      color: $interactive-controls !important;
    }
    &:disabled {
      background-color: transparent !important;
      border-color: $nav-items-header !important;
      color: $nav-items-header !important;
      cursor: not-allowed;
    }
  }
}

.user .dropdown--item button {
  width: 25%;
}
